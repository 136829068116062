

import { useEffect, useMemo, useState, useRef } from "react";
import LoadingProvider from "./contexts/LoadingContext";
import { getAuthUser } from "./services/api/UserAuth";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { TranslationProvider } from './contexts/TranslationProvider';
import Navbar from "./components/layout/Navbar";
import MainContent from "./Routes";
import "./home.css";
import { IContract, IGroup, IProject, IProjectTemplateRequirement } from "./types/models";
import AiValidationContext from "./contexts/AiValidationContext";
import InputValuesContext from "./contexts/InputValuesContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Home: React.FC = () => {
  const { user, userToken } = getAuthUser();
  const location = useLocation();
  const navigate = useNavigate()
  useEffect(() => {
    const storedToken = localStorage.getItem('user-token');
    const signInAsync = async () => {
      if (!user || userToken !== storedToken || !storedToken) {
        navigate("/sign-in")
      }
    };
    signInAsync();
  }, [user, userToken]);
  const [inputValues, setInputValues] = useState({ contractName: "" });
  const [fileNames, setFileNames] = useState<IContract['fileNames']>({});
  const [beneficialsMap, setBeneficialsMap] = useState<IContract['beneficialsMap']>({});
  const [segmentsOverrides, setSegmentsOverrides] = useState({});
  const [commentsOverrides, setCommentsOverrides] = useState({});
  const [excludedSubClauses, setExcludedSubClauses] = useState([]);
  const [userGroup, setUserGroup] = useState<number[]>([]);
  const [accessUser, setAccessUser] = useState<boolean[]>([]);
  const [groupContract, setGroupContract] = useState<string>();
  const [project, setProject] = useState<IProject>();
  const [requirement, setRequirement] = useState<IProjectTemplateRequirement>();
  const [requirementType, setRequirementType] = useState<number>();
  const [excludedClauses, setExcludedClauses] = useState([]);
  const [templateId, setTemplateId] = useState(null);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [contractId, setContractId] = useState(null);
  const [contract, setContract] = useState(null);
  const [templateData, setTemplateData] = useState([]);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [aiResponsesStatus, setAiResponseStatus] = useState([]);
  const [aiValidationValues, setAiValidationValues] = useState({});
  const [rawSummarySheet, setRawSummarySheet] = useState([]);
  const [aiContractData, setAiContractData] = useState({
    contractName: "",
    contractId: null,
    formId: null,
    textContent: "",
  });
  const [aiCompletionPercentage, setAiCompletionPercentage] = useState(0);
  const contractListParentRef = useRef(null)
  useEffect(() => { console.log("inputValues", inputValues) }, [JSON.stringify(inputValues)])
  useEffect(() => { console.log("beneficialsMap", beneficialsMap) }, [JSON.stringify(beneficialsMap)])
  useEffect(() => { console.log("fileNames", fileNames) }, [JSON.stringify(fileNames)])

  const inputValuesContextValue = useMemo(
    () => ({
      contract, setContract,
      templateData, setTemplateData,
      inputValues, setInputValues,
      fileNames, setFileNames,
      beneficialsMap, setBeneficialsMap,
      segmentsOverrides,
      setSegmentsOverrides,
      commentsOverrides,
      setCommentsOverrides,
      completionPercentage,
      setCompletionPercentage,
      excludedSubClauses,
      setExcludedSubClauses,
      excludedClauses,
      setExcludedClauses,
      templateId,
      setTemplateId,
      groups,
      setGroups,
      contractId,
      setContractId,
      contractListParentRef,
      setUserGroup,
      userGroup,
      setAccessUser,
      accessUser,
      groupContract,
      setGroupContract,
      project,
      setProject,
      requirement,
      requirementType,
      setRequirementType,
      setRequirement
    }),
    [
      contract,
      templateData,
      inputValues, setInputValues,
      fileNames, setFileNames,
      beneficialsMap, setBeneficialsMap,
      segmentsOverrides,
      commentsOverrides,
      completionPercentage,
      excludedSubClauses,
      excludedClauses,
      templateId,
      groups,
      contractId,
      contractListParentRef,
      setUserGroup,
      userGroup,
    ]
  );
  const aiValidationContextValues = useMemo(
    () => ({
      aiResponsesStatus,
      setAiResponseStatus,
      aiCompletionPercentage,
      setAiCompletionPercentage,
      aiValidationValues, setAiValidationValues,
      aiContractData,
      setAiContractData,
      rawSummarySheet,
      setRawSummarySheet,
      project,
      setProject,
      requirement,
      setRequirement,
      requirementType,
      setRequirementType
    }),
    [aiResponsesStatus, aiCompletionPercentage, rawSummarySheet, aiContractData]
  );
  useEffect(() => {
    const storedToken = localStorage.getItem('user-token');
    const signInAsync = async () => {
      if (!user || userToken !== storedToken || !storedToken) {
        navigate("/sign-in")
      }
    };
    signInAsync();
  }, [user, userToken]);
  return (
    <TranslationProvider>
      <LoadingProvider>
        <InputValuesContext.Provider value={inputValuesContextValue}>
          <AiValidationContext.Provider value={aiValidationContextValues}>
            <div className="home">
              <Navbar />
              <MainContent />
            </div>
          </AiValidationContext.Provider>
        </InputValuesContext.Provider>
        <ToastContainer hideProgressBar={true} autoClose={3000} />
      </LoadingProvider>
    </TranslationProvider>
  );
}