import React, {useContext} from 'react'
import file from '../../styles/icons/file'
import sfile from '../../styles/icons/sfile'
import { useNavigate } from 'react-router-dom'
import FileSelectionContext from '../../contexts/FileSelectionContext'
import { TiTick } from "react-icons/ti";
import { MdOutlineDelete } from "react-icons/md";
import apiClientWithLoading from '../../services/api/ApiClient';
import { updateProject } from '../../services/api/ProjectApi';
import { deleteContract } from '../../services/api/ContractApi';
import { deleteForm } from '../../services/api/FormApi';
import { deleteContractAI } from '../../services/api/ContractAIApi';
import { deleteDocument } from '../../services/api/DocumentApi';
import InputValuesContext from '../../contexts/InputValuesContext'
import { toast } from 'react-toastify'
import ProjectContext from '../../contexts/ProjectContext'

function File({ project, typeRessource, documentReq, ressource, index, basePath }) {
    const navigate = useNavigate()
    const apiClient = apiClientWithLoading();
    const { setTriggerUpdate } = React.useContext(ProjectContext);
    const { setProject } =
    useContext(InputValuesContext);
    const { setSelectedFile, selectedFile } = React.useContext(FileSelectionContext)
    const handleSelectFile = (e) => {
        e.stopPropagation()
        setSelectedFile(documentReq)
        if (ressource && ressource?.name) {
            switch (typeRessource) {
                case 'FORM':
                    return navigate(`${basePath}/edit-form/${ressource.id}`)
                case 'CONTRACT':
                    return navigate(`${basePath}/edition-contrat/${ressource.id}`);
                case 'CONTRACTAI':
                    return navigate(`${basePath}/ai-edition-contrat/${ressource.id}`);
                case 'DOCUMENT':
                    return navigate(`${basePath}/document/${ressource.id}`)
            }
        } else {
            navigate(`${basePath}/createDocumentRequirement/${documentReq?.id}`, {
                state: {
                    req: documentReq,
                    project: project
                }
            })
        }
    }
    const  handleDeleteRessource = async (e) => {
        if(documentReq === selectedFile){
            const valueKey = Object.keys(project?.values).find(key => project?.values[key]?.id === ressource?.id);
            if (valueKey) {
                const updatedValues = { ...project?.values };
                delete updatedValues[valueKey];
                const updatedProject = await updateProject(apiClient, project?.id, {
                    values: updatedValues,
                });
                switch (typeRessource) {
                    case 'FORM':
                        await deleteForm(apiClient, ressource?.id);
                        break;
                    case 'CONTRACT':
                        await deleteContract(apiClient, ressource?.id);
                        break;
                    case 'CONTRACTAI':
                        await deleteContractAI(apiClient, ressource?.id);
                        break;
                    case 'DOCUMENT':
                        await deleteDocument(apiClient, ressource?.id)
                        break;
                }
                setTriggerUpdate(prev=>!prev)
                toast.success("Document deleted successfully!");
                navigate(`${basePath}`)
            } else {
                console.log(`Value with id ${ressource?.id} not found in the project's values.`);
            }
        }
    }
    return (
        <div onClick={handleSelectFile} key={index} className={`${selectedFile === index ? "selected-file" : "file"}`}>

            {ressource && ressource?.name ? (<>
                <div className='file-icon'>
                    {selectedFile === documentReq ? sfile : file}
                </div>
                <div className='file-name'>
                    {ressource?.name}
                </div>
                <div className='file-exists'>
                <TiTick/>
                </div>
                <div className='file-exists'>
                <MdOutlineDelete onClick={handleDeleteRessource} />
                </div>
                
            </>
            ) : (
                <>
                    <div className='file-icon'>
                        {selectedFile === documentReq ? sfile : file}
                    </div>
                    <div className='file-name'>
                        {documentReq?.name}
                    </div>
                </>
            )}

        </div>
    )
}

export default File