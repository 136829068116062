import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Loading from "../../../components/common/Loading";
import EditContractAI from "../../../components/ContractAI/EditContractAI";
import AiValidationContext from "../../../contexts/AiValidationContext";
import apiClientWithLoading from "../../../services/api/ApiClient";
import { fetchContractById } from "../../../services/api/ContractAIApi";
import {
  addIndexToAnswers,
  fetchTextFileUrl,
} from "../../../helpers/helper";
import { LoadingContext } from "../../../contexts/LoadingContext";

function AiContractConfiguration() {
  const { isLoading, setLoading } = useContext(LoadingContext);
  const [isEditing, setIsEditing] = useState(false);
  const {
    setAiResponseStatus,
    setAiCompletionPercentage,
    aiContractData,
    setAiContractData,
    setRawSummarySheet,
    setProject,
    setRequirement,
    setRequirementType
  } = useContext(AiValidationContext);

  const location = useLocation();
  const { contractId } = useParams<{ contractId?: string }>() || {};
  const contractNameFromModal =
    location.state?.contractDataFromModal?.contractName;
  const aiContractModel = location.state?.aiContractData;
  console.log(contractId)
  const fetchContractData = async () => {
    const apiClient = apiClientWithLoading(setLoading);
    const data = await fetchContractById(apiClient, parseInt(contractId));
    const textcontent = await fetchTextFileUrl(
      data?.row?.textFile?.url
    );
    setAiContractData({
      contractName: data?.row?.name,
      contractId: data?.row?.id,
      formId: data?.row?.formId,
      textContent: textcontent,
    });

    setAiResponseStatus(addIndexToAnswers(data?.row?.aiResponsesStatus));
    setRawSummarySheet(data?.row?.summarySheet);
    setIsEditing(true);
  };

  useEffect(() => {
    if (contractId) {
      fetchContractData();
    } else {
      if (
        contractNameFromModal &&
        contractNameFromModal !== aiContractData.contractName
      ) {
        // Resetting the context to its initial state
        setAiCompletionPercentage(0);

        // Setting the aiContractName from the context to the new contractName
        setAiContractData({
          contractName: contractNameFromModal,
          contractId: aiContractModel.contractId,
          formId: aiContractModel.formId,
          textContent: aiContractModel?.textContent,
        });
        setRawSummarySheet(aiContractModel.summarySheet);
        setAiResponseStatus(
          addIndexToAnswers(aiContractModel.aiResponsesStatus)
        );
        if(location?.state?.project){
          setProject(location?.state?.project)
          setRequirement(location?.state?.requirement)
          setRequirementType(location?.state?.requirementType)
        }
      }
    }
  }, [contractNameFromModal, contractId]);

  return (
    <div className="contract-container">
      {isLoading ? <Loading height="90vh" /> : <EditContractAI />}
    </div>
  );
}

export default AiContractConfiguration;
