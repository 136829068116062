import { useContext } from "react";
import Clause from "./Clause";
import SelectionContext from "../../contexts/SelectionContext";
import { useEffect } from "react";
import InputValuesContext from "../../contexts/InputValuesContext";
import { isClauseExcluded } from "../../helpers/helper";
import { ContractTemplateParsedData } from "../../helpers/ParseTemplateData";

// display clauses and their subClauses
function EditContractClauseList({ clauseListRef, templateData }: {
  clauseListRef: React.MutableRefObject<any>,
  templateData: ContractTemplateParsedData,
}) {

  const { selected } = useContext(SelectionContext);
  const { excludedClauses } = useContext(InputValuesContext);

  //used to handle scrolling when an input has been selected
  useEffect(() => {
    if (selected && clauseListRef.current) {
      const elementId = selected.eventType == 'ParamValueChange' ?
        `param-${selected.paramName}` : `${selected.clauseId}`;
      const element = document.getElementById(elementId);
      console.log(elementId, element);

      if (element) {
        setTimeout(() => {
          const container = clauseListRef.current;
          const containerRect = container.getBoundingClientRect();
          const elementRect = element.getBoundingClientRect();
          const relativeTop = elementRect.top - containerRect.top;
          const newScrollTop = container.scrollTop + relativeTop;
          container.scrollTo({
            top: newScrollTop,
            behavior: "smooth",
          });
        }, 400);
      }
    }
  }, [selected.clauseId, clauseListRef]);

  return (
    <div className="clauses-list">
      {templateData
        .filter(c => !isClauseExcluded(c.id, excludedClauses))
        .map((clause, clauseIndex) => <Clause clauseListRef={clauseListRef} key={clause.id} clause={clause} clauseIndex={clauseIndex} />)}
    </div>
  );
}

export default EditContractClauseList;
