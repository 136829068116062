import React, { useState, useEffect, useMemo, useContext } from 'react'
import { Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom';
import DataRoomNavigator from './DataRoomNavigator';
import { IProject, IProjectTemplate, ITypeLevel1 } from "../../types/models";
import apiClientWithLoading from "../../services/api/ApiClient";
import { geProject } from "../../services/api/ProjectApi";
import ProjectTemplateAPI from "../../services/api/ProjectTemplateApi";
import ProjectContext, { ProjectContextType } from "../../contexts/ProjectContext";
import '../../styles/pages/dataRoom.scss'
import EditContractPage from '../subPages/Contracts/EditContractPage';
import EditFormPage from '../subPages/Form/EditFormPage';
import AiContractConfiguration from '../subPages/ContractConfiguration/AiContractConfiguration'
import DocumentPage from '../subPages/Document/DocumentPage';
import CreateDocument from '../subPages/CreateDoc/CreateDocumentRequirement';
import InputValuesContext from '../../contexts/InputValuesContext';

function DataRoom() {
  const Navigate = useNavigate();
  const location = useLocation();
  const apiClient = apiClientWithLoading();
  const { pathname } = location;
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [triggerUpdate, setTriggerUpdate] = useState<boolean>();
  const [projectTemplate, setProjectTemplate] = useState<IProjectTemplate[]>([]);
  const [hover, setHover] = useState(false);
  const basePath = pathname.split('/').slice(0, 2).join('/');
  const { project } = useContext(InputValuesContext);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await geProject(apiClient, 'nested');
        const dataTemplate = await ProjectTemplateAPI.getAllTemplates(apiClient, 'nested');
        console.log(dataTemplate)
        console.log(data)
        const donnaTemplates = dataTemplate?.rows.filter(template => template.name === 'Donna project')
        const donnaProjects = data?.rows.filter(project => project.template?.name === 'Donna project')
        setProjectTemplate(donnaTemplates);
        setProjects(donnaProjects);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching contracts: ", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [triggerUpdate]);

  const projectContext: ProjectContextType = useMemo(
    () => ({
      projects,
      setProjects,
      projectTemplate,
      setProjectTemplate,
      triggerUpdate,
      setTriggerUpdate
    }),
    [projectTemplate, projects, setProjects, setProjectTemplate, triggerUpdate, setTriggerUpdate]
  );
  const onmouseenter = () => {
    if(!hover)
    setHover(true)
  }
  const onmouseout = () => {
    if(hover)
    setHover(false)
  }

  return (
    <div className='data-room-page-container'>
      {loading ? (
        <>loading</>
      ) : (<>
        <ProjectContext.Provider value={projectContext}>
          <div onMouseOver={onmouseenter} onMouseLeave={onmouseout} className={`data-room-navigator-container ${pathname === basePath && "data-room-navigator-container-expanded"}` }>
            <h1 className='page-title'>DATA ROOM</h1>
            <p className='current-location'>Donna / DATA ROOM</p>
            <DataRoomNavigator basePath={basePath} hover={hover} />
          </div>
          <div className='data-room-content-container'>
            <Routes>
              <Route path="/edition-contrat/:contractId?" element={<EditContractPage />} />
              <Route path="/edit-form/:selectedFormId?" element={<EditFormPage />} />
              <Route path="/ai-edition-contrat/:contractId?" element={<AiContractConfiguration />} />
              <Route path="/document/:documentId?" element={<DocumentPage />} />
              <Route path="/createDocumentRequirement/:requirementId" element={<CreateDocument basePath={basePath} />} />
            </Routes>
          </div>
        </ProjectContext.Provider>
      </>
      )}
    </div>
  )
}

export default DataRoom