// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-custom{
    background-color: #7014e5;
  }
  
  .progress-expanded{
    height: 15px !important;
    border-radius: 100px !important;
  }
  
  .progress-bar-expanded{
    border-radius: 100px !important;
  }

  .progress-collapsed{
    height: 5px !important;
    border-radius: 100px !important;
    min-width: 84px !important;
    margin-top: 5px !important;
  }
  
  .progress-bar-collapsed{
    border-radius: 100px !important;
    background-color: #7014e5 !important;
  }

  .progress-collapsed-container{
    max-width: 135px !important;
    gap: 7px !important;
  }
  .progress-expanded-container h6{
    font-weight: 500 !important;
    font-size: 14px !important;
    color: #7014e5 !important;
    letter-spacing: -1.2px;
  }`, "",{"version":3,"sources":["webpack://./src/components/common/progressBar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;EAC3B;;EAEA;IACE,uBAAuB;IACvB,+BAA+B;EACjC;;EAEA;IACE,+BAA+B;EACjC;;EAEA;IACE,sBAAsB;IACtB,+BAA+B;IAC/B,0BAA0B;IAC1B,0BAA0B;EAC5B;;EAEA;IACE,+BAA+B;IAC/B,oCAAoC;EACtC;;EAEA;IACE,2BAA2B;IAC3B,mBAAmB;EACrB;EACA;IACE,2BAA2B;IAC3B,0BAA0B;IAC1B,yBAAyB;IACzB,sBAAsB;EACxB","sourcesContent":[".bg-custom{\n    background-color: #7014e5;\n  }\n  \n  .progress-expanded{\n    height: 15px !important;\n    border-radius: 100px !important;\n  }\n  \n  .progress-bar-expanded{\n    border-radius: 100px !important;\n  }\n\n  .progress-collapsed{\n    height: 5px !important;\n    border-radius: 100px !important;\n    min-width: 84px !important;\n    margin-top: 5px !important;\n  }\n  \n  .progress-bar-collapsed{\n    border-radius: 100px !important;\n    background-color: #7014e5 !important;\n  }\n\n  .progress-collapsed-container{\n    max-width: 135px !important;\n    gap: 7px !important;\n  }\n  .progress-expanded-container h6{\n    font-weight: 500 !important;\n    font-size: 14px !important;\n    color: #7014e5 !important;\n    letter-spacing: -1.2px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
