import React, { useState, useRef } from "react";
import { IClause, IContract } from "../../../types/models";
import "./clauseComparison.css"
import ComparisonClause from "./ComparisonClause";
import { ITypeLevel1, ITypeLevel2, ITypeLevel3 } from "../../../types/models";
import ComparableClauseSelector from "./ComparableClauseSelector";
import ComparableClauseRender from "./ComparableClauseRender";


function ClauseComparisonModal({
    onClose,
    currentClause,
    inputValues,
    fileNames,
    beneficialsMap,
    segmentsOverrides,
    excludedSubClauses
}: {
    onClose: () => void;
    currentClause: IClause;
    fileNames: IContract['fileNames'];
    beneficialsMap: IContract['beneficialsMap'];
    inputValues: Record<string, any>;
    segmentsOverrides: Record<string, string>;
    excludedSubClauses: IContract['excludedSubClauses'];
}) {
    const modalComparableRef = useRef(null);
    const [levelsFilter, setLevelsFilter] = useState<{
        level1Id: ITypeLevel1['id'];
        level2Id: ITypeLevel2['id'];
        level3Id: ITypeLevel3['id'];
    }>({
        level1Id: null,
        level2Id: null,
        level3Id: null,
    });
    const [comparableClauses, setComparableClauses] = useState<IClause[]>([]);
    return (
        <>
            <>
                <div className="modal-backdrop"></div>
                <div id="contractz-lab">
                    <div className="modal d-flex justify-content-center align-items-center">
                        <div className="clause-comparison-modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Comparison de Clause</h5>
                                <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                            </div>
                            <div className="body-comparison">
                                <div className="clause-comparison-container">
                                    <ComparisonClause
                                        clause={currentClause}
                                        fileNames={fileNames}
                                        beneficialsMap={beneficialsMap}
                                        inputValues={inputValues}
                                        excludedSubClauses={excludedSubClauses}
                                        segmentsOverrides={segmentsOverrides}
                                    />

                                    <div className="clause-comparison-choice" ref={modalComparableRef} style={{position: 'relative'}}>
                                        {comparableClauses.length === 0 ?
                                            <ComparableClauseSelector clauseName={currentClause.name} modalComparableRef={modalComparableRef} setComparableClauses={setComparableClauses} />
                                            :<>
                                            <ComparableClauseRender clauseName={currentClause.name} comparableClauses={comparableClauses} />
                                            <button className="confirm-comparison-button" onClick={()=>setComparableClauses([])}>
                                            Réinitialiser
                                            </button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        </>
    );
}

export default ClauseComparisonModal;
