import DatePicker from "react-datepicker";
import "./FormDateInput.css";
import { AiOutlineCalendar } from "react-icons/ai";

function FormDateInput({ name, onChange, defaultValue }) {
  // Convert value from YYYY-MM-DD string to Date object
  const dateValue = defaultValue ? new Date(defaultValue) : null;
  const handleChange = (date) => {
    onChange(date);
  };

  return (
    <>
      <div className="date-input-container">
        <div className="input-with-icon">
          <DatePicker
            selected={dateValue}
            onChange={handleChange}
            dateFormat="dd/MM/yyyy"
            showYearDropdown
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
            placeholderText="JJ/MM/AAAA"
            id={name}
          />
          <AiOutlineCalendar className="input-icon" />
        </div>
      </div>
    </>
  );
}

export default FormDateInput;
