import { AxiosInstance } from "axios";
import { IContractAIForm, ITypeLevel1, ITypeLevel2, ITypeLevel3 } from "../../types/models";
const END_POINT = "/ContractAIForm";

export const getAllContractAIForms = async ( apiClient: AxiosInstance,
levelsFilter: {
  level1Id: ITypeLevel1['id'];
  level2Id: ITypeLevel2['id'];
  level3Id: ITypeLevel3['id'];
},
schema = "full"
) => {
try {
  const response = await apiClient.post<{ count: number, rows: IContractAIForm[] }>(
    `${END_POINT}/all`,
    {
      where: {
        ...levelsFilter,
        activated: true,
      },
    },
    {
      params: {
        schema,
      },
    }
  );
  return response.data;
} catch (error) {
  console.error(
    `Error fetching templates with levels: ${levelsFilter}`,
    error
  );
  return null;
}
}

export const fetchFormById = async (apiClient: AxiosInstance, id: IContractAIForm["id"]) => {
  const response = await apiClient.get<{ row: IContractAIForm }>(`${END_POINT}?schema=full&id=${id}`);
  return response.data;
};
