import React, { useContext, useEffect, useState } from "react";
import apiClientWithLoading from "../../services/api/ApiClient";
import { addBenificial, getAllBenificials, updateBenificial } from "../../services/api/BenificialApi";
import { toast } from "react-toastify";
import Loading from "../common/Loading";
import DatePicker from "react-datepicker";
import { AiOutlineCalendar } from "react-icons/ai";
import InputValuesContext from "../../contexts/InputValuesContext";
import CustomCombobox from "../common/CustomCombobox";
import { AxiosInstance } from "axios";
import { IBeneficial, IClause, ISubClause } from "../../types/models";
import { useTranslation } from '../../contexts/TranslationProvider';
const lookup = require("../../assets/lookup.png")


function AddBenificialModel({
  onClose,
  onSave,
  currentBenificialData,
  beneficialsMap,
  setBeneficialsMap,
  beneficialTypes,
}: {
  onClose: () => void;
  onSave: (value: IBeneficial['id']) => void;
  currentBenificialData: IBeneficial;
  beneficialsMap: Record<string, IBeneficial>;
  setBeneficialsMap: React.Dispatch<React.SetStateAction<Record<number, any>>>;
  beneficialTypes: (IBeneficial['type'])[];
}) {

  const [beneficialData, setBeneficialData] = useState<{ data: IBeneficial }>({
    data: currentBenificialData?.type ? currentBenificialData : { type: beneficialTypes[0] },
  });
  const [beneficials, setBeneficials] = useState<IBeneficial[]>([])
  const benificialId = beneficialData.data.id

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredCompanyData, setFilteredCompanyData] = useState<IBeneficial[]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    const apiClient = apiClientWithLoading(setLoading);
    getAllBenificials(apiClient).then(({ rows }) => {
      setBeneficials(rows);
    });
  }, []);
  const handleInputChange = (event, field, subField) => {
    let value = event.target.value;
    if (subField) {
      setBeneficialData({
        data: {
          ...beneficialData.data,
          [subField]: {
            ...(beneficialData.data[subField] || {}),
            [field]: value,
          },
        },
      });
    } else {
      setBeneficialData({
        data: { ...beneficialData.data, [field]: value },
      });
    }
    if (field === "companyName") {
      const filteredCompanies = beneficials.filter(item => item?.companyName?.toLowerCase().includes(value?.toLowerCase()));
      setShowDropdown(value.length > 0 && filteredCompanies?.length > 0);
      setFilteredCompanyData(filteredCompanies);
    }
  };

  const submitBenificial = async (apiClient: AxiosInstance, body) => {
    try {
      setLoading(true);
      let id = beneficialData.data.id
      const response = id ? await updateBenificial(apiClient, id, body)
        : await addBenificial(apiClient, body);
      id = response.row.id;
      const newBeneficialsMap = {
        ...beneficialsMap,
        [id]: response.row,
      }
      setBeneficialsMap(newBeneficialsMap)
      setLoading(false);
      toast.success("Benificial created successfully!");
      onSave(id);
      onClose();
    } catch (error) {
      setLoading(false);
      toast.error("Error creating benificial!");
      console.error(error);
      onClose();
    }
  };
  const validateForm = () => {
    let formErrors = {};

    // Fields to exclude from validation
    const excludedFields = ["name", "jobTitle", "passport", "cin", "dateOfBirth", "placeOfBirth", "address"];

    // Check if all fields are entered
    for (const field in beneficialData.data) {
      if (excludedFields.includes(field)) continue; // Skip excluded fields

      if (typeof beneficialData.data[field] === "string" && !beneficialData.data[field]) {
        console.warn(beneficialData.data[field]);

        formErrors[field] = "Ce champs est obligatoire";
      }
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSave = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      setBeneficialData((prevState) => {
        const name = prevState.data.type == "Person" ? prevState.data.firstName + " " + prevState.data.lastName : prevState.data.companyName;
        const updatedData = {
          ...prevState,
          data: {
            ...prevState.data,
            name,
          },
        };

        // Call submitBenificial with the updated beneficialData
        (async () => {
          const apiClient = apiClientWithLoading();
          await submitBenificial(apiClient, updatedData);
        })();

        return updatedData;
      });
    }
  };
  const TextInputField = ({ field, label, subField = "", type = "text" }: {
    field: keyof IBeneficial | keyof IBeneficial['address'];
    label: string;
    subField?: string;
    type?: React.InputHTMLAttributes<HTMLInputElement>['type']
  }) => {
    const rawValue = subField ? beneficialData.data[subField]?.[field] : beneficialData.data[field];
    return (
      <div className="mb-3 container-input-beneficial" key={`${field}-${subField ?? ""}`}>
        <label htmlFor={field} className="label-beneficial">{label}</label>
        <div className={field === "companyName" ? "input-companyName-container" : "input-beneficial-container"}>
          <input
            type={type}
            className="form-control custom-placeholder"
            id={field}
            value={rawValue ?? ""}
            onChange={(event) => handleInputChange(event, field, subField)}
          />

          {field === "companyName" && <img src={lookup} className="lookup-img" />}
          {field === "companyName" && showDropdown && filteredCompanyData && filteredCompanyData.length && (
            <div className="company-name-dropdown">
              {filteredCompanyData.map((item, index) => (
                <div className="listBeneficial" key={index} onClick={() => handleDropdownSelect(item.companyName, field)}>
                  {item.companyName}
                </div>
              ))}
            </div>
          )}
        </div>
        {errors[field] && <small className="text-danger py-0 my-0">{errors[field]}</small>}
      </div>
    );
  };
  const handleComboBoxChange = (field) => (value) => {
    setBeneficialData({
      data: {
        ...beneficialData.data,
        [field]: value,
      },
    });
    if (field === "companyName") {
      const filteredCompanies = beneficials.filter(item => item.companyName.toLowerCase().includes(value.toLowerCase()));
      setShowDropdown(value.length > 0 && filteredCompanies.length > 0);
      setFilteredCompanyData(filteredCompanies);
    }
  };
  const handleDropdownSelect = (selectedValue, field) => {
    const selectedCompany = beneficials.find(company => company.companyName === selectedValue);
    setBeneficialData({
      data: {
        ...selectedCompany,
        [field]: selectedValue,
      },
    });
    setShowDropdown(false);
  };

  return (
    <>
      <>
        <div className="modal-backdrop"></div>
        <div id="contractz-lab">
          <div className="modal d-flex justify-content-center align-items-center">
            <div className="modal-dialog">
              <div className="modal-content content-beneficial">
                <div className="modal-header">
                  <h5 className="modal-title">{t("pages.editionContract.popups.benificial.title")}</h5>
                  <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                </div>
                {loading ? (
                  <Loading />
                ) : (
                  <div className="modal-body body-beneficial">
                    <div className="form-group" style={{ marginTop: "-3%" }} onSubmit={handleSave}>
                      <div className="form-floating mb-3">
                        <div className="slider-wrapper">

                          {beneficialTypes.includes('Person') &&
                            <div
                              className={`slider-option ${beneficialTypes.length > 1 && "left" || ""}  ${beneficialData.data.type === "Person" ? "selected-type-beneficial" : ""}`}
                              onClick={() => handleComboBoxChange("type")("Person")}
                            >
                              {t("pages.editionContract.popups.benificial.type1")}
                            </div>
                          }
                          {beneficialTypes.includes('Company') &&
                            <div
                              className={`slider-option ${beneficialTypes.length > 1 && "right" || ""} ${beneficialData.data.type === "Company" ? "selected-type-beneficial" : ""}`}
                              onClick={() => handleComboBoxChange("type")("Company")}
                            >
                              {t("pages.editionContract.popups.benificial.type2")}
                            </div>
                          }
                        </div>
                      </div>
                      <div className="content-input-form">
                        {beneficialData.data.type === "Person" && [
                          TextInputField({ field: "firstName", label: t("pages.editionContract.popups.benificial.inputs.person.firstname") }),
                          TextInputField({ field: "lastName", label: t("pages.editionContract.popups.benificial.inputs.person.lastname") }),
                          TextInputField({ field: "email", label: t("pages.editionContract.popups.benificial.inputs.person.email") }),
                          TextInputField({ field: "jobTitle", label: t("pages.editionContract.popups.benificial.inputs.person.jobTitle") }),
                          TextInputField({ field: "addressLine", subField: "address", label: t("pages.editionContract.popups.benificial.inputs.person.address") }),
                          TextInputField({ field: "dateOfBirth", label: t("pages.editionContract.popups.benificial.inputs.person.dateOfBirth"), type: "date" }),
                          TextInputField({ field: "placeOfBirth", label: t("pages.editionContract.popups.benificial.inputs.person.placeOfBirth"), }),
                        ]}
                        {beneficialData.data.type == "Company" && [
                          TextInputField({ field: "companyName", label: t("pages.editionContract.popups.benificial.inputs.company.companyName") }),
                          TextInputField({ field: "socialCapital", label: t("pages.editionContract.popups.benificial.inputs.company.socialCapital"), type: "number" }),
                          TextInputField({ field: "postalAddress", label: t("pages.editionContract.popups.benificial.inputs.company.address") }),
                          TextInputField({ field: "legalForm", label: t("pages.editionContract.popups.benificial.inputs.company.legalForm") }),
                          TextInputField({ field: "registrationNumber", label: t("pages.editionContract.popups.benificial.inputs.company.registrationNumber") }),
                          TextInputField({ field: "specialization", label: t("pages.editionContract.popups.benificial.inputs.company.specialization") }),
                        ]}

                        <div className="modal-footer" style={{ border: "none" }}>
                          {
                            <button type="submit" className="btn btn-primary add-beneficial-btn" onClick={handleSave}>
                              Save Beneficial
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default AddBenificialModel;
