import "./FormPageInput.css";
import { useContext, useEffect, useState } from "react";
import React from "react";
import { useCollapse } from "react-collapsed";
import { IFormTemplatePage } from "../../types/models";
import EditFormContext from "../../contexts/EditFormContext";
import FormParameterInput from "./FormParameterInput";
function FormPageInput({ page }: {
  page: IFormTemplatePage;

}) {
  const {
    paramValues, setParamValues,
    selected, setSelected,
  } = useContext(EditFormContext);
  const isSelected = selected.pageId === page.id;
  const [isExpanded, setExpanded] = useState(isSelected);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  useEffect(() => {
    setExpanded(isSelected)
  }, [isSelected]);

  function handleInputChange(value: any, paramName: string) {
    setParamValues({
      ...paramValues,
      [paramName]: value
    })
  }

  return (
    <div
      key={page.id}
      id="clause-group"
      className=""
    >
      <div className="d-flex gap-2">
        <div className="edit-contract-clause-input-header-form" style={{padding:"0rem", marginTop:"-10px", marginBottom:"10px"}}>
          <b>{page.name}</b>
        </div>
      </div>
      <div>
        <div className="wrapper-form-input">
          {page.params.map((param) => {
            const currentValue = paramValues[param.name];
            return (
              <div key={`${page.id}-${param.name}`}  className="form-item-input">
                <div className="px-2 py-1 d-flex justify-content-center">
                  <FormParameterInput
                    page={page}
                    param={param}
                    currentValue={currentValue}
                    handleInputChange={handleInputChange}
                  />
                </div>{" "}
              </div>
            );
          })}
        </div>
        {/* Render the optional subclause toggle buttons at the end */}
      </div>
    </div>
  );
}
export default FormPageInput;
