import axios, { AxiosInstance } from "axios";
import { API_BASE_URL } from "../../config";
import { IGroup, IUser } from "../../types/models";

export const signUp = async (userData: IUser) => {
  try {
    const response = await axios.post<{ token: string, user: IUser }>(`${API_BASE_URL}/auth/SignUp`, userData);
    // Extract the user data and the access token from the response
    const { user, token } = response.data;
    // Store the access token in local storage, or use any other suitable storage method
    localStorage.setItem("user-token", token);
    return user;
  } catch (error) {
    console.error("Error Signingup user :" + error);
    throw error;
  }
};

export const signIn = async (email: IUser['email'], password: IUser['password']) => {
  try {
    const response = await axios.post<{ token: string, user: IUser }>(`${API_BASE_URL}/auth/SignIn`, {
      email: email,
      password: password,
    });
    // Extract the access token from the response
    const { token, user } = response.data;
    // Store the access token in local storage, or use any other suitable storage method
    localStorage.setItem("user-token", token);
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("userId", user.id);
    // window.location.reload();
    return { token, user };
  } catch (error) {
    console.error("Error Authenticating user :" + error);
    throw error;
  }
};
export function getAuthUser() {
  const userToken = localStorage.getItem("user-token")
  const userId = localStorage.getItem("userId")
  const user = userId ? JSON.parse(localStorage.getItem("user")) as IUser : null
  return { userToken, userId, user };
}
export async function getUserGroups(apiClient: AxiosInstance) {
  const response = await apiClient.get<{ rows: IGroup[] }>(`${API_BASE_URL}/User/me/groups`);
  return response.data
}
export async function forgetPassword(email: IUser['email']): Promise<string> {
  try {
    const response = await axios.post<{ message: string }>(
      `${API_BASE_URL}/auth/forgetPassword`,
      {
        email: email,
      }
    );
    if (response.data && response.data.message) {
      return response.data.message;
    } else {
      throw new Error('Unexpected response format');
    }
  } catch (error) {
    console.error('Error in forgetPassword:', error);
    throw error
  }
}
export const resetPassword = async (password: IUser['password'], resetPasswordToken: string) => {
  try {
    const response = await axios.post<{ token: string, user: IUser }>(`${API_BASE_URL}/auth/resetPassword?resetPasswordToken=${resetPasswordToken}`, {
      password: password,
    });
    const { token, user } = response.data;
    localStorage.setItem("user-token", token);
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("userId", user.id);
    return { token, user };
  } catch (error) {
    console.error("Error in post reset password for the user :" + error);
    throw error;
  }
};
export const getResetPassword = async (resetPasswordToken: string) => {
  try {
    const response = await axios.get<{ user: IUser }>(
      `${API_BASE_URL}/auth/resetPassword?resetPasswordToken=${resetPasswordToken}`
    );

    const { user } = response.data;
    return { user };
  } catch (error) {
    console.error("Error in get reset password for the user: " + error);
    throw error;
  }
};

export const activateAccount = async (email: string, activationCode: string) => {
  try {
    const response = await axios.post<{ token: string, user: IUser }>(
      `${API_BASE_URL}/auth/activateByCode`,
      {
        email: email,
        activationCode: activationCode
      }
    );
    const { user, token } = response.data;
    localStorage.setItem("user-token", token);
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("userId", user.id);
    return user;
  } catch (error) {
    console.error("Error activating account: " + error);
    throw error;
  }
};

