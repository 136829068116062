import React from 'react'
import { useLocation } from "react-router-dom";
import AdminFunction from '../../../components/Document/AdminFunction';
import CreateContractModal from '../../../components/modals/CreateContractModal';
import CreateFormModal from '../../../components/modals/CreateFormModal';
import { useTranslation } from '../../../contexts/TranslationProvider';
import CreateContractAIModal from '../../../components/modals/CreateContractAIModal';
import ImportDocumentModal from '../../../components/modals/ImportDocumentModal';
import "./CreateDocumentRequirement.css"
function CreateDocument({basePath}) {
  const { state } = useLocation()
  const dataProject = { requirement: state?.req, project: state?.project }
  const { t } = useTranslation();
  return (
    <div className="projects px-3 pt-2 w-100">
      <section id='create-documents-requirement'>
        <h3 className='create-requirement-title py-1'>{dataProject?.requirement?.name}</h3>
        <div className="container-action-requirement">
          {dataProject?.requirement?.types?.includes(2) && <div className="col-4 action-add-requirement">
            <AdminFunction title={t("pages.pilot.cards.contract.title")} description={t("pages.pilot.cards.contract.description")} buttonTitle={t("pages.pilot.cards.contract.button")}
              render={(onClose) => <CreateContractModal onClose={onClose} dataProject={dataProject} typeReq={2} basePath={basePath} />} />
          </div>}
          {dataProject?.requirement?.types?.includes(8) && <div className="col-4 action-add-requirement">
            <AdminFunction title={t("pages.pilot.cards.form.title")} description={t("pages.pilot.cards.form.description")} buttonTitle={t("pages.pilot.cards.form.button")}
              render={(onClose) => <CreateFormModal onClose={onClose} dataProject={dataProject} typeReq={8} basePath={basePath} />} />
          </div>}
          {dataProject?.requirement?.types?.includes(4) && <div className="col-4 action-add-requirement">
            <AdminFunction title={t("pages.listener.cards.contract.title")} description={t("pages.listener.cards.contract.description")} buttonTitle={t("pages.listener.cards.contract.button")}
              render={(onClose) => <CreateContractAIModal onClose={onClose} dataProject={dataProject} typeReq={4} basePath={basePath} />} />
          </div>}
          {dataProject?.requirement?.types?.includes(1) && <div className="col-4 action-add-requirement">
            <AdminFunction title={t("pages.viewProject.popups.importDocument.header")} description={t("pages.viewProject.popups.importDocument.header")} buttonTitle={t("pages.listener.cards.question.button")}
              render={(onClose) => <ImportDocumentModal onClose={onClose} dataProject={dataProject} typeReq={1} basePath={basePath}/>} />
          </div>}
        </div>
      </section>
    </div>
  )
}

export default CreateDocument