import React from 'react';
import { useState } from 'react';
import './VerificationSignUp.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CarouselSlider from '../../components/Carousel/Carousel';
import Email from "../../assets/verifyEmail.svg";
import Logo from "../../assets/authentication-logo.svg";
import { activateAccount } from '../../services/api/UserAuth';

interface FormData {
  code: string;
}

const VerificationSignUp = () => {
    const navigate=useNavigate()
    const location=useLocation()
    const emailUser=location?.state?.emailUser
    console.log('emailUser',emailUser)
  const [formData, setFormData] = useState<FormData>({
    code: '',
  });

  const [errors, setErrors] = useState<Record<string, string>>({
    code: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newErrors: Record<string, string> = {};

    if (!formData.code.trim()) {
      newErrors.code = 'Please enter your verification code';
    }
    try {
      const user = await activateAccount(emailUser, formData?.code);
      navigate("/steps-sign-up"); 
    } catch (error) {
      console.error("Error activating account: " + error);
    }
    setErrors(newErrors);
  };

  return (
    <div className='verification-content'>
      <CarouselSlider />
      <div className='verification-sub-content'>
        <div className="authentication-logo">
          <img src={Logo} width={200} alt="Logo" />
        </div>
        <div className='verification-titles'>
          <h1 className='title-verification'>Second Step Verification </h1>
          <p className='subtitle-verification'>
            Verify your email to complete registration. Check your inbox for a confirmation link.
          </p>
        </div>
        <form onSubmit={handleSubmit} className='form-verification'>
            <img src={Email} width={350} />
            <div style={{marginTop:"-5%"}}>
          <h6 className='verification-text'>
            Enter the verification code we sent to
            <span> Your E-mail</span>
          </h6>
          <div className="form-group verification-group">
            <input
              type="text"
              className={`input-code form-control ${errors.code ? 'is-invalid' : ''}`}
              id="code"
              name="code"
              placeholder="Type code here"
              value={formData.code}
              onChange={handleInputChange}
            />
            {errors.code && <div className="invalid-feedback">{errors.code}</div>}
          </div>
          <div className='verification-form-footer'>
            <button type="submit" className="btn btn-primary btn-verify">
              Verify
            </button>
            <p className='verification-question'>
              Didn't get the code? <Link to='' className='btn-resend'>Resend</Link>
            </p>
          </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VerificationSignUp;
