import React, { useState } from "react";
import "../../components/modals/commonmodal.css";

import apiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../../components/common/Loading";

import FileInput from "../../components/common/FileInput";
import { toast } from "react-toastify";
import { uploadDocument } from "../../services/api/DocumentApi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from '../../contexts/TranslationProvider';
import { updateProject } from "../../services/api/ProjectApi";
import { numberToEnumList } from "../../domain/Project";
import { IProject, IProjectTemplateRequirement } from "../../types/models";
interface ImportDocumentModalType {
    onClose: () => void;
    dataProject?: { requirement: IProjectTemplateRequirement, project: IProject };
    typeReq?: number,
    basePath?: string
}
function ImportDocumentModal({ onClose, dataProject, typeReq, basePath = '' }: ImportDocumentModalType) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [document, setDocument] = useState<File>(null);
    const handleSave = async () => {
        if (document) {
            const formApiClient = apiClientWithLoading(setLoading, "multipart/form-data");
            try {
                setLoading(true)
                const {
                    row: { id: documentId },
                } = await uploadDocument(formApiClient, document, document.name);
                const apiClient = apiClientWithLoading(setLoading, "application/json");
                if (dataProject?.project) {
                    const updatedProject = await updateProject(apiClient, dataProject?.project?.id, {
                        values: {
                            ...dataProject?.project?.values,
                            [dataProject?.requirement?.id]: {
                                type: numberToEnumList(typeReq)[0],
                                id: documentId,
                            },
                        },
                    });
                    toast.success("Document created successfully!");
                    navigate(`${basePath}/projet/${updatedProject?.row?.id}`)
                }
            } catch (error) {
                toast.error(error?.message ?? error ?? 'error uploading document');
                setLoading(false)
                onClose()
            }
        }
    };
    return (
        <>
            <div
                className="modal-backdrop fade show"
            ></div>
            <div id="contractz-lab">
                <div className="modal d-flex justify-content-center align-items-center">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{t("pages.viewProject.popups.importDocument.header")}
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={onClose}
                                    aria-label="Close"
                                    disabled={loading}
                                ></button>
                            </div>
                            {loading ? (
                                <Loading height="50vh" />
                            ) : (
                                <div className="modal-body">
                                    <div className="form-floating mb-3">
                                        <FileInput
                                            label={t("pages.listener.popups.question.add_doc")}
                                            onChange={(e) => setDocument(e.target.file)}
                                            value={document}
                                            borderClassName={document ? "green-border" : "grey-border"}
                                        />
                                    </div>
                                    <div className="modal-footer">
                                        <button type="submit" className="btn btn-primary" onClick={handleSave}>
                                            {t("pages.viewProject.popups.importDocument.btn")}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ImportDocumentModal;
