import React, { useState, useEffect } from 'react'
import expand from '../../styles/icons/expand'
import File from './File'
import { enumToString } from '../../domain/Project';
import { IProject, IProjectTemplate } from "../../types/models";
import { fetchProjectById } from "../../services/api/ProjectApi";
import apiClientWithLoading from "../../services/api/ApiClient";
import { useLocation } from 'react-router-dom';

function Topic({ project, projectTemplate, setLocation, idx, icons, basePath, hover }) {
    const pathname = useLocation().pathname;
    const [expanded, setExpanded] = useState(false)
    const [loading, setLoading] = useState(true)
    const [fullproject, setFullProject] = useState<IProject>(null)
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (project.id) {
                    const apiClient = apiClientWithLoading();
                    const data = await fetchProjectById(apiClient, parseInt(project.id));
                    setFullProject(data?.row);
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching contracts: ", error);
                setLoading(false);
            }
        }
        fetchData();
    }, [project])

    const onClickTopic = (idx) => {
        if (expanded) {
            setLocation(-1)
            setExpanded(!expanded)
        } else {
            setExpanded(!expanded)
            setLocation(idx)
        }
    }
    const expandedDisplay = (hover || pathname === basePath)

    return (
        <div key={idx} className='topic-item'>
            <div onClick={() => onClickTopic(idx)} className='topic-header'>
                <div className='topic-icon'>
                    {expanded ? icons[projectTemplate?.level1.display].activated : icons[projectTemplate?.level1.display].disactivated}
                </div>
                {expandedDisplay && <div className='topic-name'>
                    <span className='name'>
                        {projectTemplate?.level1.display}
                    </span>
                    <span className='number-files'>
                        {projectTemplate.requirements.length + " files"}
                    </span>
                </div>}

                <div className={`topic-expand ${expanded && 'rotate'}`}>
                    {expand}
                </div>
            </div>
            {expanded && expandedDisplay && <div className='topic-files'>
                {loading ? <div>loading</div>
                    :
                    fullproject?.template?.requirements.map((req, idx) => {
                        const docInfo = fullproject.values[req.id]
                        let docType
                        let ressource
                        if (docInfo?.type) {
                            docType = enumToString(docInfo?.type)
                            ressource = fullproject?.linkedRessources[docType].find(res => res.id === docInfo?.id)
                        }
                        return <File
                            project={fullproject}
                            typeRessource={docType}
                            documentReq={req}
                            ressource={ressource}
                            index={idx}
                            basePath={basePath}
                        />
                    })
                }
            </div>}
        </div>
    )
}

export default Topic