import { AxiosInstance } from "axios";
import { ITypeLevel1 } from "../../types/models";

const END_POINT = "TypeLevel1";

export const importTypeLevels = async (apiClient: AxiosInstance, file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await apiClient.post<{ rows: ITypeLevel1[] }>("/report/importTypeLevels", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;

}

export const getAllType1 = async (apiClient: AxiosInstance, schema: string = "nested") => {
  const response = await apiClient.get<{ count: number, rows: ITypeLevel1[] }>(`${END_POINT}/all?schema=${schema}`);
  return response.data;
};
export const getAllTypeLevel1WithContractTemplate = async (apiClient: AxiosInstance) => {
  const response = await apiClient.get<{ rows: ITypeLevel1[] }>(`TypeLevel/withContractTemplate`);
  return response.data;
};
export const getAllTypeLevel1WithContractAIForm = async (apiClient: AxiosInstance) => {
  const response = await apiClient.get<{ rows: ITypeLevel1[] }>(`TypeLevel/withContractAIForm`);
  return response.data;
};

export const getAllTypeLevel1WithFormTemplate = async (apiClient: AxiosInstance) => {
  const response = await apiClient.get<{ rows: ITypeLevel1[] }>(`TypeLevel/withFormTemplate`);
  return response.data;
};
export const getAllTypeLevel1WithProjectTemplate = async (apiClient: AxiosInstance) => {
  const response = await apiClient.get<{ rows: ITypeLevel1[] }>(`TypeLevel/withProjectTemplate`);
  return response.data;
};
