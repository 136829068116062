import React, { useState } from "react";
import "./CustomCombobox.css";
import { BsChevronDown, BsX } from "react-icons/bs";


interface CustomComboboxProps {
  options: any;
  value: any;
  onChange: any;
  label: any;
  canDeselect?: boolean;
  onDropdownOpen?: any;
  optionDisplay?: any;
  optionValue?: any;
  onClick?: any;
  optionIsEnabled?: any;
  fullWidth?: boolean;
}

const CustomCombobox = ({
  options,
  value,
  onChange,
  label,
  onClick,
  canDeselect,
  onDropdownOpen,
  optionIsEnabled,
  optionDisplay,
  optionValue,
  fullWidth }: CustomComboboxProps) => {

  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option) => {
    const value = optionValue ? optionValue(option) : option;
    setIsOpen(false);
    onChange(value);
  };

  const handleDropdownClick = (event) => {
    event.stopPropagation(); // Add this line to stop event propagation
    setIsOpen(!isOpen);
    if (!isOpen) {
      onDropdownOpen?.();
    }
  };

  const handleClearClick = (event) => {
    event.stopPropagation(); // Add this line to stop event propagation
    onChange(null);
  };

  const currentOptionDisplay = optionDisplay ? optionDisplay(value) : value;

  return (
    <div className="custom-combobox-main mb-3" style={fullWidth ? { width: '100%' } : null}>
      {label && <label className="custom-combobox-label">{label}</label>}
      <div className="custom-combobox-container" onClick={onClick}>
        <div className="custom-combobox">
          <div className="custom-combobox-selected" onClick={handleDropdownClick}>
            {currentOptionDisplay}
            {!isOpen && canDeselect && value && (
              <BsX
                color="#ff0000" // Set the color for the X icon
                style={{ position: "absolute", right: "30px", cursor: "pointer" }}
                onClick={handleClearClick}
              />
            )}
            <BsChevronDown color="#2f14e5" style={{ position: "absolute", right: "10px" }} />
          </div>
          {isOpen && (
            <div className="custom-combobox-options">
              {options.map((option, index) => {
                const key = `${label}_${index}`;
                const enabled = optionIsEnabled ? optionIsEnabled(option) : true;
                const display = optionDisplay ? optionDisplay(option) : option;
                return (
                  <div key={key} className={enabled ? "custom-combobox-option" : "custom-combobox-option-disabled"} onClick={() => enabled && handleOptionClick(option)}>
                    {display}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomCombobox;
