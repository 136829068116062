import { AxiosInstance } from "axios";
import { IContractAI } from "../../types/models";

const END_POINT = "/ContractAI";


export const fetchContractById = async (apiClient: AxiosInstance, id: IContractAI["id"]) => {
  const response = await apiClient.get<{ row: IContractAI }>(`${END_POINT}?schema=full&id=${id}`);
  return response.data;
};
export const fetchContractByFilter = async (
  apiClient: AxiosInstance,
  filterName: "level1.name" | "level2.name" | "level3.name",
  filterValue: string
) => {
  const body = {
    where: {
      [filterName]: filterValue,
    },
  };
  const response = await apiClient.post<{ count: number, rows: IContractAI[] }>(`${END_POINT}/all`, body);
  return response.data;
};

export const createContract = async (apiClient: AxiosInstance, file: Blob, name: IContractAI["name"], formId: IContractAI["formId"]) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", name);
  formData.append("formId", String(formId));

  const response = await apiClient.post<{ row: IContractAI }>(
    `${END_POINT}?schema=nested`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const patchContract = async (apiClient: AxiosInstance, contractId: IContractAI["id"], data: IContractAI) => {
  const response = await apiClient.patch<{ row: IContractAI }>(
    `${END_POINT}?id=${contractId}&schema=nested`, // Add contractId as query param
    { data }
  );
  return response.data;
};
export const fetchContractAIByFilter = async <K extends keyof IContractAI>(
  apiClient: AxiosInstance,
  filterName: K,
  filterValue: IContractAI[K]
) => {
  const body = {
    where: {
      [filterName]: filterValue,
    },
  };
  const response = await apiClient.post<{ count: number, rows: IContractAI[] }>(`${END_POINT}/all`, body);
  return response.data;
}
export const deleteContractAI = async (apiClient: AxiosInstance, id: IContractAI['id']) => {
  const response = await apiClient.delete<{ deleted: boolean }>(
    `${END_POINT}?id=${id}`,
  );
  return response.data;
};