import { useContext } from "react";
import InputValuesContext from "../../contexts/InputValuesContext";
import { useTranslation } from "../../contexts/TranslationProvider";
import { useParamProcessing } from "../../hooks/useParamProcessing";
import { useSubmitContract } from "../../hooks/useSubmitContract";
import { IContract } from "../../types/models";
import "./buttons.css";

function EditionContractSaveButton() {
  const {
    inputValues,
    segmentsOverrides,
    commentsOverrides,
    excludedClauses,
    excludedSubClauses,
    templateId,
    templateData,
    completionPercentage,
    contractId,
  } = useContext(InputValuesContext);

  const processParamValues = useParamProcessing();
  const submitContract = useSubmitContract();

  const { t } = useTranslation();
  const handleFormSubmit = async () => {
    const { processedParamValues, hasError } = await processParamValues(
      inputValues
    );
    if (!hasError) {
      let status: IContract['status'] = "Draft";
      if (completionPercentage === 100) {
        status = "Done";
      }
      const body = {
        templateId: templateId,
        paramValues: processedParamValues,
        segmentsOverrides: segmentsOverrides,
        commentsOverrides,
        excludedSubClauses: excludedSubClauses,
        excludedClauses: excludedClauses,
        status: status,
        name: inputValues.contractName,
      };
      await submitContract(body, contractId);
    }
  };

  return (
    <button className="action-button" onClick={handleFormSubmit}>
      {completionPercentage !== 100
        ? t("navbar.save2")
        : t("navbar.save1")}
    </button>
  );
}

export default EditionContractSaveButton;
