import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useEffect,
} from "react";
import { AiFillDelete, AiOutlineUpload } from "react-icons/ai";
const FileInput = forwardRef<any, any>(({ onChange, value, label }: {
  onChange: (e: { target: { type: 'file', file: Buffer } }) => void;
  value: { name: string; };
  label?: string;
}, ref) => {
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    if (value) {
      setFileName(value.name);
    } else {
      setFileName("");
    }
  }, [value]);

  const triggerFileInput = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  useImperativeHandle(ref, () => ({
    triggerFileInput,
  }));

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      onChange({ target: { type: "file", file } });
    }
  };

  const deleteFile = () => {
    setFileName("");
    onChange({ target: { type: "file", file: null } });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",

        padding: "5px 4px 5px 10px",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        borderRadius: "5px",
      }}
    >
      <div className="d-flex ms-1">
        {fileName !== "" && (
          <AiFillDelete
            style={{
              marginRight: "0.5rem",
              color: "#2f14e5",
              cursor: "pointer",
            }}
            onClick={deleteFile}
          />
        )}
        <AiOutlineUpload
          style={{ marginRight: "0.5rem", color: "#2f14e5", cursor: "pointer" }}
          onClick={triggerFileInput}
        />
      </div>
      <p className="input-type-file" style={{ margin: 0 }}>{fileName || label || "Choose a file"}</p>
      <input
        type="file"
        style={{ display: "none", border: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </div>
  );
});

export default FileInput;
