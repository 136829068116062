import  { createContext } from 'react';

export interface FileSelectionContextType {
    selectedFile : any;
    setSelectedFile : any;
}

const FileSelectionContext = createContext<FileSelectionContextType>({
}as any);

export default FileSelectionContext;