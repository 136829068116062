import { AxiosInstance } from "axios";
import { IFormTemplate, ITypeLevel1, ITypeLevel2, ITypeLevel3 } from "../../types/models";

const END_POINT = "/FormTemplate";

const FormTemplateAPI = {
  importTemplate: async (apiClient: AxiosInstance, file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    const response = await apiClient.post<{ rows: IFormTemplate[] }>("/report/importFormTemplate", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;

  },
  getAllTemplates: async (apiClient: AxiosInstance) => {
    try {
      const response = await apiClient.get<{ count: number, rows: IFormTemplate[] }>(`${END_POINT}/all`);
      return response.data;
    } catch (error) {
      console.error("Error fetching all templates:", error);
      return null;
    }
  },

  getTemplateById: async (apiClient: AxiosInstance, id: IFormTemplate['id'], schema = "full") => {
    try {
      const response = await apiClient.get<{ row: IFormTemplate }>(`${END_POINT}/`, {
        params: { schema, id },
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching template with ID: ${id}`, error);
      return null;
    }
  },
  getAllNestedTemplates: async (apiClient: AxiosInstance, id: IFormTemplate['id']) => {
    try {
      const response = await apiClient.get<{ count: number, rows: IFormTemplate[] }>(`${END_POINT}/all`, {
        params: { schema: "nested", id },
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching nested templates with ID: ${id}`, error);
      return null;
    }
  },

  getTemplatesByLevels: async (
    apiClient: AxiosInstance,
    levelsFilter: {
      level1Id: ITypeLevel1['id'];
      level2Id: ITypeLevel1['id'];
      level3Id: ITypeLevel1['id'];
    },
    schema = "full"
  ) => {
    try {
      const response = await apiClient.post<{ count: number, rows: IFormTemplate[] }>(
        `${END_POINT}/all`,
        {
          where: levelsFilter,
        },
        {
          params: { schema },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        `Error fetching templates with levels: ${levelsFilter}`,
        error
      );
      return null;
    }
  },
};

export default FormTemplateAPI;
