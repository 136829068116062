import "./navbar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAuthUser, signIn } from "../../../services/api/UserAuth";
import { navbarIcons, userSetting, logo } from "./icons";
import CustomCombobox from "../../common/CustomCombobox";

function Navbar() {
	const { user, userId, userToken } = getAuthUser()
	const location = useLocation();
	const navigate = useNavigate()
	const users = [
		{
			email: "contractzlab.admin@yopmail.com",
			password: "admin",
		},
		{
			email: "fares@contractzlab.com",
			password: "123456",
		},
		{
			email: "rafik@contractzlab.com",
			password: "123456",
		},
	];
	const locations = [
		{
			location: "/map",
			label: "Map",
			icon: navbarIcons["map"]
		},
		{
			location: "/data-room",
			label: "Data Room",
			icon: navbarIcons["dataRoom"]
		},
		{
			location: "/equity",
			label: "Equity",
			icon: navbarIcons["equity"]
		},
		{
			location: "/health-check",
			label: "Health Check",
			icon: navbarIcons["health"]
		}
	]
	const handleInputChange = async (value) => {
		console.log({ user, userId, userToken });
		const userOption = users.find((user) => user.email == value);
		if (userOption) {
			await signIn(userOption.email, userOption.password);
		}
	};
	const rootLocation = {
		location: "/",
		label: "Dashboard",
		icon: navbarIcons["dashboard"]
	}
	const changeLocation = (destination) => {
		navigate(destination)
	}
	return (
		<>
			<div className="navbar-container">
				{user ? <div className="navbar-user">
					<div className="navbar-user-icon">
						<div className="navbar-user-circle">
							<div className="navbar-user-avatar">
								{user.email[0].toUpperCase()}
							</div>
						</div>
					</div>
					<div className="navbar-user-name">
						<div className="navbar-user-section">
							{user.fullName}
							<div className="navbar-user-settings">
								{userSetting}
							</div>
						</div>
					</div>
				</div> :
					<CustomCombobox
						label=""
						onDropdownOpen={() => { }}
						options={users}
						value={user}
						onChange={handleInputChange}
						optionValue={(option) => option?.email || null}
						optionDisplay={(option) => option?.email || ""}
					/>
				}
				<div className="navbar-icon">
					{logo}
				</div>
				<div className="navbar-navigation">
					<div onClick={() => changeLocation(rootLocation.location)} className={`navbar-navigation-item ${location.pathname == rootLocation.location && "active-location"}`}>
						<div className="navbar-navigation-item-label">
							{rootLocation.label}
						</div>
						<div className="navbar-navigation-item-icon">
							{rootLocation.icon}
						</div>
					</div>
					{locations.map((element, idx) => <>
						<div onClick={() => changeLocation(element.location)} key={idx} className={`navbar-navigation-item ${location.pathname.startsWith(element.location) && "active-location"}`}>
							<div className="navbar-navigation-item-label">
								{element.label}
							</div>
							<div className="navbar-navigation-item-icon">
								{element.icon}
							</div>
						</div>
					</>)}
				</div>
			</div>
		</>
	);
}

export default Navbar;
