// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-input-container {
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
}

.input-with-icon {
    position: relative;
    width: 100%;
}

.input-icon {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--primary-color);
    pointer-events: none;
}

.date-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--primary-color);
    background-color: #fff;
    background-clip: padding-box;
    appearance: none;
    cursor: pointer;
}

.date-input:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.1rem var(--primary-color-darker);
}
.grey-border {
    border: 2px solid #acabba !important;
}
.green-border {
    border: 2px solid var(--ai-success-medium) !important;
}
.react-datepicker__input-container input {
    border: none !important;
    cursor: pointer;
    outline: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Form/FormDateInput.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,QAAQ;IACR,2BAA2B;IAC3B,2BAA2B;IAC3B,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,2BAA2B;IAC3B,sBAAsB;IACtB,4BAA4B;IAC5B,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,UAAU;IACV,oDAAoD;AACxD;AACA;IACI,oCAAoC;AACxC;AACA;IACI,qDAAqD;AACzD;AACA;IACI,uBAAuB;IACvB,eAAe;IACf,wBAAwB;AAC5B","sourcesContent":[".date-input-container {\n    display: flex;\n    flex-direction: column;\n    position: relative;\n    cursor: pointer;\n}\n\n.input-with-icon {\n    position: relative;\n    width: 100%;\n}\n\n.input-icon {\n    position: absolute;\n    right: 0.5rem;\n    top: 50%;\n    transform: translateY(-50%);\n    color: var(--primary-color);\n    pointer-events: none;\n}\n\n.date-input {\n    width: 100%;\n    padding: 0.5rem;\n    border: 1px solid #ced4da;\n    border-radius: 4px;\n    font-size: 1rem;\n    line-height: 1.5;\n    color: var(--primary-color);\n    background-color: #fff;\n    background-clip: padding-box;\n    appearance: none;\n    cursor: pointer;\n}\n\n.date-input:focus {\n    border-color: #80bdff;\n    outline: 0;\n    box-shadow: 0 0 0 0.1rem var(--primary-color-darker);\n}\n.grey-border {\n    border: 2px solid #acabba !important;\n}\n.green-border {\n    border: 2px solid var(--ai-success-medium) !important;\n}\n.react-datepicker__input-container input {\n    border: none !important;\n    cursor: pointer;\n    outline: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
