import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import apiClientWithLoading from "../services/api/ApiClient";
import { createForm, updateForm } from "../services/api/FormApi";
import { toast } from "react-toastify";
import { LoadingContext } from "../contexts/LoadingContext";
import { IForm } from "../types/models";
import { EditFormContextType } from "../contexts/EditFormContext";
import { isFileType } from "../helpers/helper";
import { uploadDocument } from "../services/api/DocumentApi";
import { updateProject } from "../services/api/ProjectApi";
import { numberToEnumList } from "../domain/Project";

export function useSubmitForm() {
  const { setLoading } = useContext(LoadingContext);
  const Navigate = useNavigate()

  const submitForm = async (context: EditFormContextType) => {
    const {
      formId,
      name,
      paramValues,
      templateId,
      completionPercentage,
      setTemplate,
      setParamValues,
      setTemplateId,
      setGroups,
      setFormId,
      setForm,
      project,
      setProject,
      requirementType,
      requirement
     } = context
    const body: IForm = {
      templateId: templateId,
      paramValues: paramValues,
      status: completionPercentage === 100 ? "Done" : "Draft",
      name: name,
    };
    try {
      const formApiClient = apiClientWithLoading(setLoading, "multipart/form-data");
      const apiClient = apiClientWithLoading(setLoading, "application/json");
      console.log(body);

      let paramValues = body.paramValues!
      let hasError = false
      for (const key of Object.keys(paramValues)) {
        const value = paramValues[key];

        if (isFileType(value)) {
          const file = value;
          const name = file.name;
          try {
            const {
              row: { id },
            } = await uploadDocument(formApiClient, file, name);
            paramValues[key] = id;
          } catch (error) {
            hasError = true;
            console.error(`Error uploading file for key ${key}:`, error);
            toast.error(`Error uploading file for ${key}`);
            break;
          }
        }
      }
      body.paramValues = paramValues

      let submitedFormId: IForm['id']
      if (formId) {
        const response = await updateForm(apiClient, formId, body);
        submitedFormId = response.row.id
        toast.success("Form updated successfully!");
      } else {
        const response = await createForm(apiClient, body);
        submitedFormId = response.row.id
        if(project){
          const updatedProject = await updateProject(apiClient, project?.id, {
            values: {
              ...project?.values,
              [requirement.id]: {
                type: numberToEnumList(requirementType)[0],
                id: submitedFormId,
              },
            },
          });
          setProject(updatedProject.row);
        }
        Navigate(`/data-room/edit-form/${submitedFormId}`)
        toast.success("Form created successfully!");
      }
      setParamValues({});
      setTemplateId(null);
      setGroups([]);
      setTemplate(null)
      setFormId(null);
      setForm(null)
    
      return submitedFormId
    } catch (error) {
      const action = formId ? "updating" : "creating";
      console.error(error);

      toast.error(`Error ${action} form!`);
    }
  };

  return submitForm;
}
