import { AxiosInstance } from "axios";
import { IForm, IFormParamValue, IGroup, IUser } from "../../types/models";

const END_POINT = "/Form";

interface FormValidateReq {
  validateGroupId?: IGroup["id"],
  assignToGroupId?: IGroup["id"],
  assignToUserId?: IUser["id"],
}

export const getFormParamValues = async (apiClient: AxiosInstance, id: IForm['id']) => {
  const response = await apiClient.get<{
    formParamValues: IFormParamValue[],
    fileNames: IForm['fileNames'],
    beneficialsMap: IForm['beneficialsMap'],
  }>(`${END_POINT}/${id}/get/formParamValues`);
  return response.data;
};

export const validateForm = async (apiClient: AxiosInstance, id: IForm['id'], validationData: FormValidateReq) => {
  const response = await apiClient.post<{ count: number, rows: IForm[] }>(`${END_POINT}/${id}/validate`, validationData);
  return response.data;
};

export const fetchFormById = async (apiClient: AxiosInstance, id: IForm['id']) => {
  const response = await apiClient.get<{ row: IForm }>(`${END_POINT}?schema=full&id=${id}`);
  return response.data;
};

export const fetchFormByFilter = async <K extends keyof IForm>(
  apiClient: AxiosInstance,
  filterName: K,
  filterValue: IForm[K]
) => {
  const body = {
    where: {
      [filterName]: filterValue,
    },
  };

  const response = await apiClient.post<{ count: number, rows: IForm[] }>(`${END_POINT}/all`, body);
  return response.data;
};

export const createForm = async (apiClient: AxiosInstance, data: IForm) => {
  const response = await apiClient.post<{ row: IForm }>(`${END_POINT}?schema=nested`, { data });
  return response.data;
};

export const updateForm = async (apiClient: AxiosInstance, id: IForm['id'], data: IForm) => {
  const response = await apiClient.patch<{ row: IForm }>(
    `${END_POINT}?id=${id}&schema=nested`,
    { data }
  );
  return response.data;
};
export const getAssignedGroupForms = async (apiClient: AxiosInstance) => {
  const reponse = await apiClient.get<{ count: number, rows: IForm[], groups: IGroup[] }>(`${END_POINT}/assignedGroupForms`);
  return reponse.data;
};
export const getAllForms = async (apiClient: AxiosInstance) => {
  const reponse = await apiClient.get<{ count: number, rows: IForm[] }>(`${END_POINT}/all`);
  return reponse.data;
};

export const deleteForm = async (apiClient: AxiosInstance, id: IForm['id']) => {
  const response = await apiClient.delete<{ deleted: boolean }>(
    `${END_POINT}?id=${id}`,
  );
  return response.data;
};