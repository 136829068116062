import { IBeneficial } from "./models"

export enum SegmentedTextType {
    STATIC = 0,
    PARAM = 1,
    COMMENT = 2,
    PARAM_VALUE = 3,
    PARAM_TABLE_VALUE = 4,
    PARAM_COMMENT_VALUE = 5,
    PARAM_START = 6,
    PARAM_END = 7,
}

export type SegmentedText = [string, string, SegmentedTextType][]
type BasicSegment = {
    type: SegmentedTextType.STATIC
    | SegmentedTextType.COMMENT;
    id: string;
    value: string;
}

type ParamValueSegment = {
    type: SegmentedTextType.PARAM
    | SegmentedTextType.PARAM_VALUE
    | SegmentedTextType.PARAM_COMMENT_VALUE
    | SegmentedTextType.PARAM_TABLE_VALUE
    | SegmentedTextType.PARAM_START
    | SegmentedTextType.PARAM_END
    id: string;
    value: string;
    paramName: string;
}

export type RenderSegment = BasicSegment
    | ParamValueSegment;

export type RenderSegments = RenderSegment[]

type BaseParam =
    {
        name: string,
        label: string,
        detected?: boolean,
    }

type AnyParam = BaseParam &
{
    type: 'string' | 'date' | 'number' | 'file' | 'comment'
}

export type BeneficialParam = BaseParam &
{
    args: { beneficialTypes: (IBeneficial['type'])[] },
    type: 'beneficial'
}

export type SegmentedBeneficialParam = BaseParam &
{
    args: { beneficialTypes: (IBeneficial['type'])[] },
    type: 'beneficial'
}

export type BeneficialListParam = BaseParam &
{
    args: { beneficialTypes: (IBeneficial['type'])[], textPerBeneficial: string },
    type: 'beneficial[]'
}

export type SegmentedBeneficialListParam = BaseParam &
{
    args: { beneficialTypes: (IBeneficial['type'])[], textPerBeneficial: SegmentedText },
    type: 'beneficial[]'
}

type BooleanParam = BaseParam &
{
    args: { textIfTrue: string, textIfFalse: string },
    definition: number;
    type: 'boolean'
}

type SegmentedBooleanParam = BaseParam &
{
    args: { textIfTrue: SegmentedText, textIfFalse: SegmentedText },
    definition: number;
    type: 'boolean'
}

type EnumParam = BaseParam &
{
    args: { option: string, text: string }[],
    definition: number;
    type: 'enum'
}
export type EnumOption = {
    option: string, text: string
}
export type ListOption = {
    option: string, required: boolean
}

type SegmentedEnumParam = BaseParam &
{
    args: { option: string, text: SegmentedText }[],
    definition: number;
    type: 'enum'
}

export type ListParam = BaseParam &
{
    args: { option: string, required: boolean }[],
    type: 'list'
}

export type CSVParam = BaseParam &
{
    type: 'csv'
}

export type TableParam = BaseParam &
{
    transposed: boolean,
    args: { header: string, type: 'string' | 'date' | 'number' }[],
    type: 'table'
}
export type TableLigne = {
    header: string,
    type: 'string' | 'date' | 'number'
}

export type ClauseParam = AnyParam
    | BeneficialParam
    | BeneficialListParam
    | BooleanParam
    | EnumParam
    | ListParam
    | TableParam
    | CSVParam

export type SegmentedClauseParam = AnyParam
    | SegmentedBeneficialParam
    | SegmentedBeneficialListParam
    | SegmentedBooleanParam
    | SegmentedEnumParam
    | ListParam
    | TableParam
    | CSVParam

export type ClauseParams = ClauseParam[]

export type SegmentedClauseParams = SegmentedClauseParam[]
