import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Home } from "./Home";
import { BrowserRouter as Router , Route, Routes} from "react-router-dom";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import "./fonts.css";
import "./colors.css";
import VerificationSignUp from './pages/VerificationSignUp/VerificationSignUp';
import StepsSignUp from './pages/StepsSignUp/StepsSignUp';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<Home/>}/>
        <Route path='/sign-up' element={<SignUp/>} />
        <Route path='/verify-sign-up' element={<VerificationSignUp/>} />
        <Route path='/sign-in' element={<SignIn/>} />
        <Route path='/steps-sign-up' element={<StepsSignUp/>} />
      </Routes>
    </Router>
  );
};

export default App;
