import { useContext } from "react";
import AiValidationContext from "../../contexts/AiValidationContext";
import { LoadingContext } from "../../contexts/LoadingContext";
import apiClientWithLoading from "../../services/api/ApiClient";
import { patchContract } from "../../services/api/ContractAIApi";
import { toast } from "react-toastify";
import { removeIndex } from "../../helpers/helper";
import { AxiosInstance } from "axios";
import { useTranslation } from "../../contexts/TranslationProvider";
import "./buttons.css"

function AiContractSaveButton() {
  const {
    aiCompletionPercentage,
    aiResponsesStatus,
    setAiResponseStatus,
    aiContractData,
    setAiContractData,
    project
  } = useContext(AiValidationContext);

  const { setLoading } = useContext(LoadingContext);
  const { t } = useTranslation();
  
  
  

  const submitContract = async (apiClient: AxiosInstance, status, aiResponsesStatus) => {
    try {
      const data = {
        aiResponsesStatus,
        status,
      };
      await patchContract(apiClient, aiContractData.contractId, data);
      setAiResponseStatus([]);
      setAiContractData({
        contractName: "",
        contractId: null,
        formId: null,
      });
      setTimeout(() => {
        toast.success("Contract created successfully!")
      }, 100);
    } catch (error) {
      console.error(error);
      toast.error("Error creating contract!");
    }
  };
  const handleFormSubmit = async () => {
    const apiClientMultipart = apiClientWithLoading(setLoading);

    const status = aiCompletionPercentage === 100 ? "Done" : "Draft";

    const finalResponse = removeIndex(aiResponsesStatus);
    submitContract(apiClientMultipart, status, finalResponse);
  };
  return (
    <button className="action-button me-5" onClick={handleFormSubmit}>
      {aiCompletionPercentage !== 100
        ? t("navbar.save2")
        : t("navbar.save1")}
    </button>
  );
}

export default AiContractSaveButton;
