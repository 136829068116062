// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-action-requirement{
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
}
.container-action-requirement>div{
    margin-right: 25px;
    margin-top: 4%;
}`, "",{"version":3,"sources":["webpack://./src/pages/subPages/CreateDoc/CreateDocumentRequirement.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".container-action-requirement{\n    display: flex;\n    flex-direction:row;\n    flex-wrap: wrap;\n}\n.container-action-requirement>div{\n    margin-right: 25px;\n    margin-top: 4%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
