import { createContext } from "react";
import { IBeneficial, IContract, IContractTemplate, IGroup, IProject, IProjectTemplateRequirement } from "../types/models";
import { ContractTemplateParsedData } from "../helpers/ParseTemplateData";

export interface InputValuesContextType {
  contract: IContract;
  setContract: React.Dispatch<React.SetStateAction<IContract>>;
  templateData: ContractTemplateParsedData;
  setTemplateData: React.Dispatch<React.SetStateAction<ContractTemplateParsedData>>;
  inputValues: IContract['paramValues'];
  setInputValues: React.Dispatch<React.SetStateAction<IContract['paramValues']>>;
  fileNames: IContract['fileNames'];
  setFileNames: React.Dispatch<React.SetStateAction<IContract['fileNames']>>;
  beneficialsMap: Record<string, IBeneficial>;
  setBeneficialsMap: React.Dispatch<React.SetStateAction<IContract['beneficialsMap']>>;
  segmentsOverrides: IContract['segmentsOverrides'];
  setSegmentsOverrides: React.Dispatch<React.SetStateAction<IContract['segmentsOverrides']>>;
  commentsOverrides: IContract['commentsOverrides'];
  setCommentsOverrides: React.Dispatch<React.SetStateAction<IContract['commentsOverrides']>>;
  completionPercentage: number,
  setCompletionPercentage: React.Dispatch<React.SetStateAction<number>>;
  excludedSubClauses: IContract['excludedSubClauses'];
  setExcludedSubClauses: React.Dispatch<React.SetStateAction<IContract['excludedSubClauses']>>;
  excludedClauses: IContract['excludedClauses'];
  setExcludedClauses: React.Dispatch<React.SetStateAction<IContract['excludedClauses']>>;
  templateId: IContractTemplate['id'],
  setTemplateId: React.Dispatch<React.SetStateAction<IContractTemplate['id']>>;
  groups: IGroup[];
  setGroups: React.Dispatch<React.SetStateAction<IGroup[]>>;
  contractId: IContract['id'],
  setContractId: React.Dispatch<React.SetStateAction<IContract['id']>>;
  contractListParentRef: any,
  userGroup:number[],
  setUserGroup: React.Dispatch<React.SetStateAction<number[]>>;
  setAccessUser:React.Dispatch<React.SetStateAction<boolean[]>>
  accessUser:boolean[],
  groupContract:string,
  setGroupContract:React.Dispatch<React.SetStateAction<string>>,
  project?: IProject
  setProject?:React.Dispatch<React.SetStateAction<IProject>>;
  requirement?: IProjectTemplateRequirement,
  setRequirement?:React.Dispatch<React.SetStateAction<IProjectTemplateRequirement>>
  requirementType?:number,
  setRequirementType?:React.Dispatch<React.SetStateAction<number>>
}
const InputValuesContext = createContext<InputValuesContextType>({

} as any);

export default InputValuesContext;
