import "./ClauseInput.css";
import { useContext, useEffect, useState } from "react";
import InputValuesContext from "../../contexts/InputValuesContext";
import SelectionContext from "../../contexts/SelectionContext";
import { NestedGroupedParsedClauseParam, getClauseName, getSubClauseName, ContractTemplateParsedData } from "../../helpers/ParseTemplateData";
import { FieldValues, UseFormReturn } from "react-hook-form";
import React from "react";
import { isClauseExcluded, isSubClauseExcluded } from "../../helpers/helper";
import ParameterInput from "./ParameterInput";
import { useCollapse } from "react-collapsed";
import { isValidInputValue } from "../../helpers/CompletionPercentage";
import { removeDuplicates } from "../../utils/array";
function ClauseInput({
  nestedGroupedParam,
  clauseIndex,
  isOptionalClause,
  optionalSubClauses,
  isEditing,
  form,
  handleExclusionToggle,
  handleClick,
  inputGroupRef,
  clause
}: {
  nestedGroupedParam: NestedGroupedParsedClauseParam;
  clauseIndex: number;
  isOptionalClause: boolean;
  optionalSubClauses: any[];
  isEditing: boolean;
  form: UseFormReturn<FieldValues, any>;
  handleExclusionToggle(event: any, id: any, isSubclause: any): void;
  handleClick: (clauseId: any) => void;
  inputGroupRef: React.MutableRefObject<any>;
  clause: ContractTemplateParsedData[number]
}) {
  const {
    inputValues,
    setInputValues,
    excludedSubClauses,
    excludedClauses,
    accessUser
  } = useContext(InputValuesContext);
  const { selected, setSelected } = useContext(SelectionContext);
  const isSelected = selected.clauseId === nestedGroupedParam.clauseId;
  const [isExpanded, setExpanded] = useState(isSelected);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const isAccessible = accessUser.hasOwnProperty(clause.code) ? accessUser[clause.code] : true;

  useEffect(() => {
    isAccessible && setExpanded(isSelected)
  }, [isSelected]);

  function handleInputChange(
    e,
    paramName,
    clauseId,
    subClauseId,
  ) {
    let value = e.target.value;
    if (paramName && isValidInputValue(value)) {
      isAccessible && setSelected({
        eventType: 'ParamValueChange',
        clauseId: clauseId,
        paramName
      })
    }

    setInputValues((prevState) => ({
      ...prevState,
      [paramName]: value,
    }));
  }




  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = form;



  function getCurrentValue(param, inputValues) {
    let currentValue;

    currentValue = inputValues?.[param.name] ?? "";

    return currentValue;
  }

  return (
    <div
      key={nestedGroupedParam.clauseId}
      id="clause-group"
      className={`clause-input-${nestedGroupedParam.clauseId} edit-contract-clause-input px-4 py-2 mx-1 my-2 ${isSelected && isAccessible ? "selected-input-container" : ""}`}
      onClick={() => handleClick(nestedGroupedParam.clauseId)}
      ref={isSelected ? inputGroupRef : null}

    >
      <div className="d-flex gap-2" {...getToggleProps()}>
        <div className="edit-contract-clause-input-header">
          <b>{getClauseName(nestedGroupedParam.clauseName, clauseIndex)}</b>
        </div>
        {isOptionalClause && isAccessible && isSelected && (
          <React.Fragment key={`${nestedGroupedParam.clauseId}_toggle`}>
            <div className="switch">
              <input
                type="checkbox"
                id={`toggle_${nestedGroupedParam.clauseId}_${(nestedGroupedParam as any).subClauseId}`}
                defaultChecked={!isClauseExcluded(nestedGroupedParam.clauseId, excludedClauses)}
                onChange={(event) => handleExclusionToggle(event, nestedGroupedParam.clauseId, false)}
              />
              <label
                htmlFor={`toggle_${nestedGroupedParam.clauseId}_${(nestedGroupedParam as any).subClauseId}`}
              ></label>
            </div>
          </React.Fragment>
        )}
      </div>
      <div {...getCollapseProps()}>
        <div
          key={`${nestedGroupedParam.clauseId}`}
          onClick={() => handleClick(nestedGroupedParam.clauseId)}
        >
          {removeDuplicates(nestedGroupedParam.params, 'name').map((param) => {
            const currentValue = getCurrentValue(param, inputValues);
            return (
              <div
                key={`${param.name}${param.clauseId}${param.subClauseId}`}
              >
                <div className="px-2 py-1">
                  <ParameterInput
                    param={param}
                    clauseId={param.clauseId}
                    subClauseId={param.subClauseId}
                    control={control}
                    currentValue={currentValue}
                    handleInputChange={handleInputChange}
                    register={register}
                    errors={errors}
                    isEditing={isEditing}
                  />
                </div>{" "}
              </div>
            );
          })}
        </div>
        {nestedGroupedParam.subclauses.map((group, subClauseIndex) => {
          const subClauseParams = group.params
            .filter(subClaueParam => !nestedGroupedParam.params.find(clauseParam => clauseParam.name == subClaueParam.name))

          if (!subClauseParams.length)
            return <React.Fragment key={`${group.clauseId}_${group.subClauseId}`}>
            </React.Fragment>
          return (
            <div
              key={`${group.clauseId}_${group.subClauseId}`}
              onClick={() => handleClick(group.clauseId)}
            >
              <h4 className="input-group-subtitle text-start py-2">
                {getSubClauseName(group.subClauseName, clauseIndex, subClauseIndex)}
              </h4>
              {removeDuplicates(subClauseParams, 'name').map((param) => {
                const currentValue = getCurrentValue(param, inputValues);
                return (
                  <div
                    key={`${param.name}${param.clauseId}${param.subClauseId}`}
                  >
                    <div className="px-2 py-1">
                      <ParameterInput
                        param={param}
                        clauseId={param.clauseId}
                        subClauseId={param.subClauseId}
                        control={control}
                        currentValue={currentValue}
                        handleInputChange={handleInputChange}
                        register={register}
                        errors={errors}
                        isEditing={isEditing}
                      />
                    </div>{" "}
                  </div>
                );
              })}
            </div>
          );
        })}
        {/* Render the optional subclause toggle buttons at the end */}
        {nestedGroupedParam.subclauses.map((group, subClauseIndex) => {
          const optionalSubclause = optionalSubClauses.find(
            (optionalSubClause) =>
              optionalSubClause.clauseId === group.clauseId &&
              optionalSubClause.subClauseId === group.subClauseId
          );

          const isOptionalSubclause = optionalSubclause !== undefined;

          return (
            isOptionalSubclause && (
              <React.Fragment
                key={`${group.clauseId}_${group.subClauseId}_toggle`}
              >
                <div className="d-flex gap-2">
                  <div className="switch">
                    <input
                      type="checkbox"
                      id={`toggle_${group.clauseId}_${group.subClauseId}`}
                      defaultChecked={!isSubClauseExcluded(group.subClauseId, excludedSubClauses)}
                      onChange={(event) => handleExclusionToggle(event, group.subClauseId, true)}
                    />
                    <label
                      htmlFor={`toggle_${group.clauseId}_${group.subClauseId}`}
                    ></label>
                  </div>
                  <label>{getSubClauseName(group.subClauseName, clauseIndex, subClauseIndex)}</label>
                </div>
              </React.Fragment>
            )
          );
        })}
      </div>
    </div>
  );
}
export default ClauseInput;
