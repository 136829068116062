import React, { useState, useMemo } from 'react'
import dots from '../../styles/icons/dots'
import { topics, activeTopics } from '../../styles/icons/topics'
import expand from '../../styles/icons/expand'
import Topic from './Topic'
import ProjectContext from "../../contexts/ProjectContext";
import { useLocation } from 'react-router-dom';
import FileSelectionContext from '../../contexts/FileSelectionContext'

function DataRoomNavigator({basePath, hover}) {
    const { pathname } = useLocation();
    const [location, setLocation] = useState(0)
    const { projects, projectTemplate } = React.useContext(ProjectContext);
    const [selectedFile, setSelectedFile] = React.useState(null)
    const FileSelectionContextValue = useMemo(() => {
		return { selectedFile, setSelectedFile }

	},[selectedFile])
    const icons = {
        "Traction": {activated: activeTopics["Metrics"] ,disactivated: topics["Metrics"]},
        "Sales & Marketing": {activated: activeTopics["Sales"] ,disactivated: topics["Sales"]},
        "Product and Technology": {activated: activeTopics["Product"] ,disactivated: topics["Product"]},
        "Legal documents": {activated: activeTopics["Product"] ,disactivated: topics["Product"]},
        "Investment Records": {activated: activeTopics["Product"] ,disactivated: topics["Product"]},
        "Intellectual Property": {activated: activeTopics["Product"] ,disactivated: topics["Product"]},
        "Governance": {activated: activeTopics["Product"] ,disactivated: topics["Product"]},
        "General Company Documents": {activated: activeTopics["General"] ,disactivated: topics["General"]},
        "Human Ressources & Service Providers": {activated: activeTopics["Product"] ,disactivated: topics["Product"]},
        "Project": {activated: activeTopics["Product"] ,disactivated: topics["Product"]},

    }
    const expandedDisplay = (hover || pathname === basePath)
    return (
        <FileSelectionContext.Provider value={FileSelectionContextValue}>
        <div className='data-room-navigator'>
            <div className='header'>
                <span className='header-title'>
                    Topics
                </span>
                {expandedDisplay && <div className='actions'>
                    <span className='dots'>
                        {dots}
                    </span>
                </div>}
            </div>
            <div className='topics-list'>
                {projects.map((project, idx) => {
                    return <Topic
                        project={project}
                        projectTemplate={projectTemplate.find((template) => template.id === project.templateId)}
                        setLocation={setLocation}
                        idx={idx} 
                        icons={icons}
                        basePath={basePath}
                        hover={hover}
                        />
                })}

            </div>
        </div>
        </FileSelectionContext.Provider>
    )
}

export default DataRoomNavigator