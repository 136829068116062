
import React, { useState } from "react";
import ClauseText from "./ClauseText";

interface ComparableClauseRenderProps {
    comparableClauses: any[];
    clauseName: string;
}
function ComparableClauseRender({
    comparableClauses,
    clauseName
}: ComparableClauseRenderProps) {
    const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
    const [selectedTemplateIndex, setSelectedTemplateIndex] = useState<number>(null);
    const onChange = (template, index) => {
        setSelectedTemplate(template);
        setSelectedTemplateIndex(index);
    }
    return (<>
        <div className="clause-comparison-select-container">
            {comparableClauses.length > 0 && comparableClauses.map((clauses, index) =>{
                const selectedClause = clauses.filter(clause => clause.name === clauseName)[0];
                return <div className={`clause-comparison-select-element ${index === selectedTemplateIndex && 'selected-comparison'}`} key={index} onClick={() => onChange(selectedClause, index)}>
                    <span className="clause-comparison-select-element-text">Version {index + 1}</span>
                </div>}
            )}
        </div>
        {selectedTemplateIndex === null && <div>
           Choisir une version
            </div>}
        {!selectedTemplate && selectedTemplateIndex !== null && <div>
            Aucune clause pour cette selection
            </div>}
        {selectedTemplate && <div className="clause-comparison-chosen">
            <div className="clause-comparison-header" style={{marginBottom: '20px'}}>
                <span className="clause-comparison-cummon-title">
                    Clause du clausier
                </span>
                <br />
                <span className="clause-comparison-actual-title half-border-bottom">
                    {selectedTemplate.name}
                </span>
            </div>
            <div className="clause-comparison-body">
                <ClauseText
                    segmentation={selectedTemplate.segmentation}
                    fileNames={[]}
                    beneficialsMap={[]}
                    inputValues={[]}
                    segmentsOverrides={{}}
                />
                {selectedTemplate.subClauses.map((subClause) => {
                    return (
                        <div
                            key={subClause.id}
                            className="my-2"
                            id={`${selectedTemplate.id}-${subClause.id}`}
                        >
                            <h5 className="fw-bold">{subClause.name}</h5>
                            <div>
                                {subClause.segmentation?.segmentedText?.length > 0 && (
                                    <ClauseText
                                        segmentation={subClause.segmentation}
                                        fileNames={[]}
                                        beneficialsMap={[]}
                                        inputValues={[]}
                                        segmentsOverrides={{}}
                                    />
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
        }
    </>)
}

export default ComparableClauseRender;
