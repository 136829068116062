import { Route, Routes } from "react-router-dom";
import Dashboard from './pages/Dashboard'
import Map from './pages/Map'
import DataRoom from './pages/DataRoom'
import Equity from './pages/Equity'
import HealthCheck from './pages/HealthCheck'

function MainContent() {
	return (
		<div className="main-content" >
			<Routes>
				<Route path="/" element={<Dashboard />} />
				<Route path="/map" element={<Map />} />
				<Route path="/data-room/*" element={<DataRoom />} />
				<Route path="/equity" element={<Equity />} />
				<Route path="/health-check" element={<HealthCheck/>} />
			</Routes>
		</div>
	);
}

export default MainContent;
