import { useContext } from "react";
import apiClientWithLoading from "../services/api/ApiClient";
import { toast } from "react-toastify";
import { isFileType } from "../helpers/helper";
import { uploadDocument } from "../services/api/DocumentApi";
import { LoadingContext } from "../contexts/LoadingContext";
export function useParamProcessing() {
  const { setLoading } = useContext(LoadingContext);

  const processParamValues = async (paramValues: Record<string, any>) => {
    const processedParamValues: Record<string, any> = {};
    let hasError = false;
    const apiClient = apiClientWithLoading(setLoading, "multipart/form-data");

    for (const key of Object.keys(paramValues)) {
      const value = paramValues[key];

      if (isFileType(value)) {
        const file = value;
        const name = file.name;
        try {
          const {
            row: { id },
          } = await uploadDocument(apiClient, file, name);
          processedParamValues[key] = id;
        } catch (error) {
          hasError = true;
          console.error(`Error uploading file for key ${key}:`, error);
          toast.error(`Error uploading file for ${key}`);
          break;
        }
      } else {
        if (
          value &&
          value.isinitial === true &&
          value.isinitial !== undefined
        ) {
          processedParamValues[key] = value?.id;
        } else {
          processedParamValues[key] = value;
        }
      }
    }

    return { processedParamValues, hasError };
  };

  return processParamValues;
}
