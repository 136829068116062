// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body.custom-fonts {
    font-family: "Open Sans", sans-serif;
}

body.custom-fonts h1,
body.custom-fonts h2,
body.custom-fonts h3,
body.custom-fonts h4,
body.custom-fonts h5,
body.custom-fonts h6 {
    font-family: "Hanken Grotesk", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/fonts.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;AAEA;;;;;;IAMI,yCAAyC;AAC7C","sourcesContent":["body.custom-fonts {\n    font-family: \"Open Sans\", sans-serif;\n}\n\nbody.custom-fonts h1,\nbody.custom-fonts h2,\nbody.custom-fonts h3,\nbody.custom-fonts h4,\nbody.custom-fonts h5,\nbody.custom-fonts h6 {\n    font-family: \"Hanken Grotesk\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
