import  { createContext, Dispatch, SetStateAction } from 'react';
import { IProject, IProjectTemplate } from '../types/models';

export interface ProjectContextType {
  projects: IProject[]; 
  projectTemplate: IProjectTemplate[]; 
  setProjects: Dispatch<SetStateAction<IProject[]>>; 
  setProjectTemplate: Dispatch<SetStateAction<IProjectTemplate[]>>; 
  triggerUpdate: boolean;
  setTriggerUpdate: Dispatch<SetStateAction<boolean>>;
}

const ProjectContext = createContext<ProjectContextType>({
  
}as any);

export default ProjectContext;