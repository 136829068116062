import React, { useState } from 'react';
import './steps.css';
import LineActive from "../../../assets/line-active.svg";
import LineInactive from "../../../assets/line-inactive.svg";

function Steps() {
    const [step, setStep] = useState(0);
    const titleSteps = ["Personal Information", "Startup Focus", "Your Donna Journey"];

    const handleNext = () => {
        setStep((prevStep) => Math.min(prevStep + 1, titleSteps.length - 1));
    };

    const handlePrevious = () => {
        setStep((prevStep) => Math.max(prevStep - 1, 0));
    };

    return (
        <div className='steps-container'>
            <div className='steps-header'>
                <h1 className='step-title'>{titleSteps[step]}</h1>
                <div>
                    {titleSteps.map((title, index) => (
                        <React.Fragment key={index}>
                            <div className={index === step ? 'active-step-donna' : (index < step ? 'completed-step' : '')}>
                                {index + 1}
                            </div>
                            {index < titleSteps.length - 1 && <img src={index < step ? LineActive : LineInactive} style={{marginLeft:"13px", marginRight:"15px"}} alt={`Step ${index + 1}`} /> }
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className='steps-content'>
                Steps content for Step {step + 1}
            </div>
            <div className='steps-footer'>
                {step > 0 && <button onClick={handlePrevious}>Previous</button>}
                {step < titleSteps.length - 1 ? <button onClick={handleNext}>Next</button> : <button>Finish</button>}
            </div>
        </div>
    );
}

export default Steps;
