// services/api/DocumentAPI.js

import { AxiosInstance } from "axios";
import { IDocument, IDocumentUseCaseResponse } from "../../types/models";

const END_POINT = "/Document";

export const getAllDocuments = async (apiClient: AxiosInstance, schema = 'nested') => {
  const response = await apiClient.get<{ count: number, rows: IDocument[] }>(`${END_POINT}/all?schema=${schema}`);
  return response.data;
};

export const getDocumentById = async (apiClient: AxiosInstance, id: IDocument["id"], schema = 'full') => {
  const response = await apiClient.get<{ row: IDocument }>(`${END_POINT}?schema=${schema}&id=${id}`);
  return response.data;
};

export const uploadDocument = async (apiClient: AxiosInstance, file: Blob, name: IDocument['name'], schema = 'pure') => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", name);

  const response = await apiClient.put<{ row: IDocument }>(`${END_POINT}?schema=${schema}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};
export const questionUseCase = async (apiClient: AxiosInstance, documentId: IDocument['id'], questions: string[]) => {
  const data = {
    documentId,
    questions
  }
  const response = await apiClient.post<{ row: IDocumentUseCaseResponse }>("/Document/questionUseCase", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data;
};
export const documentUseCaseResponse = async (
  apiClient: AxiosInstance,
  useCaseResponsesId: IDocument["useCaseResponsesId"],
  data: { responseValidation: IDocumentUseCaseResponse['responseValidation'][] }
) => {
  const response = await apiClient.patch<{ data: { responseValidation: IDocumentUseCaseResponse['responseValidation'][] } }>(
    `/DocumentUseCaseResponse?id=${useCaseResponsesId}&schema=nested`,
    { data }
  );
  return response.data;
};
export const deleteDocument = async (apiClient: AxiosInstance, id: IDocument['id']) => {
  const response = await apiClient.delete<{ deleted: boolean }>(
    `${END_POINT}?id=${id}`,
  );
  return response.data;
};
