import { AxiosInstance } from "axios";
import { API_BASE_URL } from "../../config";
import { IGroup, IGroup_User, IUser } from "../../types/models";

export async function getGroupUsers(apiClient: AxiosInstance, groupId: IGroup['id']) {
  const response = await apiClient.get<{ rows: IUser[] }>(`${API_BASE_URL}/Group/getUsers?id=${groupId}`);
  return response.data;
}

export async function getAllGroup_Users(apiClient: AxiosInstance, groupId: IGroup['id']) {
  const response = await apiClient.get<{ rows: IGroup_User[] }>(`${API_BASE_URL}/Group_User/all`);
  return response.data;
}

export async function getAllGroups(apiClient: AxiosInstance, schema = "pure") {
  const response = await apiClient.get<{ rows: IGroup[] }>(`${API_BASE_URL}/Group/all?schema=${schema}`);
  return response.data;
}
export async function upsertGroupUsers(apiClient: AxiosInstance, groupUsers: IGroup_User[]) {

  return await apiClient.put(`${API_BASE_URL}/Group/setGroupUsers`, { data: groupUsers });
}

