import "./FormParameterInput.css";
import FileInput from "../common/FileInput";
import { useContext, useState } from "react";
import AddBenificialModel from "../modals/AddBenificialModal";
import EditTableModal from "../modals/EditTableModal";
import { extractXLSXCells } from "../../utils/csv";
import { transpose } from "../../utils/array";
import { IFormTemplatePage } from "../../types/models";
import { ClauseParam } from "../../types/ClauseParams";
import { FormListInputItem } from "./FormListInputItem";
import { isFileType } from "../../helpers/helper";
import EditFormContext from "../../contexts/EditFormContext";
import FormDateInput from "./FormDateInput";

function FormParameterInput({
  page,
  param,
  currentValue,
  handleInputChange,
}: {
  page: IFormTemplatePage;
  param: ClauseParam;
  currentValue: any;
  handleInputChange(paramValue: any, paramName: string): void
}) {
  const currentTranspose = !!currentValue?.[0]
  let currentCells = param.type == "csv" ? currentValue?.[1] ?? [] : []

  currentCells = currentTranspose ? transpose(currentCells) : currentCells
  const [showBenificialModel, setShowBenificialModel] = useState(false);
  const [showTableModal, setShowTableModal] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [csvCells, setCsvCells] = useState(currentCells)
  const { beneficialsMap, setBeneficialsMap, fileNames } = useContext(EditFormContext);
  const fieldName = `${page.id}_${param.name}`;

  switch (param.type) {
    case 'beneficial':
      return (
        <>
          <button
            type="button"
            className="benificial-button"
            onClick={() => setShowBenificialModel(true)}
          >
            {param.label}
          </button>
          {showBenificialModel && (
            <AddBenificialModel
              onClose={() => setShowBenificialModel(false)}
              beneficialsMap={beneficialsMap}
              setBeneficialsMap={setBeneficialsMap}
              currentBenificialData={beneficialsMap[currentValue] || {}}
              onSave={(beneficialId) => {
                handleInputChange(beneficialId, param.name,);
                setShowBenificialModel(false);
              }}
              beneficialTypes={param.args?.beneficialTypes ?? ['Company', 'Person']}
            />
          )}
        </>
      )
    case 'table':
      const currentTableValues: (string | Date | number)[][] = currentValue ?? [];
      return (
        <>
          <button
            type="button"
            className="benificial-button"
            onClick={() => setShowTableModal(true)}
          >
            {param.label}
          </button>
          {showTableModal && (
            <EditTableModal
              param={param}
              onClose={() => setShowTableModal(false)}
              currentTableValues={currentTableValues}
              onSave={(tableData) => {
                handleInputChange(tableData, param.name,);
                setShowBenificialModel(false);
              }}
            />
          )}
        </>
      );

    case 'boolean':
      return (
        <div className="d-flex flex-column  gap-2  py-2 justify-content-between checkbox-input-container align-items-center form-group">
          <div className="d-flex flex-column ">
            <label htmlFor={param.name}>{param.label}</label>
          </div>
          {/* /* update boolean type into checkbox */}

          <div className="radio-group boolean-group">
            <label className="form-check-label">
              <input
                type="radio"
                value="true"
                checked={currentValue}
                className="form-check-input boolean-check"
                onChange={() => {
                  handleInputChange(
                    true,
                    param.name,
                  );
                }}
                name={param.name}
              />
              Oui
            </label>
            <label className="form-check-label">
              <input
                type="radio"
                value="false"
                checked={!currentValue}
                className="form-check-input boolean-check"
                onChange={(e) => {
                  handleInputChange(
                    false,
                    param.name,
                  );
                }}
                name={param.name}
              />
              Non
            </label>
          </div>
        </div>
      );
    case 'date':
      return (
        <div
          className={`d-flex flex-column ${currentValue ? "green-border" : "grey-border"
            }`}
        >
          <label htmlFor={param.name} className="ms-1">
            {param.label ?? "JJ/MM/AAAA"}
          </label>
          <div className="d-flex gap-2 align-items-center">
            {currentValue ? (
              <div className="input-progress-done ms-2">Complété</div>
            ) : (
              <div className="input-inprogres ms-2">En cours</div>
            )}
            <div className="w-100">
              <FormDateInput
                name={fieldName}
                defaultValue={currentValue}
                onChange={(date) => {
                  if (date && !isNaN(date.getTime())) {
                    handleInputChange(date.toISOString().substring(0, 10), param.name,);
                  }
                }}
              />
            </div>
          </div>
        </div>
      );
    case 'file':
      const displayFile = currentValue && (isFileType(currentValue) ? currentValue : { name: fileNames[currentValue] })
      return (
        <div className={`${currentValue ? "green-border" : "grey-border"}`}>
          <label htmlFor={param.name} className="ps-1">
            {param.label}
          </label>
          <div className="d-flex gap-2">
            {currentValue ? (
              <div className="input-done ms-2">Complété</div>
            ) : (
              <div className="input-inprogress ms-2">En cours</div>
            )}
            <div className="w-100">
              <FileInput
                onChange={(e) =>
                  handleInputChange(e.target.file, param.name)
                }
                value={displayFile}
                borderClassName={currentValue ? "green-border" : "grey-border"}
              />
            </div>
          </div>
        </div>
      );
    case 'csv':
      const onCSVInputChange = (e) => {
        const file = e.target.file;
        if (file) {
          extractXLSXCells(file, (cells) => {
            setCsvCells(cells)
            handleInputChange([false, cells, file.name], param.name,)
          })
        } else {
          setCsvCells([])
          handleInputChange(null, param.name,)
        }
      }
      const onTranspose = (value: boolean) => {
        handleInputChange([value, value ? transpose(csvCells) : csvCells, currentValue[2]], param.name,)
      }
      return (
        <div className={`${currentValue ? "green-border" : "grey-border"}`}>
          <label htmlFor={param.name} className="ps-1">
            {param.label}
          </label>

          <div className="d-flex gap-2">
            {currentValue ? (
              <div className="input-done ms-2">Complété</div>
            ) : (
              <div className="input-inprogress ms-2">En cours</div>
            )}
            {currentValue && (
              <div className="switch w-10">
                <input
                  type="checkbox"
                  id={`toggle_${page.id}`}
                  onChange={e => onTranspose(e.target.checked)}
                />
                <label htmlFor={`toggle_${page.id}`}></label>
              </div>
            )}
            <div className="w-100">
              <FileInput
                onChange={onCSVInputChange}
                value={currentValue ? { name: currentValue[2] } : null}
                borderClassName={currentValue ? "green-border" : "grey-border"}
              />
            </div>

          </div>
        </div>
      );

    case 'list':
      return (
        <div className="d-flex flex-column  gap-1  py-2 justify-content-between checkbox-input-container align-items-center form-group">

          <label htmlFor={param.name}>{param.label}</label>
          <div className="wrapper-item-list-form">
            {param.args.map((arg, index) => (
              <FormListInputItem
                currentValue={currentValue}
                key={index}
                index={index}
                handleInputChange={handleInputChange}
                param={param}
                colorLabel="#212529"
              />
            ))}
          </div>
        </div>
      )



    case 'enum':
      const enumOptions = param.args.map((arg) => arg.option);
      const hasValue = currentValue !== null && currentValue !== undefined && currentValue !== "";
      return (
        <>
          <div className="form-group w-100">
            <div
              className={`d-flex flex-column  ${!hasValue ? "grey-border" : "green-border"
                }`}
            >
              <div className="d-flex align-items-start justify-content-between py-2 px-2">

                <label className="ms-2">{param.label}</label>
                {hasValue ? (
                  <div className="input-done ms-2">Complété</div>
                ) : (
                  <div className="input-inprogress ms-2">En cours</div>
                )}
              </div>

              <div className="radio-group flex-column align-items-start px-3 pb-1" style={{ gap: "0rem" }}>

                {enumOptions.map((option, index) => (
                  <div key={`${fieldName}_${index}`} className="form-check">
                    <div>
                      <input
                        type="radio"
                        className="form-check-input"
                        value={index}
                        checked={currentValue == index}
                        onChange={(e) => {
                          console.log(index, e.target.value);
                          handleInputChange(index, param.name);
                        }}
                      />
                      <label className="form-check-label" htmlFor={String(index)}>
                        {option}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      );

    default:
      return (
        <div
          className={`d-flex gap-2 align-items-center w-100 ${currentValue ? "green-border" : "grey-border"}`}
          style={{
            boxShadow: isFocused ? "0 0 0 2px #893aee" : "none",
            padding: "5px",
          }}
        >
          {currentValue ? (
            <div className="input-done ms-2">Complété</div>
          ) : (
            <div className="input-inprogress ms-2">En cours</div>
          )}
          <textarea
            value={currentValue || ""}
            onChange={(e) =>
              handleInputChange(e.target.value, param.name)
            }
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            style={{
              border: "none",
              outline: "none",
              padding: "5px",
            }}
            placeholder={param.label}
            className="custom-form-textarea"

          />
        </div>
      );
  }
}
export default FormParameterInput;
