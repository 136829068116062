import { createContext } from "react";
import { IBeneficial, IForm, IFormTemplate, IFormTemplatePage, IGroup, IProject, IProjectTemplateRequirement } from "../types/models";
import { ClauseParam } from "../types/ClauseParams";


type SelectedFormTemplatePageType = {
  pageId: IFormTemplatePage['id'];
  paramName?: ClauseParam['name'];
}
export interface EditFormContextType {
  form: IForm;
  setForm: React.Dispatch<React.SetStateAction<IForm>>;
  formId: IForm['id'],
  setFormId: React.Dispatch<React.SetStateAction<IForm['id']>>;
  template: IFormTemplate;
  setTemplate: React.Dispatch<React.SetStateAction<IFormTemplate>>;
  templateId: IFormTemplate['id'],
  setTemplateId: React.Dispatch<React.SetStateAction<IFormTemplate['id']>>;
  name: IForm['name'];
  setName: React.Dispatch<React.SetStateAction<IForm['name']>>;
  paramValues: IForm['paramValues'];
  setParamValues: React.Dispatch<React.SetStateAction<IForm['paramValues']>>;
  fileNames: IForm['fileNames'];
  setFileNames: React.Dispatch<React.SetStateAction<IForm['fileNames']>>;
  beneficialsMap: Record<string, IBeneficial>;
  setBeneficialsMap: React.Dispatch<React.SetStateAction<IForm['beneficialsMap']>>;
  groups: IGroup[];
  setGroups: React.Dispatch<React.SetStateAction<IGroup[]>>;
  completionPercentage: number,
  setCompletionPercentage: React.Dispatch<React.SetStateAction<number>>  
  selected: SelectedFormTemplatePageType;
  setSelected: React.Dispatch<React.SetStateAction<SelectedFormTemplatePageType>>;
  project?: IProject
  setProject?:React.Dispatch<React.SetStateAction<IProject>>;
  requirement?: IProjectTemplateRequirement,
  setRequirement?:React.Dispatch<React.SetStateAction<IProjectTemplateRequirement>>
  requirementType?:number,
  setRequirementType?:React.Dispatch<React.SetStateAction<number>>
}

const EditFormContext = createContext<EditFormContextType>({
  
}as any);

export default EditFormContext;
