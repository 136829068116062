import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./EditContractPage.css";
import EditContract from "../../../components/Contract/EditContract";
import { useContractData } from "../../../hooks/useContractData";
import SaveDraftModel from "../../../components/modals/SaveDraftModal";
import MiddlewareManager from "../../../services/MiddlewareManager";
import { useEffect } from "react";
import { PromiseSub, createPromiseSub } from "../../../utils/promise";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

function EditContractPage() {
  const location = useLocation();
  const { contractId } = useParams<{ contractId?: string }>() || {};

  const [draft, setSaveDraft] = useState(false);
  const [sub, setSub] = useState<PromiseSub>(null);

  const onLocation = async (pathname: string) => {
    console.log(location.pathname)
    if (location.pathname === "/data-room/edition-contrat" && pathname != "/data-room/edition-contrat/:contractId") {
      const newSub = createPromiseSub()
      setSaveDraft(true);
      setSub(newSub);
      await newSub.promise
    }
  };

  useEffect(() => {
    MiddlewareManager.subscribe("location", onLocation);
    return () => {
      MiddlewareManager.unsubscribe("location", onLocation);
    };
  }, []);

  useEffect(() => {
    console.log("location", location.pathname)
    if (location.pathname !== "/data-room/edition-contrat") {
      setSaveDraft(false);
    }
    return () => { };
  }, [location]);
  const initialContractName =
    location.state?.contractDataFromModal?.contractName;
  const levelsFilter = location.state?.levelsFilter;

  const {
    templateData,
    isLoading,
    contractName,
    isEditing,
    paramValues,
    excludedClauses,
    excludedSubClauses,
  } = useContractData(
    parseInt(contractId),
    initialContractName,
    levelsFilter,
  );

  return (
    <div className="contract-container d-flex justify-content-between align-items-center">
      {isLoading ? (
        <div className="contract-loading-container">
          <span>Loading</span>
        </div>
      ) : (
        <EditContract
          templateData={templateData}
          contractName={contractName}
          isEditing={isEditing}
          paramValues={paramValues}
          initialExcludedClauses={excludedClauses}
          initialExcludedSubClauses={excludedSubClauses}
        />
      )}
      {draft && (
        <SaveDraftModel
          type="contract"
          onClose={() => { setSaveDraft(false); sub.reject() }}
          onSave={(saved: boolean) => { sub.resolve() }}
        />
      )}
    </div>
  );
}

export default EditContractPage;
