import "./commonmodal.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getAllType1 } from "../../services/api/TypesApi";
import apiClientWithLoading from "../../services/api/ApiClient";
import { AiFillDelete, AiOutlineUpload } from "react-icons/ai";
import { fetchContractAIByFilter } from "../../services/api/ContractAIApi";
import { createContract } from "../../services/api/ContractAIApi";
import { extractDataFromAiObject } from "../../helpers/ParseTemplateData";
import { getAllContractAIForms } from "../../services/api/AiFormApi";
import {
  fetchTextFileUrl,
} from "../../helpers/helper";
import ShowCase from "../common/ShowCase";
import CustomCombobox from "../common/CustomCombobox";
import { useTranslation } from '../../contexts/TranslationProvider';
import { IProject, IProjectTemplateRequirement, ITypeLevel1, ITypeLevel2, ITypeLevel3 } from "../../types/models";
import { updateProject } from "../../services/api/ProjectApi";
import { numberToEnumList } from "../../domain/Project";
interface CreateContractAIModalType {
  onClose: () => void;
  dataProject?: { requirement: IProjectTemplateRequirement, project: IProject };
  typeReq?: number,
  basePath?: string
}
function CreateContractAIModal({ onClose, dataProject, typeReq, basePath = '' }:CreateContractAIModalType) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [contractExistsError, setContractExistsError] = useState(false);
  const modalContentRef = useRef(null);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const { t } = useTranslation();
  const triggerFileInput = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };
  const deleteFile = (onChange) => {
    setFile(null);

    // Create a new Event
    const event = new Event("input", { bubbles: true });

    // Reset the value of the input field
    if (fileInputRef.current) {
      fileInputRef.current.value = null;

      // Dispatch the event
      fileInputRef.current.dispatchEvent(event);
    }

    // Call onChange with the event
    onChange(event);
  };
  const [levelsFilter, setLevelsFilter] = useState<{
    level1Id: ITypeLevel1['id'];
    level2Id: ITypeLevel2['id'];
    level3Id: ITypeLevel3['id'];
  }>({
    level1Id: null,
    level2Id: null,
    level3Id: null,
  });
  const [displayLevels, setDisplayLevels] = useState({
    level1: true,
    level2: true,
    level3: true,
  });
  const [options, setOptions] = useState({
    level1: [],
    level2: [],
    level3: [],
  });
  const apiClient = apiClientWithLoading();
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await getAllType1(apiClient);
      setOptions((prevOptions) => ({
        ...prevOptions,
        level1: data.rows,
      }));
    };
    setLoading(false);

    fetchData();
  }, []);
  useEffect(() => {
    if (dataProject) {
      setLevelsFilter({
        level1Id: dataProject?.requirement?.level1Id,
        level2Id: dataProject?.requirement?.level2Id,
        level3Id: dataProject?.requirement?.level3Id
      })
    }
  }, [dataProject]);
  const handleLevel1Change = (value) => {
    const selectedLevel1 = options.level1.find(
      (item) => item.display === value
    );
    if (selectedLevel1) {
      setLevelsFilter({
        level1Id: selectedLevel1.id,
        level2Id: null,
        level3Id: null
      })
      setOptions((prevOptions) => ({
        ...prevOptions,
        level2: selectedLevel1.levels2,
        level3: [],
      }));
      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level2: selectedLevel1.levels2.length > 0,
        level3: false,
      }));

      // Clear the values of Type2 and Type3 comboboxes
      setValue("Type2", "Type Niveau 2");
      setValue("Type3", "Type Niveau 3");
    }
  };

  const handleComboboxClick = () => {
    setTimeout(() => {
      modalContentRef.current.scrollTo({
        top: modalContentRef.current.scrollHeight,
        behavior: "smooth",
      });
    }, 10);
  };

  const handleLevel2Change = (value) => {
    const selectedLevel2 = options.level2.find(
      (item) => item.display === value
    );
    if (selectedLevel2) {
      setLevelsFilter({
        level1Id: levelsFilter.level1Id,
        level2Id: selectedLevel2.id,
        level3Id: null
      })
      setOptions((prevOptions) => ({
        ...prevOptions,
        level3: selectedLevel2.levels3 || [],
      }));

      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level3:
          Array.isArray(selectedLevel2.levels3) &&
          selectedLevel2.levels3.length > 0,
      }));
    } else {
      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level3: false,
      }));
    }
    setValue("Type3", "Type Niveau 3");
  };
  const handleLevel3Change = (value) => {
    const selectedLevel3 = options.level3.find((item) => item.name === value);
    if (selectedLevel3) {
      setLevelsFilter({
        ...levelsFilter,
        level3Id: selectedLevel3.id,
      })
    }
  };
  const fetchAiContractData = useCallback(
    async (file, name) => {
      setLoading(true);
      const form = await getAllContractAIForms(apiClient,levelsFilter);
      const multipartApiClient = apiClientWithLoading(
        setLoading,
        "multipart/form-data"
      );
      const formId = form?.rows[0]?.id;
      const data = await createContract(multipartApiClient, file, name, formId);
      if(dataProject?.project){
        const updatedProject = await updateProject(apiClient, dataProject?.project?.id, {
          values: {
            ...dataProject.project?.values,
            [dataProject?.requirement.id]: {
              type: numberToEnumList(typeReq)[0],
              id: data?.row?.id,
            },
          },
        });
      }

      const row = data.row;
      let parsedData = extractDataFromAiObject(row);

      parsedData.textContent = await fetchTextFileUrl(
        parsedData.textFileUrl
      );

      setLoading(false);
      return parsedData;
    },
    [apiClient, setLoading]
  );
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const onSubmit = async (data) => {
    const { count } = await fetchContractAIByFilter(
      apiClient,
      "name",
      data.contractName
    );
    let selectedLevel, selectedValue;

    if (count >= 1) {
      setLoading(false);
      setContractExistsError(true);
    } else {
      // Check which level the user has selected
      if (data.Type3 !== "Type Niveau 3" ||dataProject?.requirement?.level3?.name) {
        selectedLevel = "level3.name";
        selectedValue = dataProject ? dataProject?.requirement?.level3?.name : data.Type3;
        if(dataProject){
          data.Type3 = selectedValue
        }
      } else if (data.Type2 !== "Type Niveau 2"||dataProject?.requirement?.level2?.name) {
        selectedLevel = "level2.name";
        selectedValue = dataProject ? dataProject?.requirement?.level2?.name :  data.Type2;
        if(dataProject){
          data.Type2 = selectedValue
        }
      } else if (data.Type1 !== "Type Niveau 1" ||dataProject?.requirement?.level1?.name) {
        selectedLevel = "level1.name";
        selectedValue =  dataProject ? dataProject?.requirement?.level1?.name : data.Type1;
        if(dataProject){
          data.Type1 = selectedValue
        }
      }

      const aiContractData = await fetchAiContractData(
        file,
        data.contractName
      );
      setLoading(false);
      
      navigate(`${basePath}/ai-edition-contrat`, {
        state: {
          contractDataFromModal: data,
          aiContractData: aiContractData,
          project:dataProject&&dataProject?.project ,
          requirementType:typeReq&&typeReq,
          requirement:dataProject&&dataProject?.requirement
        },
      });
    }
  };

  const contractName = watch("contractName");
  useEffect(() => {
    if (contractExistsError) setContractExistsError(false);
  }, [contractName]);
  return (
    <>
      <div
        className="modal-backdrop fade show"
      ></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t("pages.listener.popups.contract.title")}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  disabled={loading}
                ></button>
              </div>
              <div className="modal-body modal-body-pilotage" ref={modalContentRef}>
                {!loading && (
                  <p>
                    {t("pages.listener.popups.contract.description")}
                  </p>
                )}
                {loading ? (
                  <ShowCase height="100%" />
                ) : (
                  <form
                    action=""
                    className="form-group"
                    style={{minHeight:"300px"}}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <p className="fw-bolder">
                      {t("pages.listener.popups.contract.required")}
                    </p>
                    <div className="form-input-content">
                      <div className="form-input-first-content">
                        <label htmlFor="contractName" className="case-form-labelName">
                          {t("pages.listener.popups.contract.inputs.contractName.name")} {" "}
                        </label>
                        <div className="form-floating mb-3 case-form-labelInput">
                          <input
                            type="text"
                            className="form-control custom-color-input"
                            id="contractName"
                            placeholder={t("pages.listener.popups.contract.inputs.contractName.placeholder")}
                            onChange={() => {
                              setContractExistsError(false);
                            }}
                            {...register("contractName", { required: true })}
                          />
                        </div>
                        {errors.contractName && (
                          <p className="text-danger py-0 my-0 py-0 my-0">
                            {t("pages.listener.popups.contract.inputs.contractName.error1")}
                          </p>
                        )}
                        {contractExistsError && (
                          <p className="text-danger py-0 my-0 py-0 my-0">
                            {t("pages.listener.popups.contract.inputs.contractName.error2")}
                          </p>
                        )}
                      </div>
                      {dataProject ? (
                       <div>
                       <p className="types-contractAI-title">{t("pages.pilot.popups.contract.inputs.types")}</p>
                       <h6>
                         {dataProject?.requirement?.level1?.name +
                           (dataProject?.requirement?.level2 ? "/" + dataProject?.requirement.level2.name : "") +
                           (dataProject?.requirement?.level3 ? "/" + dataProject?.requirement.level3.name : "")}
                       </h6>
                     </div>
                      ) : (
                      <div className="form-input-last-content" 
                       style={{ maxHeight: '550px', marginBottom:"20%" }}
                    >
                        <Controller
                          name="Type1"
                          control={control}
                          defaultValue={t("pages.listener.popups.contract.inputs.type1.value")}
                          rules={{
                            required: true,
                            validate: (value) => value !== t("pages.listener.popups.contract.inputs.type1.value"),
                          }}
                          render={({ field }) => (
                            <CustomCombobox
                              label={t("pages.listener.popups.contract.inputs.type1.name")}
                              options={options.level1.map(
                                (item) => item.display
                              )}
                              value={field.value}
                              onDropdownOpen={handleComboboxClick}
                              onChange={(value) => {
                                handleLevel1Change(value);
                                field.onChange(value);
                              }}
                            />
                          )}
                        />

                        {errors.Type1 && (
                          <p className="text-danger py-0 my-0">
                            {t("pages.listener.popups.contract.inputs.type1.error")}
                          </p>
                        )}
                        {displayLevels.level2 && (
                          <Controller
                            name="Type2"
                            control={control}
                            defaultValue={t("pages.listener.popups.contract.inputs.type2.value")}
                            rules={{
                              required: true,
                              validate: (value) => value !== t("pages.listener.popups.contract.inputs.type2.value"),
                            }}
                            render={({ field }) => (
                              <CustomCombobox
                                label={t("pages.listener.popups.contract.inputs.type2.name")}
                                onDropdownOpen={handleComboboxClick}
                                options={options.level2.map(
                                  (item) => item.display
                                )}
                                value={field.value}
                                onChange={(value) => {
                                  handleLevel2Change(value);
                                  field.onChange(value);
                                }}
                              />
                            )}
                          />
                        )}
                        {options.level2.length > 0 && errors.Type2 && (
                          <p className="text-danger py-0 my-0">
                            {t("pages.listener.popups.contract.inputs.type2.error")}
                          </p>
                        )}
                        {displayLevels.level3 && (
                          <Controller
                            name="Type3"
                            control={control}
                            defaultValue={t("pages.listener.popups.contract.inputs.type3.value")}
                            rules={{
                              required: true,
                              validate: (value) => value !== t("pages.listener.popups.contract.inputs.type3.value"),
                            }}
                            render={({ field }) => (
                              <CustomCombobox
                                label={t("pages.listener.popups.contract.inputs.type3.name")}
                                onDropdownOpen={handleComboboxClick}
                                options={options.level3.map(
                                  (item) => item.display
                                )}
                                value={field.value}
                                onChange={(value)=>{
                                  handleLevel3Change(value);
                                  field.onChange(value)
                                }
                              }
                              />
                            )}
                          />
                        )}

                        {options.level3.length > 0 && errors.Type3 && (
                          <p className="text-danger py-0 my-0">
                            {t("pages.listener.popups.contract.inputs.type3.error")}
                          </p>
                        )}
                      </div>)}
                    </div>
                    <Controller
                      name="file"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            border: "dashed 2px #ccc",
                            padding: "0.5rem",
                            cursor: "pointer",
                            flexDirection: "row-reverse",
                            justifyContent: "space-between",
                          }}
                          className="mt-3"
                        >
                          <div className="d-flex ms-1">
                            {file?.name && (
                              <AiFillDelete
                                style={{
                                  marginRight: "0.5rem",
                                  color: "#2f14e5",
                                  cursor: "pointer",
                                }}
                                onClick={() => deleteFile(field.onChange)}
                              />
                            )}
                            <AiOutlineUpload
                              style={{
                                marginRight: "0.5rem",
                                color: "#2f14e5",
                              }}
                              onClick={triggerFileInput}
                            />
                          </div>
                          <p style={{ margin: 0 }}>
                            {file?.name || t("pages.listener.popups.contract.inputs.file.placeholder")}
                          </p>

                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleFileChange(e);
                              field.onChange(e);
                            }}
                          />
                        </div>
                      )}
                    />
                    {errors.file && (
                      <p className="text-danger py-2 my-0 py-0 my-0">
                        {t("pages.listener.popups.contract.inputs.file.error")}
                      </p>
                    )}

                    <div className="modal-footer footer-form-pilotage">
                      <button type="submit" className="btn btn-primary">
                        {t("pages.listener.popups.contract.button")}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateContractAIModal;
