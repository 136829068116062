// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox-input-container {
    width: 100%;
    min-height: 50px;
    padding: 0 15px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.checkbox-label {
    font-size: 16px;
    color: var(--neutral-medium);
}

.checkbox-input-container input:checked ~ .checkmark {
    background-color: var(--primary-color-medium) !important;
    border: 1px solid var(--primary-color-medium) !important;
}
.custom-checkbox {
    width: 20px;
    height: 20px;
    appearance: auto;
    cursor: pointer;
}
.required-element{
    margin: 12px 0px;
    color: gray;
    font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/CheckBoxInput.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,4BAA4B;AAChC;;AAEA;IACI,wDAAwD;IACxD,wDAAwD;AAC5D;AACA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,WAAW;IACX,eAAe;AACnB","sourcesContent":[".checkbox-input-container {\n    width: 100%;\n    min-height: 50px;\n    padding: 0 15px;\n    box-sizing: border-box;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n}\n\n.checkbox-label {\n    font-size: 16px;\n    color: var(--neutral-medium);\n}\n\n.checkbox-input-container input:checked ~ .checkmark {\n    background-color: var(--primary-color-medium) !important;\n    border: 1px solid var(--primary-color-medium) !important;\n}\n.custom-checkbox {\n    width: 20px;\n    height: 20px;\n    appearance: auto;\n    cursor: pointer;\n}\n.required-element{\n    margin: 12px 0px;\n    color: gray;\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
