import { AxiosInstance } from "axios";
import { IProject } from "../../types/models";

const END_POINT = "/Project";
export const geProject = async (apiClient: AxiosInstance, schema: string = 'pure') => {
    const reponse = await apiClient.get<{ count: number, rows: IProject[] }>(`${END_POINT}/all?schema=${schema}`);
    return reponse.data;
  };
  export const createProject = async (apiClient: AxiosInstance, data: IProject) => {
    const response = await apiClient.post<{ row: IProject }>(`${END_POINT}?schema=nested`, { data });
    return response.data;
  };
  export const fetchProjectById = async (apiClient: AxiosInstance, id: IProject['id']) => {
    if (!id) {
      console.warn("fetchProjectById", id);
      console.warn(new Error().stack);
    }
    const response = await apiClient.get<{ row: IProject }>(`${END_POINT}?schema=full&id=${id}`);
    return response.data;
  };
  export const updateProject = async (apiClient: AxiosInstance, id: IProject['id'], data: IProject) => {
    const response = await apiClient.patch<{ row: IProject }>(
      `${END_POINT}?id=${id}&schema=nested`,
      { data }
    );
    return response.data;
  };