import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/common/Loading";
import apiClientWithLoading from "../../../services/api/ApiClient";
import { fetchTextFileUrl, formatDate } from "../../../helpers/helper";
import { LoadingContext } from "../../../contexts/LoadingContext";
import { IDocument } from "../../../types/models";
import { getDocumentById } from "../../../services/api/DocumentApi";
import "./DocumentPage.css"
function DocumentPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading, setLoading } = useContext(LoadingContext);
  const [document, setDocument] = useState<IDocument>(null);
  const [text, setText] = useState<string>(null);
  const {documentId} = useParams<{ documentId?: string }>() || {};

  useEffect(() => {
    (async () => {
      const apiClient = apiClientWithLoading(setLoading);
      const data = await getDocumentById(apiClient, parseInt(documentId));
      const text = await fetchTextFileUrl(data?.row?.textFile);
      setDocument(data.row);
      setText(text);
    })();
  }, []);

  const handleUseCaseClick = (item) => {
    const questions = item.response.map(([question, answer]) => question);
    const answers = item.response.map(([question, answer]) => answer);

    navigate(`/data-room/ai-document-response/${documentId}`, {
      state: {
        nameDocument: document?.name,
        questions: questions,
        answers: answers,
        responseValidation: item?.responseValidation,
        useCaseResponsesId: item?.id
      },
    });
  };

  return document ? (
    <div className="row w-100">
      <div className="col-12">
        <h1 className="contract-name">{document.name}</h1>
        <div className="clause-list-container selected">
          <pre>{text}</pre>
        </div>
      </div>
    </div>
  ) : (
    <Loading height="90vh" />
  );
}

export default DocumentPage;
