import { AxiosInstance } from "axios";
import { IClause, IContractTemplate, ITypeLevel1, ITypeLevel2, ITypeLevel3 } from "../../types/models";

const END_POINT = "/ContractTemplate";
export type ValidationWarning = {
  message: string;
  templateCode: string;
  clauseCode: string;
  subClauseCode: string;
  paramName: string;
}
export type GenerateTemplateFromDocumentRequest = {
  file: Blob & { name: string };
  name: string;
  level1Id?: ITypeLevel1['id'];
  level2Id?: ITypeLevel2['id'];
  level3Id?: ITypeLevel3['id'];
}

const ContractTemplateAPI = {
  importTemplate: async (apiClient: AxiosInstance, file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    const response = await apiClient.post<{ warnings: ValidationWarning[], rows: IContractTemplate[] }>(`${END_POINT}/importFromXlsx`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;

  },



  generateTemplateFromDocument: async (apiClient: AxiosInstance, req: GenerateTemplateFromDocumentRequest) => {
    const formData = new FormData();

    formData.append("file", req.file);
    req.name && formData.append("name", req.name);
    req.level1Id && formData.append("level1Id", String(req.level1Id));
    req.level2Id && formData.append("level2Id", String(req.level2Id));
    req.level3Id && formData.append("level3Id", String(req.level3Id));
    const response = await apiClient.post<{ warnings: ValidationWarning[], row: IContractTemplate }>(`${END_POINT}/generateFromDocument`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;

  },

  generateClauseParams: async (apiClient: AxiosInstance, clauseId: IClause['id']) => {

    const response = await apiClient.post<{ warnings: ValidationWarning[], row: IClause }>
      (`${END_POINT}/generateClauseParams?clauseId=${clauseId}`);
    return response.data;

  },


  getAllTemplates: async (apiClient: AxiosInstance, schema = "levels") => {
    try {
      const response = await apiClient.get<{ count: number, rows: IContractTemplate[] }>(`${END_POINT}/all`, {
        params: { schema },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching all templates:", error);
      return null;
    }
  },

  getTemplateById: async (apiClient: AxiosInstance, id: IContractTemplate['id'], schema = "full") => {
    try {
      const response = await apiClient.get<{ row: IContractTemplate }>(`${END_POINT}/`, {
        params: { schema, id },
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching template with ID: ${id}`, error);
      return null;
    }
  },

  updateTemplate: async (apiClient: AxiosInstance, id: IContractTemplate['id'], data: IContractTemplate, schema = "full") => {
    const response = await apiClient.patch<{ warnings: ValidationWarning[], row: IContractTemplate }>(
      `${END_POINT}?id=${id}&schema=${schema}`,
      { data }
    );
    return response.data;
  },

  getAllNestedTemplates: async (apiClient: AxiosInstance, id: IContractTemplate['id']) => {
    try {
      const response = await apiClient.get<{ count: number, rows: IContractTemplate[] }>(`${END_POINT}/all`, {
        params: { schema: "nested", id },
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching nested templates with ID: ${id}`, error);
      return null;
    }
  },

  getTemplatesByLevelName: async (
    apiClient: AxiosInstance,
    levelsFilter: {
      level1Id: ITypeLevel1['id'];
      level2Id: ITypeLevel2['id'];
      level3Id: ITypeLevel3['id'];
    },
    schema = "full"
  ) => {
    try {
      const response = await apiClient.post<{ count: number, rows: IContractTemplate[] }>(
        `${END_POINT}/all`,
        {
          where: {
            ...levelsFilter,
            activated: true,
          },
        },
        {
          params: {
            schema,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        `Error fetching templates with levels: ${levelsFilter}`,
        error
      );
      return null;
    }
  },
  getAllTemplatesByLevelName: async (
    apiClient: AxiosInstance,
    levelsFilter: {
      level1Id: ITypeLevel1['id'];
      level2Id: ITypeLevel2['id'];
      level3Id: ITypeLevel3['id'];
    },
    schema = "full"
  ) => {
    try {
      const response = await apiClient.post<{ count: number, rows: IContractTemplate[] }>(
        `${END_POINT}/all`,
        {
          where: {
            ...levelsFilter,
          },
        },
        {
          params: {
            schema,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        `Error fetching templates with levels: ${levelsFilter}`,
        error
      );
      return null;
    }
  }
};

export default ContractTemplateAPI;
