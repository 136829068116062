import { useEffect, useContext } from "react";

import apiClientWithLoading from "../services/api/ApiClient";
import { fetchFormById } from "../services/api/FormApi";
import FormTemplateAPI from "../services/api/FormTemplateApi";
import { EditFormContextType } from "../contexts/EditFormContext";
import { LoadingContext } from "../contexts/LoadingContext";
import { ITypeLevel1, ITypeLevel2, ITypeLevel3 } from "../types/models";

export function useFormData(
  context: EditFormContextType,
  levelsFilter: {
    level1Id: ITypeLevel1['id'];
    level2Id: ITypeLevel2['id'];
    level3Id: ITypeLevel3['id'];
  }
) {
  const { isLoading, setLoading } = useContext(LoadingContext);
  const {
    form, setForm,
    formId, setFormId,
    template, setTemplate,
    name, setName,
    setParamValues,
    setFileNames,
    setBeneficialsMap,
    setTemplateId,
    setGroups,
  } = context;

  useEffect(() => {
    const fetchData = async () => {
      const apiClient = apiClientWithLoading(setLoading);
      if (form && template)
        return
      if (formId) {
        const data = await fetchFormById(apiClient, formId);
        setForm(data.row);
        setFormId(formId);
        setTemplate(data.row.template)
        setTemplateId(data?.row?.templateId);
        setName(data.row?.name);
        setParamValues(data?.row?.paramValues);
        setFileNames(data?.row?.fileNames);
        setBeneficialsMap(data?.row?.beneficialsMap)
        setGroups(data?.row?.validationGroups);
      } else if (name) {
        setLoading(true);
        setForm(null);
        setFormId(null);
        const data = await FormTemplateAPI.getTemplatesByLevels(apiClient, levelsFilter);
        const row = data?.rows[0];
        setTemplate(row);
        setTemplateId(row?.id);
        setGroups(row?.groups!);
        setParamValues({});
        setLoading(false);
      }
    };
    fetchData();
  }, [formId, name]);
}
