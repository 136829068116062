import { ClauseParam, RenderSegments, SegmentedClauseParam, SegmentedText, SegmentedTextType } from "../types/ClauseParams";
import { IBeneficial, IContract } from "../types/models";
import { getDeepValue } from "../utils/object";
import { pathHead } from "../utils/string";
import { isValidInputValue } from "./CompletionPercentage";

type Scope = {
	beneficialParamName?: string;
	beneficial?: IBeneficial;
}
export function getValueFromInputs<T extends ClauseParam | SegmentedClauseParam>(inputPath: string, inputs: any,
	fileNames: IContract['fileNames'], beneficialsMap: IContract['beneficialsMap'],
	params: T[], scope?: Scope) {
	const [paramName, valuePath] = pathHead(inputPath)
	let parentParam = params.find((param) => param.name === paramName)
	let value = null
	if (parentParam) {
		switch (parentParam.type) {
			case 'boolean':
			case 'enum':
				const definitions = params.filter((param) => param.name === paramName && param.type == parentParam.type)
				parentParam = definitions.find((param) => ((param as any).definition || 0) === (Number(valuePath) || 0))
				value = inputs[paramName]
				break;
			case 'beneficial':
				const benecialId = inputs[paramName]
				value = getDeepValue(beneficialsMap[benecialId] || {}, valuePath)
				break;
			case 'beneficial[]':
				const benecialsId = inputs[paramName]
				value = Array.isArray(benecialsId) ? benecialsId.map(benecialId => beneficialsMap[benecialId])
					: undefined
				break;
			case 'file':
				if (inputs[paramName] instanceof File) {
					value = inputs[paramName].name
				} else {
					const fileId = inputs[paramName]
					value = fileNames[fileId]
				}
				break;
			default:
				value = inputs[paramName]
				break;
		}
	} else if (scope?.beneficial && Object.keys(scope.beneficial).includes(paramName)) {
		parentParam = params.find((param) => param.name === scope?.beneficialParamName)
		value = getDeepValue(scope.beneficial, inputPath)
		return { value, parentParam, scopeParam: true };

	}
	return { value, parentParam };
};
export function findParamBoundaries(renderSegments: RenderSegments, paramName: string): [number, number][] {
	const boundaries: [number, number][] = [];
	let paramStack: { index: number, paramName: string }[] = [];

	for (let i = 0; i < renderSegments.length; i++) {
		const segment = renderSegments[i];

		if (segment.type === SegmentedTextType.PARAM_START && segment.paramName === paramName) {
			paramStack.push({ index: i, paramName });
		} else if (segment.type === SegmentedTextType.PARAM_END && segment.paramName === paramName && paramStack.length > 0 && paramStack[paramStack.length - 1].paramName === paramName) {
			const start = paramStack.pop()!.index;
			boundaries.push([start, i]);
		}
	}

	return boundaries;
}


export function getRenderSegments(segmentedText: SegmentedText, inputs: any,
	fileNames: IContract['fileNames'], beneficialsMap: IContract['beneficialsMap'],
	params: SegmentedClauseParam[], scope?: Scope): RenderSegments {

	let outputSegmentedText = [] as RenderSegments
	segmentedText.map((segment) => {
		switch (segment[2]) {
			case SegmentedTextType.STATIC:
				outputSegmentedText.push({
					type: SegmentedTextType.STATIC,
					id: segment[0],
					value: segment[1],
				})
				break;
			case SegmentedTextType.PARAM:
				const inputPath = segment[1];
				const { value: input, parentParam, scopeParam } = getValueFromInputs(inputPath, inputs, fileNames, beneficialsMap, params, scope);
				if (!parentParam) {
					// console.warn("getRenderSegments: parentParam not found", inputPath, segment);
					return outputSegmentedText.push({
						type: SegmentedTextType.STATIC,
						id: segment[0],
						value: segment[1],
					})
				}
				if (scopeParam) {
					return outputSegmentedText.push(
						{
							type: SegmentedTextType.PARAM_VALUE,
							id: segment[0],
							value: input,
							paramName: parentParam.name
						}
					)
				}
				if (!isValidInputValue(input)) {
					return outputSegmentedText.push({
						type: SegmentedTextType.PARAM,
						id: segment[0],
						value: segment[1],
						paramName: parentParam.name
					})
				}
				const defaultSegment = {
					type: SegmentedTextType.PARAM,
					id: segment[0],
					value: segment[1],
					paramName: parentParam.name
				}
				const startSegment = {
					type: SegmentedTextType.PARAM_START,
					id: `${defaultSegment}-START`,
					paramName: defaultSegment.paramName,
					value: "",
				}
				const endSegment = {
					type: SegmentedTextType.PARAM_END,
					id: `${defaultSegment}-END`,
					paramName: defaultSegment.paramName,
					value: "",
				}
				switch (parentParam.type) {
					case 'beneficial':
						const fieldName = segment[1].split(".")[1] as keyof IBeneficial
						if (fieldName == 'address') {
							const address = input as IBeneficial['address']
							const values = [
								address.addressLine,
								address.postalCode,
								address.city,
								address.country,
							].filter((field) => field);
							return outputSegmentedText.push(
								{
									type: SegmentedTextType.PARAM_VALUE,
									id: segment[0],
									value: values.join(", "),
									paramName: parentParam.name
								}
							)
						}
						return outputSegmentedText.push({
							type: SegmentedTextType.PARAM_VALUE,
							id: segment[0],
							value: String(input),
							paramName: parentParam.name
						})
					case 'beneficial[]':
						if (Array.isArray(input)) {
							input.forEach((beneficial: IBeneficial) =>
								outputSegmentedText.push(...getRenderSegments(parentParam.args.textPerBeneficial, inputs, fileNames, beneficialsMap, params,
									{ beneficial, beneficialParamName: parentParam.name }))
							)
						}
						return
					case 'boolean':
						return outputSegmentedText.push(
							startSegment,
							...getRenderSegments(input ? parentParam.args.textIfTrue : parentParam.args.textIfFalse,
								inputs, fileNames, beneficialsMap, params, scope),
							endSegment
						)

					case 'date':
						const date = new Date(input);
						const day = String(date.getDate()).padStart(2, "0");
						const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based in JavaScript
						const year = date.getFullYear();
						const reformattedDates = `${day}/${month}/${year}`;
						return outputSegmentedText.push(
							{
								type: SegmentedTextType.PARAM_VALUE,
								id: segment[0],
								value: reformattedDates,
								paramName: parentParam.name
							}
						)
					case 'file':
						return outputSegmentedText.push({
							type: SegmentedTextType.PARAM_VALUE,
							id: segment[0],
							value: input,
							paramName: parentParam.name
						})
					case 'comment':
						return outputSegmentedText.push({
							type: SegmentedTextType.PARAM_COMMENT_VALUE,
							id: segment[0],
							value: input,
							paramName: parentParam.name
						})
					case 'enum':
						const arg = parentParam.args[input]
						if (!arg)
							return outputSegmentedText.push(defaultSegment)
						const segmentedText = arg.text;
						try {
							return outputSegmentedText.push(
								startSegment,
								...getRenderSegments(segmentedText, inputs, fileNames, beneficialsMap, params, scope),
								endSegment,
							)
						} catch (error) {
							console.error({ input, segmentedText });
							throw error;
						}
					case 'list':
						return outputSegmentedText.push((Array.isArray(input)) ?
							{
								type: SegmentedTextType.PARAM_VALUE,
								id: segment[0],
								value: input.map(idx => parentParam.args[idx]?.option).join("\n"),
								paramName: parentParam.name
							}
							: defaultSegment)
					case 'table':
						const headers = parentParam.args.map(arg => arg.header)
						return outputSegmentedText.push((Array.isArray(input)) ?
							{
								type: SegmentedTextType.PARAM_TABLE_VALUE,
								id: segment[0],
								value: JSON.stringify([!!parentParam.transposed, [headers, ...input]]),
								paramName: parentParam.name
							}
							: defaultSegment)
					case 'csv':
						return outputSegmentedText.push((Array.isArray(input)) ?
							{
								type: SegmentedTextType.PARAM_TABLE_VALUE,
								id: segment[0],
								value: JSON.stringify([input[0], input[1]]),
								paramName: parentParam.name
							}
							: defaultSegment)
					case 'string':
					case 'number':
						return outputSegmentedText.push(
							{
								type: SegmentedTextType.PARAM_VALUE,
								id: segment[0],
								value: String(input),
								paramName: parentParam.name
							})
					default:
						console.warn(parentParam);
						console.warn(input);
						console.warn(segment);
						return
				}
			case SegmentedTextType.COMMENT:
				return outputSegmentedText.push({
					type: SegmentedTextType.COMMENT,
					id: segment[0],
					value: segment[1],
				})
			default:
				break;
		}

	})

	return outputSegmentedText
}