import React, { useContext, useEffect, useRef, useState } from "react";
import "./commonmodal.css";
import apiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../common/Loading";
import { toast } from "react-toastify";
import { IContract, IContractParamValue } from "../../types/models";
import { getContractParamValues } from "../../services/api/ContractApi";
import InputValuesContext from "../../contexts/InputValuesContext";
import { mergeParams } from "../../helpers/ParseTemplateData";
import { ClauseParam } from "../../types/ClauseParams";
import { useTranslation } from '../../contexts/TranslationProvider';
import { ContractCommit } from "../../types/ContractCommit";
import { findLastIndex } from 'lodash';
const { default: EditSteoOnIcon } = require('../../assets/edit_form_page_on.svg');
const { default: StepLine } = require('../../assets/stepLine.svg');
const { default: Step } = require('../../assets/active_assigned_G.svg');


function ContractParamValueRow({ contractParamValue, fileNames, param, beneficialsMap, insertedBy, insertedAt }) {
  let value = contractParamValue.value;
  let prevValue = contractParamValue.previousValue;
  if (!param) {
    console.error("Param is undefined for ContractParamValueRow");
    return null;
  }
  switch (param.type!) {
    case 'beneficial':
      value = beneficialsMap[value]?.name;
      prevValue = beneficialsMap[prevValue]?.name;
      break;
    case 'beneficial[]':
      value = Array.isArray(value) && value.map(bId => beneficialsMap[bId]?.name ?? "").join(", ");
      prevValue = Array.isArray(prevValue) && prevValue.map(bId => beneficialsMap[bId]?.name ?? "").join(", ");
      break;
    case 'table':
      value = "";
      prevValue = "";
      break;
    case 'csv':
      const [csvTransposed, csvRows, csvName] = value ?? [false, [], ""];
      value = csvName as string ?? "";
      const [csvTransposedPrev, csvRowsPrev, csvNamePrev] = prevValue ?? [false, [], ""];
      prevValue = csvNamePrev as string ?? "";
      break;
    case 'file':
      value = fileNames[value];
      prevValue = fileNames[prevValue];
      break;
    case 'list':
      value = (value as any as number[])?.map(idx => idx + 1)?.join(",");
      prevValue = (prevValue as any as number[])?.map(idx => idx + 1)?.join(",");
      break;
    case 'enum':
      const arg = (param as any).args[value];
      value = arg?.option ?? "";
      const argPrev = (param as any).args[prevValue];
      prevValue = argPrev?.option ?? "";
      break;
    case 'boolean':
      value = ((value as any) == true && "Y") || ((value as any) == false && "N") || "";
      prevValue = ((prevValue as any) == true && "Y") || ((prevValue as any) == false && "N") || "";
      break;
    case 'comment':
    case 'date':
    case 'number':
    case 'string':
      break;
    default:
      break;
  }

  const formattedDate = new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  return (
    <tr>
      <td style={{ border: "1px solid black", padding: "2px", textAlign: "left" }}>
        {contractParamValue.name}
      </td>
      <td style={{ border: "1px solid black", padding: "2px", textAlign: "left" }}>
        {prevValue}
      </td>
      <td style={{ border: "1px solid black", padding: "2px", textAlign: "left" }}>
        {value}
      </td>
      <td style={{ border: "1px solid black", padding: "2px", textAlign: "left" }}>
        {formattedDate.format(new Date(insertedAt))}
      </td>
      <td style={{ border: "1px solid black", padding: "2px", textAlign: "left" }}>
        {insertedBy}
      </td>
    </tr>
  );
}

function ContractAuditModal({ onClose }) {
  const [loading, setLoading] = useState(false);
  const {
    templateData,
    contractId,
    contract,
    groupContract,
  } = useContext(InputValuesContext);
  const params = mergeParams(templateData)
  const { t } = useTranslation();
  let [auditData, setAuditData] = useState({
    contractParamValues: [],
    commits: [],
    fileNames: {},
    beneficialsMap: {},
  });
  useEffect(() => {
    const apiClient = apiClientWithLoading(setLoading);
    getContractParamValues(apiClient, contractId).then(({ beneficialsMap, contractParamValues, commits, fileNames }) => {
      setAuditData({ beneficialsMap, contractParamValues,commits,fileNames });
    });
  }, [contractId, contract?.assignedToGroupId]);
  function groupCommits(commits) {
    const sortedCommits = commits?.sort((a, b) =>
      new Date(a?.commitedAt as string).getTime() - new Date(b?.commitedAt as string).getTime()
    );
    const finalGroupedCommits = [];
    let currentGroup = null;
    sortedCommits.forEach(commit => {
      const groupName = commit?.group?.name || 'Commit';
      if (!currentGroup || currentGroup.name !== groupName) {
        if (currentGroup) {
          finalGroupedCommits.push(currentGroup);
        }
        currentGroup = { name: groupName, commits: [commit] };
      } else {
        currentGroup.commits.push(commit);
      }
    });

    if (currentGroup) {
      finalGroupedCommits.push(currentGroup);
    }

    return finalGroupedCommits;
  }

  const groupedCommits = groupCommits(auditData?.commits);
  let lastIndex = findLastIndex(groupedCommits, { name: groupContract });
  
  let noSingleGroupVariable;
  if (groupedCommits[0]?.name === "Commit" && groupedCommits?.length === 1) {
    noSingleGroupVariable = true;
  }
   if (!(lastIndex == groupedCommits.length - 1)) {
    let newGroup = { name: groupContract, commits: [] };
    groupedCommits.push(newGroup)
  }
  const [currentGroup, setCurrentGroup] = useState(0);
  const commitGroupIndex = groupedCommits?.findIndex(group => group.name === 'Commit');

  if (commitGroupIndex !== -1&&groupedCommits.length>1) {
    const lastGroup = groupedCommits?.splice(commitGroupIndex, 1)[0];
    const nextGroup = groupedCommits[0];
    nextGroup.commits = nextGroup.commits?.concat(lastGroup?.commits);
  }
  let groupedCommitsLength=groupedCommits?.length-1
  useEffect(() => {
    groupedCommitsLength<=5 ? setCurrentGroup(groupedCommitsLength) :setCurrentGroup(0)
  }, [groupedCommitsLength]);
  
  if (groupedCommits.length > 6) {
    const lastElement = groupedCommits.pop();
    groupedCommits.unshift(lastElement)
  } 
  
  return (
    <>
      <div className="modal-backdrop fade show"></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t("pages.editionContract.popups.audit.title")}</h5>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close" disabled={loading}></button>
              </div>
              {loading ? (
                <Loading />
              ) : (
                <div className="">
                  {!noSingleGroupVariable && groupedCommits.length > 0 &&
                    <div className="audit-workflow-steps">
                      <div className="content-steps">
                        {groupedCommits.map((item, index) => (
                          <div key={index} className="edit-audit-step">
                            <div className="audit-step">
                              <img
                                src={
                                  currentGroup === index
                                    ? EditSteoOnIcon
                                    : Step
                                }
                                height="24px"
                                width="auto"
                                alt=""
                                onClick={() => setCurrentGroup(index)}
                              />
                              <p className="step-name">{item.name}</p>
                            </div>
                            {index < groupedCommits.length - 1 && (
                              <img
                                src={StepLine}
                                className="edit-audit-step-line"
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>}

                  {loading ? (
                    <Loading />
                  ) : (
                    <div className="modal-body">
                      <table className="my-0 mt-3" style={{ borderCollapse: "collapse", width: "100%" }}>
                        <thead>
                          <tr>
                            <th style={{ border: "1px solid black", paddingLeft: "4px", paddingRight: "32px", textAlign: "left" }} >
                              {t("pages.editionContract.popups.audit.name")}
                            </th>
                            <th style={{ border: "1px solid black", paddingLeft: "4px", paddingRight: "32px", textAlign: "left", width: "190px" }} >
                              {t("pages.editionContract.popups.audit.previousValue")}
                            </th>
                            <th style={{ border: "1px solid black", paddingLeft: "4px", paddingRight: "32px", textAlign: "left" }} >
                              {t("pages.editionContract.popups.audit.value")}
                            </th>
                            <th style={{ border: "1px solid black", paddingLeft: "4px", paddingRight: "32px", textAlign: "left", width: "125px" }} >
                              {t("pages.editionContract.popups.audit.insert_at")}
                            </th>
                            <th style={{ border: "1px solid black", paddingLeft: "4px", paddingRight: "32px", textAlign: "left" }} >
                              {t("pages.editionContract.popups.audit.insert_by")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {groupedCommits[currentGroup == null ? groupedCommits.length - 1 : currentGroup]?.commits?.map((commit, commitIndex) =>
                            commit?.changes?.map((change, changeIndex) => {
                              return (
                                <ContractParamValueRow
                                  key={`${commitIndex}-${changeIndex}`}
                                  contractParamValue={change}
                                  fileNames={auditData.fileNames}
                                  beneficialsMap={auditData.beneficialsMap}
                                  param={params.find((p) => p.name === change.name)}
                                  insertedBy={commit.commitedBy?.email}
                                  insertedAt={commit.commitedAt}
                                />
                              );
                            })
                          )}
                        </tbody>

                      </table>
                      <div className="modal-footer">
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContractAuditModal;
