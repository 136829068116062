import { AxiosInstance } from "axios";
import { IBeneficial } from "../../types/models";

const END_POINT = "/Beneficial";

export const addBenificial = async (apiClient: AxiosInstance, data: IBeneficial) => {
  const response = await apiClient.post(`${END_POINT}/?schema=nested`, data);
  return response.data;
};
export const updateBenificial = async (apiClient: AxiosInstance, contractId: IBeneficial['id'], data: IBeneficial) => {
  const response = await apiClient.patch(`${END_POINT}/?id=${contractId}&schema=nested`, data);
  return response.data;
};
export const getAllBenificials = async (apiClient: AxiosInstance) => {
  try {
    const response = await apiClient.get<{ count: number, rows: IBeneficial[] }>(`${END_POINT}/all`);
    return response.data;
  } catch (error) {
    console.error("Error fetching all templates:", error);
    return null;
  }
};
