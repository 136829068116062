import "./commonmodal.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import CustomCombobox from "../common/CustomCombobox";
import { useNavigate } from "react-router-dom";
import { getAllTypeLevel1WithFormTemplate } from "../../services/api/TypesApi";
import apiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../common/Loading";
import { fetchFormByFilter } from "../../services/api/FormApi";
import { IProject, IProjectTemplateRequirement, ITypeLevel1, ITypeLevel2, ITypeLevel3 } from "../../types/models";
import { useTranslation } from '../../contexts/TranslationProvider';
import EditFormContext, { EditFormContextType } from "../../contexts/EditFormContext";
import EventManager from "../../services/EventManager";

interface CreateFormModalType {
  onClose: () => void;
  dataProject?: { requirement: IProjectTemplateRequirement, project: IProject };
  typeReq?: number,
  basePath?: string
}
function CreateFormModal({ onClose, dataProject, typeReq, basePath = '' }: CreateFormModalType) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [editFormContext, setEditFormContext] = useState<EditFormContextType>(null)
  useEffect(() => {
    EventManager.subscribe('EditFormContext', setEditFormContext)
    return () => {
      EventManager.unsubscribe('EditFormContext', setEditFormContext)
    }
  }, [])
  const [loading, setLoading] = useState(false);
  const [formName, setFormName] = useState("");
  const [levelsFilter, setLevelsFilter] = useState<{
    level1Id: ITypeLevel1['id'];
    level2Id: ITypeLevel2['id'];
    level3Id: ITypeLevel3['id'];
  }>({
    level1Id: null,
    level2Id: null,
    level3Id: null,
  });
  const [levels1, setLevels1] = useState<ITypeLevel1[]>([]);
  const [contractExistsError, setFormExistsError] = useState(false);
  const modalContentRef = useRef(null);
  const apiClient = apiClientWithLoading();
  useEffect(() => {
    setLoading(true);
    (async () => {
      const { rows } = await getAllTypeLevel1WithFormTemplate(apiClient);
      setLevels1(rows)
      setLoading(false);
    })();
  }, []);
  const selectedLevel1 = levelsFilter.level1Id && levels1.find(l => l.id == levelsFilter.level1Id)
  const levels2 = selectedLevel1 && selectedLevel1.levels2
  const selectedLevel2 = levelsFilter.level2Id && levels2?.find(l => l.id == levelsFilter.level2Id)
  const levels3 = selectedLevel2 && selectedLevel2.levels3
  const selectedLevel3 = levelsFilter.level3Id && levels3?.find(l => l.id == levelsFilter.level3Id)
  useEffect(() => {
    if (dataProject) {
      setLevelsFilter({
        level1Id: dataProject?.requirement?.level1Id,
        level2Id: dataProject?.requirement?.level2Id,
        level3Id: dataProject?.requirement?.level3Id
      })
    }
  }, [dataProject]);
  const onSubmit = async (data) => {
    setLoading(true);
    const { count } = await fetchFormByFilter(apiClient, "name", formName);
    setLoading(false);
    if (count >= 1) {
      setFormExistsError(true);
    } else {
      navigate(`${basePath}/edit-form`, {
        state: {
          levelsFilter: levelsFilter,
          form: null,
          formId: null,
          name: formName,
          project:dataProject&&dataProject?.project ,
          requirementType:typeReq&&typeReq,
          requirement:dataProject&&dataProject?.requirement
        },
      });
    }
  };
  useEffect(() => {
    if (contractExistsError) setFormExistsError(false);
  }, [formName]);

  return (
    <>
      <div className="modal-backdrop fade show"></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t("pages.pilot.popups.form.title")}</h5>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
              </div>
              <div className="modal-body modal-body-pilotage" ref={modalContentRef}>
                <p>{t("pages.pilot.popups.form.description")}</p>
                {loading ? (
                  <Loading />
                ) : (
                  <form action="" className="form-group"  onSubmit={onSubmit}>
                    <p className="fw-bolder">{t("pages.pilot.popups.form.required")}</p>
                    <div className="form-input-content">
                      <div>
                        <label htmlFor="formName" className="case-form-labelName">{t("pages.pilot.popups.form.inputs.formName.name")}</label>
                        <div className="form-floating mb-3 case-form-labelInput">
                          <input
                            type="text"
                            className="form-control custom-color-input"
                            id="formName"
                            placeholder={t("pages.pilot.popups.form.inputs.formName.placeholder")}
                            onChange={(event) => {
                              const value = event.target.value;
                              setFormName(value);
                            }}
                          />
                        </div>
                        {contractExistsError && <p className="text-danger py-0 my-0 py-0 my-0">{t("pages.pilot.popups.form.inputs.formName.error2")}</p>}
                      </div>
                      {dataProject ? (
                        <div>
                          <p className="types-contract-title">{t("pages.pilot.popups.form.inputs.types")}</p>
                          <h6>
                            {dataProject?.requirement?.level1?.name +
                              (dataProject?.requirement?.level2 ? "/" + dataProject.requirement.level2.name : "") +
                              (dataProject?.requirement?.level3 ? "/" + dataProject.requirement.level3.name : "")}
                          </h6>
                        </div>

                      ) : (
                        <div style={{ height: '550px',marginBottom:"20%" }}>
                          <CustomCombobox
                            label={t("pages.pilot.popups.form.inputs.type1.name")}
                            options={levels1}
                            value={selectedLevel1}
                            optionDisplay={(option: ITypeLevel1) => option?.name}
                            optionValue={(option: ITypeLevel1) => option?.id}
                            onChange={(level1Id: ITypeLevel1['id']) => {
                              setLevelsFilter({
                                level1Id,
                                level2Id: null,
                                level3Id: null
                              })
                            }}
                          />
                          {levels2?.length > 0 && (
                            <CustomCombobox
                              label={t("pages.pilot.popups.form.inputs.type2.name")}
                              options={levels2}
                              value={selectedLevel2}
                              optionDisplay={(option: ITypeLevel2) => option?.name}
                              optionValue={(option: ITypeLevel2) => option?.id}
                              onChange={(level2Id: ITypeLevel2['id']) => {
                                setLevelsFilter({
                                  ...levelsFilter,
                                  level2Id,
                                  level3Id: null
                                })
                              }}
                            />
                          )}
                          {levels3?.length > 0 && (
                            <CustomCombobox
                              label={t("pages.pilot.popups.form.inputs.type3.name")}
                              options={levels3}
                              value={selectedLevel3}
                              optionDisplay={(option: ITypeLevel3) => option?.name}
                              optionValue={(option: ITypeLevel3) => option?.id}
                              onChange={(level3Id: ITypeLevel3['id']) => setLevelsFilter({
                                ...levelsFilter,
                                level3Id,
                              })}
                            />
                          )}
                        </div>)}
                    </div>
                    <div className="modal-footer footer-form-pilotage">
                      <button type="submit" className="btn btn-primary">
                        {t("pages.pilot.popups.form.button")}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateFormModal;
