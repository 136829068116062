// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary-color: #2f14e5;
    --primary-color-darker: #130093;
    --primary-color-medium: #2f14e5;
    --primary-color-light: #452aff;
    --primary-color-lighter: #9585ff;
    --secondary-color: #300962;
    --secondary-color-darker: #420c88;
    --secondary-color-medium: #7014e5;
    --secondary-color-light: #893aee;
    --secondary-color-lighter: #eee3fd;
    --ai-informative-darkest: #1f7284;
    --ai-informative-medium: #30b1cd;
    --ai-informative-clear: #b5e3ee;
    --ai-error-darkest: #960d0d;
    --ai-error-medium: #e51414;
    --ai-error-clear: #f8abab;
    --ai-warning-darkest: #6c4205;
    --ai-warning-medium: #f98543;
    --ai-warning-clear: #fdebd0;
    --ai-success-darkest: #0e5823;
    --ai-success-medium: #45e271;
    --ai-success-clear: #d3f8dd;
    --neutral-black: #131316;
    --neutral-darker: #1e1e24;
    --neutral-dark: #363540;
    --neutral-medium: #5e5c70;
    --neutral-light: #d3d2da;
}
`, "",{"version":3,"sources":["webpack://./src/colors.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,+BAA+B;IAC/B,+BAA+B;IAC/B,8BAA8B;IAC9B,gCAAgC;IAChC,0BAA0B;IAC1B,iCAAiC;IACjC,iCAAiC;IACjC,gCAAgC;IAChC,kCAAkC;IAClC,iCAAiC;IACjC,gCAAgC;IAChC,+BAA+B;IAC/B,2BAA2B;IAC3B,0BAA0B;IAC1B,yBAAyB;IACzB,6BAA6B;IAC7B,4BAA4B;IAC5B,2BAA2B;IAC3B,6BAA6B;IAC7B,4BAA4B;IAC5B,2BAA2B;IAC3B,wBAAwB;IACxB,yBAAyB;IACzB,uBAAuB;IACvB,yBAAyB;IACzB,wBAAwB;AAC5B","sourcesContent":[":root {\n    --primary-color: #2f14e5;\n    --primary-color-darker: #130093;\n    --primary-color-medium: #2f14e5;\n    --primary-color-light: #452aff;\n    --primary-color-lighter: #9585ff;\n    --secondary-color: #300962;\n    --secondary-color-darker: #420c88;\n    --secondary-color-medium: #7014e5;\n    --secondary-color-light: #893aee;\n    --secondary-color-lighter: #eee3fd;\n    --ai-informative-darkest: #1f7284;\n    --ai-informative-medium: #30b1cd;\n    --ai-informative-clear: #b5e3ee;\n    --ai-error-darkest: #960d0d;\n    --ai-error-medium: #e51414;\n    --ai-error-clear: #f8abab;\n    --ai-warning-darkest: #6c4205;\n    --ai-warning-medium: #f98543;\n    --ai-warning-clear: #fdebd0;\n    --ai-success-darkest: #0e5823;\n    --ai-success-medium: #45e271;\n    --ai-success-clear: #d3f8dd;\n    --neutral-black: #131316;\n    --neutral-darker: #1e1e24;\n    --neutral-dark: #363540;\n    --neutral-medium: #5e5c70;\n    --neutral-light: #d3d2da;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
