// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-useCaseQA{
    font-weight: 900;
    font-size: 23px;
    color: var(--neutral-medium);
}`, "",{"version":3,"sources":["webpack://./src/pages/subPages/Document/DocumentPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,4BAA4B;AAChC","sourcesContent":[".header-useCaseQA{\n    font-weight: 900;\n    font-size: 23px;\n    color: var(--neutral-medium);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
