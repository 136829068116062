// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.param {
  color: var(--primary-color-medium);
  font-weight: 900;
}
.param-value {
  color: var(--primary-color-darker);
  font-weight: 900;
}
.comment {
  color: var(--neutral-medium);
  font-weight: 600;
}
.selected-text {
  font-size: 16px;
}
.highlighted-text {
  background-color: #fff4e7;
}
.selected-text:focus {
  background-color: white;
  border: none !important;
  outline: none;
  overflow-wrap: normal !important;
  cursor: text;
}
.comment-edit {
  cursor: text !important;
}
.comment-prefix {
  color: var(--primary-color-medium) !important;
  font-weight: 900 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Contract/renderSegmentedText.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,gBAAgB;AAClB;AACA;EACE,kCAAkC;EAClC,gBAAgB;AAClB;AACA;EACE,4BAA4B;EAC5B,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,uBAAuB;EACvB,uBAAuB;EACvB,aAAa;EACb,gCAAgC;EAChC,YAAY;AACd;AACA;EACE,uBAAuB;AACzB;AACA;EACE,6CAA6C;EAC7C,2BAA2B;AAC7B","sourcesContent":[".param {\n  color: var(--primary-color-medium);\n  font-weight: 900;\n}\n.param-value {\n  color: var(--primary-color-darker);\n  font-weight: 900;\n}\n.comment {\n  color: var(--neutral-medium);\n  font-weight: 600;\n}\n.selected-text {\n  font-size: 16px;\n}\n.highlighted-text {\n  background-color: #fff4e7;\n}\n.selected-text:focus {\n  background-color: white;\n  border: none !important;\n  outline: none;\n  overflow-wrap: normal !important;\n  cursor: text;\n}\n.comment-edit {\n  cursor: text !important;\n}\n.comment-prefix {\n  color: var(--primary-color-medium) !important;\n  font-weight: 900 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
