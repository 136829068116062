// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.showcase {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.slideshow-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 120px;
    height: 6px;
    margin-top: 20px;
}

.bar-item {
    width: 27.97px;
    height: 5.99px;
    background: rgba(47, 20, 229, 0.25);
    transition: background 0.5s ease-in-out;
    border-radius: 3px;
}

.bar-active {
    width: 47.94px;
    background: #2f14e5;
}

.fade-enter {
    opacity: 0;
    transform: scale(0.9);
}

.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.showcase img {
    height: 260px;
    margin-left: -50px;
}

.slideshow-text {
    font-family: "Hanken Grotesk";
    font-style: normal;
    font-weight: 400;

    line-height: 22px;
    /* or 150% */

    text-align: center;

    color: rgba(0, 0, 0, 0.85);
}
`, "",{"version":3,"sources":["webpack://./src/components/common/showcase.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;IACZ,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,cAAc;IACd,mCAAmC;IACnC,uCAAuC;IACvC,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,qBAAqB;AACzB;;AAEA;IACI,UAAU;IACV,wBAAwB;IACxB,0CAA0C;AAC9C;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,qBAAqB;IACrB,0CAA0C;AAC9C;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,gBAAgB;;IAEhB,iBAAiB;IACjB,YAAY;;IAEZ,kBAAkB;;IAElB,0BAA0B;AAC9B","sourcesContent":[".showcase {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.slideshow-bar {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 120px;\n    height: 6px;\n    margin-top: 20px;\n}\n\n.bar-item {\n    width: 27.97px;\n    height: 5.99px;\n    background: rgba(47, 20, 229, 0.25);\n    transition: background 0.5s ease-in-out;\n    border-radius: 3px;\n}\n\n.bar-active {\n    width: 47.94px;\n    background: #2f14e5;\n}\n\n.fade-enter {\n    opacity: 0;\n    transform: scale(0.9);\n}\n\n.fade-enter-active {\n    opacity: 1;\n    transform: translateX(0);\n    transition: opacity 300ms, transform 300ms;\n}\n\n.fade-exit {\n    opacity: 1;\n}\n\n.fade-exit-active {\n    opacity: 0;\n    transform: scale(0.9);\n    transition: opacity 300ms, transform 300ms;\n}\n\n.showcase img {\n    height: 260px;\n    margin-left: -50px;\n}\n\n.slideshow-text {\n    font-family: \"Hanken Grotesk\";\n    font-style: normal;\n    font-weight: 400;\n\n    line-height: 22px;\n    /* or 150% */\n\n    text-align: center;\n\n    color: rgba(0, 0, 0, 0.85);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
