import "./EditFormInputList.css";
import { useContext } from "react";
import React from "react";
import EditFormContext from "../../contexts/EditFormContext";
import FormPageInput from "./FormPageInput";


function EditFormInputList({currentPage,setIndexCurrentPage,currentIndexPage}) {
  const {
    template,
  } = useContext(EditFormContext);

  function onSubmit(e) {
    e.preventDefault();
  }
  let index = template?.pages?.length
  return (
    <>    <div className="variable-input">
      <form onSubmit={onSubmit}>
        
            <React.Fragment key={currentPage.id}>
              {" "}
              <FormPageInput
                page={currentPage}
              />
            </React.Fragment>
         
      </form>
    </div>
      <div className="form-page-footer">
      <button style={{height: "40px", width: "100px"}} className={currentIndexPage!==0?"previous-btn":"previous-btn-hidden"} onClick={() => currentIndexPage > 0 ? setIndexCurrentPage(currentIndexPage - 1) : ""}>Précédent</button>
      <button style={{height: "40px", width: "100px"}} className={currentIndexPage!==index-1?"next-btn":"next-btn-hidden"} onClick={() => currentIndexPage < index - 1 ? setIndexCurrentPage(currentIndexPage + 1) : ""}>
        Suivant
      </button>
    </div>
    </>

  );
}
export default EditFormInputList;
