import { useEffect, useState } from "react";
import { EditFormContextType } from "../../contexts/EditFormContext";
import EventManager from "../../services/EventManager";
import { useTranslation } from "../../contexts/TranslationProvider";

function EditFormSaveButton() {
  const [editFormContext, setEditFormContext] = useState<EditFormContextType>(null)
  const { t } = useTranslation();
  const handleFormSubmit = async () => {
   EventManager.invoke('SubmitForm', editFormContext)
  };
  useEffect(() => {
    EventManager.subscribe('EditFormContext', setEditFormContext)
    return () => {
      EventManager.unsubscribe('EditFormContext', setEditFormContext)
    }
  }, [])

  return (
    <button style={{marginBottom: "15px"}} className="save-draft-form-btn" onClick={handleFormSubmit}>
      {editFormContext?.completionPercentage !== 100
        ? t("navbar.saveForm1")
        : t("navbar.saveForm2")}
    </button>
  );
}

export default EditFormSaveButton;
