// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/steps-bg-page.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.steps-page {
    width: 100%;
    height: 100vh;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); 
    background-size:cover; 
    background-position: center;
    background-repeat: no-repeat; 
}
`, "",{"version":3,"sources":["webpack://./src/pages/StepsSignUp/stepsSignUp.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,yDAAuD;IACvD,qBAAqB;IACrB,2BAA2B;IAC3B,4BAA4B;AAChC","sourcesContent":[".steps-page {\n    width: 100%;\n    height: 100vh;\n    background-image: url('../../assets/steps-bg-page.svg'); \n    background-size:cover; \n    background-position: center;\n    background-repeat: no-repeat; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
