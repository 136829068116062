import React, { useContext } from "react";
import InputValuesContext from "../contexts/InputValuesContext";
import { useNavigate } from "react-router-dom";
import apiClientWithLoading from "../services/api/ApiClient";
import { createContract, updateContract } from "../services/api/ContractApi";
import { toast } from "react-toastify";
import { LoadingContext } from "../contexts/LoadingContext";
import { IContract } from "../types/models";
import { updateProject } from "../services/api/ProjectApi";
import { numberToEnumList } from "../domain/Project";
import ProjectContext from "../contexts/ProjectContext";

export function useSubmitContract(navigateTo = '/contrats') {
  const { setLoading } = useContext(LoadingContext);
  const Navigate = useNavigate();
  const {project, requirementType, requirement } =useContext(InputValuesContext);
  const { setTriggerUpdate } = React.useContext(ProjectContext);
  const submitContract = async (body: IContract, contractId: IContract['id']) => {
    try {
      const apiClient = apiClientWithLoading(setLoading, "application/json");

      let submitedContractId:IContract['id']
      if (contractId) {
        const response = await updateContract(apiClient, contractId, body);
        submitedContractId=response.row.id
        toast.success("Contract updated successfully!");
      }else{
        const response = await createContract(apiClient, body);
        submitedContractId=response.row.id
        if(project){
          const updatedProject = await updateProject(apiClient, project?.id, {
            values: {
              ...project?.values,
              [requirement.id]: {
                type: numberToEnumList(requirementType)[0],
                id: submitedContractId,
              },
            },
          });
          setTriggerUpdate(prev=>!prev)
        }
        Navigate(`/data-room/edition-contrat/${submitedContractId}`)
        toast.success("Contract created successfully!");
      }
      return submitedContractId
    } catch (error) {
      const action = contractId ? "updating" : "creating";
      toast.error(`Error ${action} contract!`);
    }
  };

  return submitContract;
}
