import { AxiosInstance } from "axios";
import { IContract, IContractParamValue, IContractUnion, IGroup, IUser } from "../../types/models";
import { ContractCommit } from "../../types/ContractCommit";

const END_POINT = "/Contract";

interface ContractValidateReq {
  validateGroupId?: IGroup["id"],
  assignToGroupId?: IGroup["id"],
  assignToUserId?: IUser["id"],
}

export const getContractParamValues = async (apiClient: AxiosInstance, id: IContract['id']) => {
  const response = await apiClient.get<{
    contractParamValues: IContractParamValue[],
    commits:ContractCommit [],
    fileNames: IContract['fileNames'],
    beneficialsMap: IContract['beneficialsMap'],
  }>(`${END_POINT}/${id}/get/contractParamValues`);
  return response.data;
};

export const validateContract = async (apiClient: AxiosInstance, id: IContract['id'], validationData: ContractValidateReq) => {
  const response = await apiClient.post<{ count: number, rows: IContract[] }>(`${END_POINT}/${id}/validate`, validationData);
  return response.data;
};

export const fetchContractById = async (apiClient: AxiosInstance, id: IContract['id']) => {
  if (!id) {
    console.warn("fetchContractById", id);
    console.warn(new Error().stack);
  }
  const response = await apiClient.get<{ row: IContract }>(`${END_POINT}?schema=full&id=${id}`);
  return response.data;
};

export const fetchContractByFilter = async <K extends keyof IContract>(
  apiClient: AxiosInstance,
  filterName: K,
  filterValue: IContract[K]
) => {
  const body = {
    where: {
      [filterName]: filterValue,
    },
  };

  const response = await apiClient.post<{ count: number, rows: IContract[] }>(`${END_POINT}/all`, body);
  return response.data;
};

export const createContract = async (apiClient: AxiosInstance, data: IContract) => {
  const response = await apiClient.post<{ row: IContract }>(`${END_POINT}?schema=nested`, { data });
  return response.data;
};

export const updateContract = async (apiClient: AxiosInstance, id: IContract['id'], data: IContract) => {
  const response = await apiClient.patch<{ row: IContract }>(
    `${END_POINT}?id=${id}&schema=nested`,
    { data }
  );
  return response.data;
};

export const deleteContract = async (apiClient: AxiosInstance, id: IContract['id']) => {
  const response = await apiClient.delete<{ deleted: boolean }>(
    `${END_POINT}?id=${id}`,
  );
  return response.data;
};

export const getAssignedGroupContracts = async (apiClient: AxiosInstance) => {
  const reponse = await apiClient.get<{ count: number, rows: IContract[], groups: IGroup[] }>(`${END_POINT}/assignedGroupContracts`);
  return reponse.data;
};

export const getUnionContracts = async (apiClient: AxiosInstance, schema: string = 'pure') => {
  const reponse = await apiClient.get<{ count: number, rows: IContractUnion[] }>(`${END_POINT}Union/all?schema=${schema}`);
  return reponse.data;
};

export const getAssignedGroupContractsNested = async (apiClient: AxiosInstance) => {
  const reponse = await apiClient.get<{ count: number, rows: IContract[], groups: IGroup[] }>(`${END_POINT}/assignedGroupContracts?schema=nested`);
  return reponse.data;
};
