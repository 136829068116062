import "./CheckBoxInput.css";
interface CustomComboboxProps {
  checked: any;
  onChange: any;
  name: any;
  disabled: any;
  label?: any;
  colorLabel?:string
  editable?: boolean;
}
function CheckBoxInput({ checked, onChange, label, name, disabled = false, colorLabel, editable = true }: CustomComboboxProps) {
  const handleInputChange = (e) => {
    e.stopPropagation();
    onChange(e);
  };

  return (
    <div className={`checkbox-input-container d-flex ${label ? "flex-column align-items-start" : "justify-content-between align-items-center"}`}>
      <label htmlFor={name} style={{color:colorLabel&&colorLabel}}>{label ?? name}</label>
      <div className={`d-flex justify-content-between ${label && "w-100"}`}>
        {(label !== undefined && label !== null) && <div className="checkbox-label">{label}</div>}
        {editable && <div className="switch">
        <input
            type="checkbox"
            checked={checked}
            id={name}
            onChange={handleInputChange}
            name={name}
            disabled={disabled}
          />
          <label htmlFor={name}></label>
        </div>
          }
         {
            !editable && <div className="switch">
            {
              disabled && <span className="required-element">required</span>
            }
            </div>
         }
      </div>
    </div>
  );
}

export default CheckBoxInput;
