// src/utils/parseContractData.js

import { ClauseParam } from "../types/ClauseParams";
import { IClause, IContract, IContractAI, IContractTemplate, ISubClause } from "../types/models";
import {
  isClauseExcluded,
  isSubClauseExcluded,
} from "./helper";

export const getTemplateClause = (templateData, clauseId) => {
  return templateData
    .find(clause => clause.id == clauseId)
}
export function getClauseName(name: string, clauseIndex: number) {
  return name?.replace("$clauseIndex", String(clauseIndex + 1))
}
export function getSubClauseName(name: string, clauseIndex: number, subClauseIndex: number) {
  return name?.replace("$clauseIndex", String(clauseIndex + 1))
    ?.replace("$subClauseIndex", String(subClauseIndex + 1))
}
export const getTemplateClausesSummaryData = (
  templateData: ContractTemplateParsedData,
  excludedClauses: IContract['excludedClauses'],
  excludedSubClauses: IContract['excludedSubClauses'],
) => {
  const summary = templateData
    .filter((clause) => !isClauseExcluded(clause.id, excludedClauses))
    .map((clause, idx) => {
      return {
        name: clause.name,
        subClauses: clause.subClauses
          .filter(
            (subClause) =>
              !isSubClauseExcluded(subClause.id, excludedSubClauses)
          )
          .map((subClause, idx) => subClause.name),
      };
    });
  return summary;
};
const parseTemplateData = (rawData: { row: IContractTemplate }) => {
  const clauses = rawData.row.clauses.map((clause) => {
    const subClauses = clause.subClauses.map((subClause) => {
      return {
        id: subClause.id,
        name: subClause.name,
        code: subClause.code,
        index: subClause.index,
        isOptional: subClause.isOptional,
        params: subClause.params,
        rawText: subClause.rawText,
        segmentation: subClause.segmentation,
        comment: subClause.comment,
      };
    });

    return {
      id: clause.id,
      name: clause.name,
      code: clause.code,
      subClauses: subClauses,
      rawText: clause.rawText,
      segmentation: clause.segmentation,
      comment: clause.comment,
      params: clause.params,
      isOptional: clause.isOptional,
    };
  });

  clauses.sort((a, b) => {
    const [aMajor, aMinor] = a.code.split("-").map(Number);
    const [bMajor, bMinor] = b.code.split("-").map(Number);

    if (aMajor !== bMajor) return aMajor - bMajor; // Compare major number (before the hyphen)
    return aMinor - bMinor; // Compare minor number (after the hyphen)
  });
  return clauses;
};
export type ContractTemplateParsedData = ReturnType<typeof parseTemplateData>
export const extractDataFromAiObject = (aiObject: IContractAI) => {
  const textFileUrl = aiObject.textFile.url;
  const summarySheet = aiObject.summarySheet;
  const contractId = aiObject.id;
  const formId = aiObject.formId;
  const aiResponsesStatus = aiObject?.aiResponsesStatus;

  return {
    textFileUrl,
    summarySheet,
    contractId,
    formId,
    aiResponsesStatus,
    textContent: undefined,
  };
};

export const extractDataFromSummarySheetGroupedByClauseName = (
  summarySheet: IContractAI['summarySheet']
) => {
  // Map over each entry in the summary sheet
  const data = summarySheet.map((item, index) => {
    // Return an object where each property is extracted from the item
    return {
      clauseName: item[0],
      subclauseName: item[1],
      question: item[2],
      response: item[3],
      index: index,
    };
  });

  const groupedData: Record<string, IContractAI['summarySheet']> = data.reduce((acc, item) => {
    const key = item.clauseName;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  return groupedData;
};

export function extractOptionalClauses(templateData: ContractTemplateParsedData) {
  const optionalClauses: {
    clauseId: IClause['id'],
    clauseName: IClause['name'],
  }[] = [];

  templateData.forEach((clause) => {
    if (clause.isOptional) {
      optionalClauses.push({
        clauseId: clause.id,
        clauseName: clause.name,
      });
    }
  });

  return optionalClauses;
}
export type OptionalClause = {
  clauseId: IClause['id'],
  clauseName: IClause['name'],
}

export function extractOptionalSubclauses(templateData: ContractTemplateParsedData) {
  const optionalSubclauses: {
    clauseId: IClause['id'],
    clauseName: IClause['name'],
    subClauseId: ISubClause['id'],
    subClauseName: ISubClause['name'],
  }[] = [];
  templateData.forEach((clause) => {
    clause.subClauses.forEach((subClause) => {
      if (subClause.isOptional) {
        optionalSubclauses.push({
          clauseId: clause.id,
          clauseName: clause.name,
          subClauseId: subClause.id,
          subClauseName: subClause.name,
        });
      }
    });
  });

  return optionalSubclauses;
}
export type OptionalSubClause = {
  clauseId: IClause['id'],
  clauseName: IClause['name'],
  subClauseId: ISubClause['id'],
  subClauseName: ISubClause['name'],
}


function generateParam({
  param,
  isEditing,
  paramValues,
  clauseId,
  clauseName,
  subClauseId = null,
  subClauseName = null,
}: {
  param: ContractTemplateParsedData[number]["params"][number],
  isEditing: boolean,
  paramValues: IContract['paramValues'],
  clauseId: IClause['id'],
  clauseName: IClause['name'],
  subClauseId?: ISubClause['id'],
  subClauseName?: ISubClause['name'],
}) {
  let value = isEditing ? paramValues[param.name] : undefined;

  return {
    ...param,
    rendered: true,
    value,
    subClauseId,
    clauseId,
    clauseName,
    subClauseName,
  };
}
export type ParsedClauseParam = ReturnType<typeof generateParam>

function generateClauseParams(
  clause: ContractTemplateParsedData[number],
  isEditing: boolean,
  paramValues: IContract['paramValues'],
) {
  return (clause.params || []).map((param) =>
    generateParam({
      param,
      isEditing,
      paramValues,
      clauseId: clause.id,
      clauseName: clause.name,
    })
  );
}

function generateSubClauseParams(
  clause: ContractTemplateParsedData[number],
  isEditing: boolean,
  paramValues: IContract['paramValues'],
) {
  return (clause.subClauses || []).flatMap((subClause) =>
    (subClause.params || []).map((param) =>
      generateParam({
        param,
        isEditing,
        paramValues,
        clauseId: clause.id,
        clauseName: clause.name,
        subClauseId: subClause.id,
        subClauseName: subClause.name,
      })
    )
  );
}

export function generateCombinedParams(
  templateData: ContractTemplateParsedData,
  isEditing: boolean,
  paramValues: IContract['paramValues']
) {
  if (templateData) {
    return templateData.flatMap((clause) => {
      const clauseParams = generateClauseParams(
        clause,
        isEditing,
        paramValues
      );
      const subClauseParams = generateSubClauseParams(
        clause,
        isEditing,
        paramValues
      );
      return [...clauseParams, ...subClauseParams];
    });
  }
  return [];
}

export type GroupedParsedClauseParam = {
  clauseId: ParsedClauseParam['clauseId'],
  subClauseId: ParsedClauseParam['subClauseId'],
  clauseName: ParsedClauseParam['clauseName'],
  subClauseName: ParsedClauseParam['subClauseName'],
  params: ParsedClauseParam[],
}
export type NestedGroupedParsedClauseParam = {
  clauseId: GroupedParsedClauseParam['clauseId'],
  clauseName: GroupedParsedClauseParam['clauseName'],
  params: GroupedParsedClauseParam['params']
  subclauses: GroupedParsedClauseParam[]
}
export function getClauseNestedGroupParams(clause: ContractTemplateParsedData[number], params: ParsedClauseParam[]) {
  const group: NestedGroupedParsedClauseParam = {
    clauseId: clause.id,
    clauseName: clause.name,
    params: params.filter(param => param.clauseId == clause.id && !param.subClauseId),
    subclauses: clause.subClauses.map(subClause => ({
      clauseId: clause.id,
      clauseName: clause.name,
      subClauseId: subClause.id,
      subClauseName: subClause.name,
      params: params.filter(param => param.subClauseId == subClause.id),
    }))
  }
  return group
}
export function groupClauseParams(params: ParsedClauseParam[]) {
  const groupedParams: Record<string, GroupedParsedClauseParam> = {};

  params.forEach((param) => {
    const key = `${param.clauseId}_${param.subClauseId}`;

    if (!groupedParams[key]) {
      groupedParams[key] = {
        clauseId: param.clauseId,
        subClauseId: param.subClauseId,
        subClauseName: param.subClauseName,
        clauseName: param.clauseName,
        params: [] as ParsedClauseParam[],
      };
    }

    groupedParams[key].params.push(param);
  });

  return groupedParams;
}


export function nestGroupedClauseParams(groupedParams: GroupedParsedClauseParam[]) {
  const nestedGroups: Record<IClause['id'], NestedGroupedParsedClauseParam> = {};

  groupedParams.forEach((group) => {
    if (!nestedGroups[group.clauseId]) {
      nestedGroups[group.clauseId] = {
        clauseId: group.clauseId,
        clauseName: group.clauseName,
        params: [],
        subclauses: [],
      };
    }
    if (group.subClauseId) {
      nestedGroups[group.clauseId].subclauses.push(group);
    } else {
      nestedGroups[group.clauseId].params.push(...group.params);
    }
  });
  return nestedGroups
}


export const mergeParams = (templateData: ContractTemplateParsedData) => {
  const params: (ClauseParam & {
    clauseCode: IClause['code'];
    clauseName: IClause['name'];
    subClauseCode?: ISubClause['code'];
    subClauseName?: ISubClause['name'];
  })[] = [];
  for (const clause of templateData) {
    for (const param of clause.params) {
      params.push({
        ...param,
        clauseCode: clause.code,
        clauseName: clause.name,
        subClauseCode: "",
        subClauseName: "",
      })
    }
    for (const subClause of clause.subClauses) {
      for (const param of subClause.params) {
        params.push({
          ...param,
          clauseCode: clause.code,
          clauseName: clause.name,
          subClauseCode: subClause.code,
          subClauseName: subClause.name,
        })
      }
    }
  }
  return params;
};

//Will be called to mergeParameter as desired in the api call
export const mergeParamValues = (inputValues: Record<string, any>) => {
  const paramValues: Record<string, any> = {};

  for (const clauseOrSubclause of Object.values(inputValues)) {
    if (
      typeof clauseOrSubclause === "object" &&
      !Array.isArray(clauseOrSubclause)
    ) {
      for (const params of Object.values(clauseOrSubclause)) {
        if (typeof params === "object" && !Array.isArray(params)) {
          Object.entries(params).forEach(([paramName, paramValue]) => {
            paramValues[paramName] = paramValue;
          });
        }
      }
    }
  }

  return paramValues;
};

export default parseTemplateData;
