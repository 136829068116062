// TranslationProvider.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface TranslationContextProps {
  language: string;
  setLanguage: (lang: string) => void;
  t: (key: any) => any;
}

const TranslationContext = createContext<TranslationContextProps | undefined>(undefined);

const TranslationProvider: React.FC<any> = ({ children }: { children: ReactNode }) => {
  const [language, setLanguage] = useState('fr');
  const translations = require(`../content/${language}.json`);

  const t = (key : any) => {
    const keys = key.split('.');
    let result = translations;
  
    for (const k of keys) {
      if (result && result[k] !== undefined) {
        result = result[k];
      } else {
        return key;
      }
    }
  
    return result;
  };

  return (
    <TranslationContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </TranslationContext.Provider>
  );
};

const useTranslation = (): TranslationContextProps => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error('useTranslation must be used within a TranslationProvider');
  }
  return context;
};

export { TranslationProvider, useTranslation };
