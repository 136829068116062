
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import "./renderSegmentedText.css";
import { IClause, IContract, ISubClause } from "../../types/models";
import { findParamBoundaries, getRenderSegments } from "../../helpers/segmentation";
import { RenderSegments, SegmentedText, SegmentedTextType } from "../../types/ClauseParams";
import { StateSync } from "../../utils/state";
import SelectionContext from "../../contexts/SelectionContext";


interface RenderSegmentedTextProps {
  inputValues: Record<string, any>;
  clauseId: IClause['id'];
  segmentation: IClause['segmentation'];
  subClauseId?: ISubClause['id'];
  fileNames: IContract['fileNames'];
  beneficialsMap: IContract['beneficialsMap'];
  segmentsOverrides: Record<string, string>;
  onSegmentChange: (id: string, text: string) => void
  isSelected: boolean;
  isEditing: boolean;
  formattedRawRef?: React.MutableRefObject<any>;
}
function RenderSegmentedText({
  segmentation,
  fileNames,
  beneficialsMap,
  segmentsOverrides,
  onSegmentChange,
  isSelected,
  isEditing,
  inputValues,
}: RenderSegmentedTextProps) {
  const {
    selected,
  } = useContext(SelectionContext);
  const [editingSegmentId, setEditingSegmentId] = useState(null)
  const [changedParamBoundaries, setChangedParamBoundaries] = useState<[number, number][]>([])

  const spanRef = useRef(null);
  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.contentEditable = true;
      spanRef.current.focus();
    }
  }, [editingSegmentId]);

  const stateSync = useMemo(() => new StateSync(setEditingSegmentId, (prev, newValue) => {
    return prev || newValue
  }, 100), [setEditingSegmentId])

  const renderSegments: RenderSegments = getRenderSegments(segmentation.segmentedText, inputValues,
    fileNames, beneficialsMap, segmentation.segmentedParams)
    .map(seg => {
      return {
        ...seg,
        value: seg.value?.replaceAll(/\r\n/g, "\n"),
      }
    })

  const handleStaticContentChange = (id: SegmentedText[number][0]) => (e) => {
    const newContent = e.target.innerText;
    console.log(JSON.stringify(newContent));

    const segment = renderSegments.find(seg => seg.id == id)
    if (segment) {
      let newValue
      if (newContent == segment.value) {
        newValue = segmentsOverrides
        delete newValue[id]
      } else {
        newValue = {
          ...segmentsOverrides,
          [id]: newContent
        }
      }
      stateSync.set(null)
      onSegmentChange(id, newContent)
    } else {
      console.warn(id);
    }
  };
  useEffect(() => {
    const paramNames = renderSegments.map(seg => (seg as any).paramName).filter(seg => seg)
    if (selected.eventType == 'ParamValueChange' && selected.paramName && paramNames.includes(selected.paramName)) {
      const boundaries = findParamBoundaries(renderSegments, selected.paramName)
      setChangedParamBoundaries(boundaries)

    } else {
      setChangedParamBoundaries([])
    }
  }, [selected]);

  return (
    <div style={{ marginTop: '30px' }}>
      {renderSegments.map((segment, idx) => {
        const isHighlighted = changedParamBoundaries.some((b) => b[0] < idx && idx < b[1])
        const { id, value, type } = segment
        const key = idx;
        const text = segmentsOverrides[id] ?? value

        const breakedHTMLText = text?.replaceAll(/\n/g, "<br/>")
        const editableProps: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> = isEditing && {
          contentEditable: true, suppressContentEditableWarning: true, onClick: (e) => stateSync.set(id)
        } || {}
        const editingProps: React.DetailedHTMLProps<React.HTMLAttributes<any>, any> = isEditing && {
          ref: spanRef,
          contentEditable: true, suppressContentEditableWarning: true, onBlur: handleStaticContentChange(id)
        } || {}
        const isSegmentEditing = isEditing && editingSegmentId == id
        switch (type) {
          case SegmentedTextType.PARAM_START:
          case SegmentedTextType.PARAM_END:
            return <React.Fragment key={key}>
            </React.Fragment>
          case SegmentedTextType.STATIC:
            return <React.Fragment key={key}>
              {isSegmentEditing ?
                <span {...editingProps} className={`${isSelected ? "selected-text" : ""} ${isHighlighted ? "highlighted-text" : ""}`
                } dangerouslySetInnerHTML={{ __html: breakedHTMLText }}></span>
                // <span {...editingProps} className={isSelected ? "selected-text" : ""} >{text}</span>
                // <span {...editingProps} className={isSelected ? "selected-text" : ""} >{breakedText}</span>
                // <div {...editingProps} className={isSelected ? "selected-text" : ""} dangerouslySetInnerHTML={{ __html: breakedHTMLText }}></div>
                // <div {...editingProps} className={isSelected ? "selected-text" : ""} >{text}</div>
                // <div {...editingProps} className={isSelected ? "selected-text" : ""} >{breakedText}</div>
                :
                <span {...editableProps} className={`${isSelected ? "selected-text" : ""} ${isHighlighted ? "highlighted-text" : ""}`} dangerouslySetInnerHTML={{ __html: breakedHTMLText }}></span>
              }
            </React.Fragment>
          case SegmentedTextType.COMMENT:
            return <React.Fragment key={key}>
              {isSegmentEditing ?
                <span {...editingProps} className={`comment comment-edit ${isHighlighted ? "highlighted-text" : ""}`} dangerouslySetInnerHTML={{ __html: breakedHTMLText }}></span>
                :
                <>
                  <span className={`comment-prefix ${isHighlighted ? "highlighted-text" : ""}`}>☞ &nbsp;</span>
                  <span {...editableProps} className={`comment ${isHighlighted ? "highlighted-text" : ""}`} dangerouslySetInnerHTML={{ __html: breakedHTMLText }}></span>
                </>
              }
            </React.Fragment>
          case SegmentedTextType.PARAM:
            const paramPath = value.split(".");
            const param = segmentation.segmentedParams.find((param) => param.name == segment.paramName)
            const paramLabel = param?.label;
            return (
              <span id={`param-${segment.paramName}`} key={key} className={`param ${isSelected ? "selected-text" : ""} ${isHighlighted ? "highlighted-text" : ""}`}>
                {paramLabel ?? value}{paramPath[1] && param?.type == 'beneficial' && `(${paramPath[1]})`}
              </span>
            );
          case SegmentedTextType.PARAM_VALUE:
            return <React.Fragment key={key}>
              <span id={`param-${segment.paramName}`} style={{ whiteSpace: 'pre-line' }} className={`param-value ${isSelected ? "selected-text" : ""} ${isHighlighted ? "highlighted-text" : ""}`}>{text}</span>
            </React.Fragment>
          case SegmentedTextType.PARAM_TABLE_VALUE:
            const [transposed, tableRows] = JSON.parse(value)
            const [headers, ...rows] = tableRows as string[][]
            return transposed ? (
              <table id={`param-${segment.paramName}`} key={key} className={`my-4 ${isHighlighted ? "highlighted-text" : ""}`} style={{ borderCollapse: "collapse", width: "100%" }}>
                <tbody>
                  {headers.map((header, idx) => (
                    <tr key={idx}>
                      <th style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>
                        {header}
                      </th>
                      {rows.map((row, rowIndex) => (
                        <td key={rowIndex} style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>
                          {row[idx]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) :
              (
                <table id={`param-${segment.paramName}`} key={key} className={`my-4 ${isHighlighted ? "highlighted-text" : ""}`} style={{ borderCollapse: "collapse", width: "100%" }}>
                  <thead>
                    <tr>
                      {headers.map((header, idx) =>
                        <th key={idx} style={{ border: "1px solid black", padding: "8px", textAlign: "left" }} >
                          {header}
                        </th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, idx) =>
                      <tr key={idx} >
                        {row.map((cell, idx) =>
                          <td key={idx} style={{ border: "1px solid black", padding: "8px", textAlign: "left" }} >
                            {cell}
                          </td>)}
                      </tr>)
                    }
                  </tbody>
                </table>
              );
          case SegmentedTextType.PARAM_COMMENT_VALUE:
            return <React.Fragment key={key}>
              <span id={`param-${segment.paramName}`} style={{ whiteSpace: 'pre-line' }} className={`comment ${isHighlighted ? "highlighted-text" : ""}`}>{text}</span>
            </React.Fragment>
          default:
            break;
        }
      })}
    </div>
  );
}

export default RenderSegmentedText;
