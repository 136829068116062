import { AxiosInstance } from "axios";
import { IProjectTemplate } from "../../types/models";

const END_POINT = "/ProjectTemplate";

const ProjectTemplateAPI = {
  getAllTemplates: async (apiClient: AxiosInstance, schema = "levels") => {
    try {
      const response = await apiClient.get<{ count: number, rows: IProjectTemplate[] }>(
        `${END_POINT}/all`, {
        params: { schema },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching all templates:", error);
      return null;
    }
  },
  getTemplateById: async (apiClient: AxiosInstance, id: IProjectTemplate['id'], schema = "full") => {
    try {
      const response = await apiClient.get<{ row: IProjectTemplate }>(`${END_POINT}/`, {
        params: { schema, id },
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching template with ID: ${id}`, error);
      return null;
    }
  },
};

export default ProjectTemplateAPI;
