import React, { useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Carousel.css"
import { Carousel } from 'bootstrap';

function CarouselSlider() {
    useEffect(() => {
        const carousel = new Carousel(document.getElementById('carouselSignUp'), {
            interval: 5500
        });
        return () => {
            carousel.dispose();
        };
    }, []);
    return (
        <div id="carouselSignUp" className="carousel slide sign-up-intro" data-bs-ride="carousel" style={{ width: "60%", height: '100vh' }}>
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <div className="d-block w-50 h-50 img1-sign-up" ></div>
                    <div className="carousel-caption d-none d-md-block caption-text">
                        <h5 className='caption-sign-up-title'>Generate startup legal documents</h5>
                        <p className='caption-sign-up-subtitle'>Generate key startup legal documents in a few clicks and sign them digitally with our built-in DocuSign integration</p>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className="d-block w-50 h-50 img1-sign-up" ></div>
                    <div className="carousel-caption d-none d-md-block caption-text">
                        <h5 className='caption-sign-up-title'>Create Your Vision and Map Your Path to Success</h5>
                        <p className='caption-sign-up-subtitle'>Begin your startup journey by crafting your vision and mapping your path to success.</p>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className="d-block w-50 h-50 img1-sign-up" ></div>
                    <div className="carousel-caption d-none d-md-block caption-text">
                        <h5 className='caption-sign-up-title'>Elevate Your Project Management</h5>
                        <p className='caption-sign-up-subtitle'>Transform project management with our secure Data Room for contracts and projects</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CarouselSlider