import { useContext } from "react";
import ContractAIVariableInput from "./ContractAIVariableInput";
import { getCompletionClassName } from "../../helpers/helper";
import AiValidationContext from "../../contexts/AiValidationContext";
import AiContractSaveButton from "../actions/AiContractSaveButton";

function EditContractAI() {
  const { aiCompletionPercentage, aiContractData } =
    useContext(AiValidationContext);

  return (
    <div className="row mt-2 pt-2">
      <div className="col-6">
        <div className="d-flex justify-content-between">
          <h1 style={{marginTop: "5px"}} className="contract-name">{aiContractData.contractName}</h1>
          <div style={{paddingBottom: "10px"}}>
            <AiContractSaveButton />
          </div>
        </div>
        <div className="clause-list-container selected">
          <pre>{aiContractData.textContent}</pre>
        </div>
      </div>
      <div className="col-6 px-5">
        <div className="d-flex justify-content-between">
          <h4>Édition</h4>
          <h5 className="me-3">
            Complété à{" "}
            <span className={getCompletionClassName(aiCompletionPercentage)}>
              {aiCompletionPercentage}%
            </span>{" "}
          </h5>
        </div>

        <div className="variable-input-container">
          <ContractAIVariableInput />
        </div>
      </div>
    </div>
  );
}

export default EditContractAI;
