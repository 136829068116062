import React from 'react'
import "./stepsSignUp.css"
import Logo from "../../assets/authentication-logo.svg";
import Steps from '../../components/modals/Steps/Steps';
function StepsSignUp() {
  return (
    <section  id='steps-page'  className='steps-page'>
        <img src={Logo} />
        <Steps/>
    </section>
  )
}

export default StepsSignUp