import { createContext } from "react";
import { IContractAI, IProject, IProjectTemplateRequirement } from "../types/models";
type AIContractData = {
  contractName: IContractAI['name'];
  contractId: IContractAI['id'];
  formId: IContractAI['formId'];
  textContent?: string;
}
export type AiResponsesStatusRow = [IContractAI['aiResponsesStatus'][number][0], IContractAI['aiResponsesStatus'][number][1], number]
interface AiValidationContextType {
  aiValidationValues: any;
  setAiValidationValues: React.Dispatch<React.SetStateAction<any>>;
  aiContractData: AIContractData;
  setAiContractData: React.Dispatch<React.SetStateAction<AIContractData>>;
  aiResponsesStatus: AiResponsesStatusRow[];
  setAiResponseStatus: React.Dispatch<React.SetStateAction<AiResponsesStatusRow[]>>;
  aiCompletionPercentage: number;
  setAiCompletionPercentage: React.Dispatch<React.SetStateAction<number>>;
  rawSummarySheet: IContractAI['summarySheet'];
  setRawSummarySheet: React.Dispatch<React.SetStateAction<IContractAI['summarySheet']>>;
  project?: IProject
  setProject?:React.Dispatch<React.SetStateAction<IProject>>;
  requirement?: IProjectTemplateRequirement,
  setRequirement?:React.Dispatch<React.SetStateAction<IProjectTemplateRequirement>>
  requirementType?:number,
  setRequirementType?:React.Dispatch<React.SetStateAction<number>>

}
const AiValidationContext = createContext<AiValidationContextType>({
  aiValidationValues: {},
  setAiValidationValues: () => { },
  aiContractData: {} as any,
  setAiContractData: () => { },
  aiResponsesStatus: [],
  setAiResponseStatus: () => { },
  aiCompletionPercentage: 0,
  setAiCompletionPercentage: () => { },
  rawSummarySheet: [],
  setRawSummarySheet: () => { },
  project:{},
  requirement:{},
  requirementType:0,
  setProject: () => { },
  setRequirement: () => { },
  setRequirementType: () => { },
});

export default AiValidationContext;
