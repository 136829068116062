// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contract-name {
    font-weight: 900;
    font-size: 31px;
    color: var(--neutral-dark);
    letter-spacing: -0.02em;
}
.contract-container {
    /* overflow: hidden; */
}

.contract-container h4 {
    font-weight: 900;
    font-size: 23px;
    color: var(--neutral-medium);
}
.contract-container h5 {
    font-weight: 700;
    font-size: 15px;
}

.contract-container p {
    font-weight: 400;
    font-size: 14px;
    color: var(--neutral-medium);
}
`, "",{"version":3,"sources":["webpack://./src/pages/subPages/Form/EditFormPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,0BAA0B;IAC1B,uBAAuB;AAC3B;AACA;IACI,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,4BAA4B;AAChC;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,4BAA4B;AAChC","sourcesContent":[".contract-name {\n    font-weight: 900;\n    font-size: 31px;\n    color: var(--neutral-dark);\n    letter-spacing: -0.02em;\n}\n.contract-container {\n    /* overflow: hidden; */\n}\n\n.contract-container h4 {\n    font-weight: 900;\n    font-size: 23px;\n    color: var(--neutral-medium);\n}\n.contract-container h5 {\n    font-weight: 700;\n    font-size: 15px;\n}\n\n.contract-container p {\n    font-weight: 400;\n    font-size: 14px;\n    color: var(--neutral-medium);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
