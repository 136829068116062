import { useState, useEffect, useContext } from "react";

import InputValuesContext from "../contexts/InputValuesContext";
import apiClientWithLoading from "../services/api/ApiClient";
import { fetchContractById } from "../services/api/ContractApi";
import { getUserGroups } from "../services/api/UserAuth";
import ContractTemplateAPI from "../services/api/ContractTemplateApi";
import parseTemplateData from "../helpers/ParseTemplateData";
import { LoadingContext } from "../contexts/LoadingContext";

import { IContract, ITypeLevel1, ITypeLevel2, ITypeLevel3 } from "../types/models";
import { getAllGroups } from "../services/api/GroupApi";

export function useContractData(
  contractId: IContract['id'],
  initialContractName: IContract['name'],
  levelsFilter: {
    level1Id: ITypeLevel1['id'];
    level2Id: ITypeLevel2['id'];
    level3Id: ITypeLevel3['id'];
  }
) {
  const { isLoading, setLoading } = useContext(LoadingContext);

  const {
    templateData,
    setTemplateData,
    setContract,
    setInputValues,
    setFileNames,
    setBeneficialsMap,
    setTemplateId,
    setGroups,
    userGroup,
    setUserGroup,
    accessUser,
    setAccessUser,
    setContractId,
    groupContract,
    setGroupContract,
    setSegmentsOverrides,
    commentsOverrides,
    setCommentsOverrides
  } = useContext(InputValuesContext);
  const [contractName, setContractName] = useState<IContract['name']>(initialContractName);
  const [isEditing, setIsEditing] = useState(false);
  const [paramValues, setParamValues] = useState<IContract['paramValues']>({});
  const [excludedClauses, setExcludedClauses] = useState<IContract['excludedClauses']>([]);
  const [excludedSubClauses, setExcludedSubClauses] = useState<IContract['excludedSubClauses']>([]);



  useEffect(() => {
    let accessMap
    let usergroupIds
    let groupContract
    const fetchData = async () => {
      const apiClient = apiClientWithLoading(setLoading);
      if (contractId) {
        setContractId(contractId);
        const data = await fetchContractById(apiClient, contractId);
        const templateRow = data.row.template;
        getAllGroups(apiClient).then(({ rows }) => {
          const foundGroup = rows?.find(group => group?.id === data?.row.assignedToGroupId);
          if (foundGroup) {
            setGroupContract(foundGroup.name)
          }
        });
        const usergroupdata = await getUserGroups(apiClient)
          .then((usergroupdata) => {
            usergroupIds = usergroupdata.rows.map((group) => group.id);
            setUserGroup(usergroupIds);

            // Extract clause codes and group IDs from the template
            const clauseGroup = data.row.template.groups.map((group) => {
              return group.Group_ContractTemplate.clauseCodes.map((code) => ({
                code,
                groupId: group.Group_ContractTemplate.GroupId
              }));
            });

            // Create a mapping of clause codes to group IDs
            const clauseMapGroup = {};
            clauseGroup.forEach((codes) => {
              codes.forEach(({ code, groupId }) => {
                clauseMapGroup[code] = clauseMapGroup[code] || [];
                if (!clauseMapGroup[code].includes(groupId)) {
                  clauseMapGroup[code].push(groupId);
                }
              });
            });

            // Check user access for each clause code
            accessMap = {};
            usergroupIds.forEach((groupId) => {
              Object.keys(clauseMapGroup).forEach((code) => {
                const access = clauseMapGroup[code].includes(groupId);
                if (!accessMap[code]) {
                  accessMap[code] = false;
                }
                if (access) {
                  accessMap[code] = true;
                }
              });
            });
          })
          .catch((error) => {
            console.error('Error fetching user groups:', error);
          });
        setCommentsOverrides(data?.row?.commentsOverrides ?? {})
        setSegmentsOverrides(data.row?.segmentsOverrides ?? {})
        setAccessUser(accessMap)
        setContract(data.row);
        setContractName(data.row?.name);
        setParamValues(data?.row?.paramValues);
        setFileNames(data?.row?.fileNames);
        setBeneficialsMap(data?.row?.beneficialsMap)
        setExcludedClauses(data?.row?.excludedClauses);
        setExcludedSubClauses(data?.row?.excludedSubClauses);
        const parsedData = parseTemplateData({ row: templateRow });
        setTemplateData(parsedData);
        setTemplateId(data?.row?.templateId);
        console.log(data?.row?.validationGroups);
        setGroups(data?.row?.validationGroups);
        setIsEditing(true);
      } else if (contractName) {
        setLoading(true);
        console.log(levelsFilter);
        const data = await ContractTemplateAPI.getTemplatesByLevelName(
          apiClient,
          levelsFilter
        );

        const row = data?.rows[0];
        const parsedData = parseTemplateData({ row });
        setTemplateData(parsedData);
        const usergroupdata = await getUserGroups(apiClient)
          .then((usergroupdata) => {
            usergroupIds = usergroupdata.rows.map((group) => group.id);
            setUserGroup(usergroupIds);

            // Extract clause codes and group IDs from the template
            const clauseGroup = data.rows[0].groups.map((group) => {
              return group.Group_ContractTemplate.clauseCodes.map((code) => ({
                code,
                groupId: group.Group_ContractTemplate.GroupId
              }));
            });

            // Create a mapping of clause codes to group IDs
            const clauseMapGroup = {};
            clauseGroup.forEach((codes) => {
              codes.forEach(({ code, groupId }) => {
                clauseMapGroup[code] = clauseMapGroup[code] || [];
                if (!clauseMapGroup[code].includes(groupId)) {
                  clauseMapGroup[code].push(groupId);
                }
              });
            });

            // Check user access for each clause code
            accessMap = {};
            usergroupIds.forEach((groupId) => {
              Object.keys(clauseMapGroup).forEach((code) => {
                const access = clauseMapGroup[code].includes(groupId);
                if (!accessMap[code]) {
                  accessMap[code] = false;
                }
                if (access) {
                  accessMap[code] = true;
                }
              });
            });
          })
          .catch((error) => {
            console.error('Error fetching user groups:', error);
          });
        setAccessUser(accessMap)
        console.log('accessUser', accessMap)
        setTemplateId(data?.rows[0]?.id);
        setGroups(data?.rows[0]?.groups!);
        setCommentsOverrides({})
        setSegmentsOverrides({})
        setInputValues({
          contractName: contractName,
        });
        setLoading(false);
      }
    };
    fetchData();
  }, [contractId, initialContractName]);

  return {
    templateData,
    isLoading,
    contractName,
    isEditing,
    paramValues,
    excludedClauses,
    excludedSubClauses,
    userGroup,
    accessUser,
    groupContract,
    commentsOverrides
  };
}
