import { API_BASE_URL } from "../config";
import { AiResponsesStatusRow } from "../contexts/AiValidationContext";
import { IContract, IContractAI, ISubClause } from "../types/models";

export const isFileType = (value: any) => {
  return value instanceof File;
};

export function isSubClauseExcluded(subClauseId: IContract['excludedSubClauses'][number], excludedSubClauses: IContract['excludedSubClauses']) {
  return excludedSubClauses.includes(subClauseId);
}
export function isClauseExcluded(clauseId: IContract['excludedClauses'][number], excludedClauses: IContract['excludedClauses']) {
  return excludedClauses.includes(clauseId);
}

//used to determine the background color of complete à
export function getCompletionClassName(completionPercentage: number) {
  if (completionPercentage < 50) {
    return "completion-low";
  } else if (completionPercentage < 80) {
    return "completion-medium";
  } else {
    return "completion-high";
  }
}

export function removeIndex(listOfLists: any[][]) {
  return listOfLists.map((subList) => subList.slice(0, 2));
}

export function formatDate(dateString: string) {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // +1 because months are 0-indexed
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export async function fetchTextFileUrl(textFileUrl: string) {
  const response = await fetch(textFileUrl);
  const textContent = await response.text();
  return textContent;
}

export function extractValidatedAnswers(aiReponseStatus: AiResponsesStatusRow[]) {
  return aiReponseStatus.filter((aiResponse) => aiResponse[1] === 1);
}

export function extractRemovedAnswers(aiReponseStatus: AiResponsesStatusRow[]) {
  return aiReponseStatus.filter((aiResponse) => aiResponse[1] === 2);
}
export function addIndexToAnswers(aiReponseStatus: IContractAI['aiResponsesStatus']) {
  return aiReponseStatus.map((subList, index) => {
    return [...subList, index] as AiResponsesStatusRow;
  });
}
