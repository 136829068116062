// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
    opacity: 1 !important;
}
.contract-name {
    font-weight: 900;
    font-size: 31px;
    color: var(--neutral-dark);
    letter-spacing: -0.02em;
}
.contract-container {
    /* overflow: hidden; */
    width: 100%;
}
.contract-loading-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.contract-container h4 {
    font-weight: 900;
    font-size: 23px;
    color: var(--neutral-medium);
}
.contract-container h5 {
    font-weight: 700;
    font-size: 15px;
}
.contract-container h6 {
    font-weight: 500;
    font-size: 14px;
    color: var(--neutral-medium);
}

.contract-container p {
    font-weight: 400;
    font-size: 14px;
    color: var(--neutral-medium);
}
`, "",{"version":3,"sources":["webpack://./src/pages/subPages/Contracts/EditContractPage.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,0BAA0B;IAC1B,uBAAuB;AAC3B;AACA;IACI,sBAAsB;IACtB,WAAW;AACf;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,4BAA4B;AAChC;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,4BAA4B;AAChC;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,4BAA4B;AAChC","sourcesContent":["*{\n    opacity: 1 !important;\n}\n.contract-name {\n    font-weight: 900;\n    font-size: 31px;\n    color: var(--neutral-dark);\n    letter-spacing: -0.02em;\n}\n.contract-container {\n    /* overflow: hidden; */\n    width: 100%;\n}\n.contract-loading-container{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n}\n\n.contract-container h4 {\n    font-weight: 900;\n    font-size: 23px;\n    color: var(--neutral-medium);\n}\n.contract-container h5 {\n    font-weight: 700;\n    font-size: 15px;\n}\n.contract-container h6 {\n    font-weight: 500;\n    font-size: 14px;\n    color: var(--neutral-medium);\n}\n\n.contract-container p {\n    font-weight: 400;\n    font-size: 14px;\n    color: var(--neutral-medium);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
