
import React from "react";
import { IClause, IContract } from "../../../types/models";
import ClauseText from "./ClauseText";
import { isSubClauseExcluded } from "../../../helpers/helper";


interface ComparisonClauseProps {
    inputValues: Record<string, any>;
    fileNames: IContract['fileNames'];
    beneficialsMap: IContract['beneficialsMap'];
    segmentsOverrides: Record<string, string>;
    clause: IClause;
    excludedSubClauses: IContract['excludedSubClauses'];
}
function ComparisonClause({
    fileNames,
    beneficialsMap,
    segmentsOverrides,
    inputValues,
    clause,
    excludedSubClauses,
}: ComparisonClauseProps) {

    return (
        <>
            <div className="clause-comparison-current">
                <div className="clause-comparison-header">
                    <span className="clause-comparison-cummon-title">
                        Clause du clausier
                    </span>
                    <br />
                    <span className="clause-comparison-actual-title half-border-bottom">
                        {clause.name}
                    </span>
                </div>
                <div className="clause-comparison-body">
                    <ClauseText
                        segmentation={clause.segmentation}
                        fileNames={fileNames}
                        beneficialsMap={beneficialsMap}
                        inputValues={inputValues}
                        segmentsOverrides={segmentsOverrides}
                    />
                    {clause.subClauses.map((subClause) => {
                        const isExcludedSubclause = isSubClauseExcluded(
                            subClause.id,
                            excludedSubClauses
                        );
                        return (
                            !isExcludedSubclause && (
                                <div
                                    key={subClause.id}
                                    className="my-2"
                                    id={`${clause.id}-${subClause.id}`}
                                >
                                    <h5 className="fw-bold">{subClause.name}</h5>
                                    <div>
                                        {subClause.segmentation?.segmentedText?.length > 0 && (
                                            <ClauseText
                                                segmentation={subClause.segmentation}
                                                fileNames={fileNames}
                                                beneficialsMap={beneficialsMap}
                                                inputValues={inputValues}
                                                segmentsOverrides={segmentsOverrides}
                                            />
                                        )}
                                    </div>
                                </div>
                            )
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default ComparisonClause;
