// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home{
    display: flex;
    flex-direction: column;
}
.main-content{
    min-height: calc(100vh - (20px + 6vh));
    width: 100vw;
}
.app-container {
    min-height: 100vh;
  }
  
  .no-padding-right-row {
    padding-right: 0 !important;
  }
  /* update element label */
  label {
    font-weight: 700;
    font-size: 12px;
    color: #2f14e5;
    font-style: normal;
  }
  label::first-word {
    text-transform: capitalize !important;
  }
  
  button {
    background-color: var(--primary-color-medium);
    color: white;
    font-size: 14px;
    font-weight: 700;
    box-shadow: 0px 23px 9px rgba(51, 18, 239, 0.02),
      0px 13px 8px rgba(51, 18, 239, 0.07), 0px 6px 6px rgba(51, 18, 239, 0.12),
      0px 1px 3px rgba(51, 18, 239, 0.14), 0px 0px 0px rgba(51, 18, 239, 0.15);
    border-radius: 4px;
    border: none;
  }
  .sidebar-container{
    transition: all 0.3s;
    border-right: 1px solid #E5E9F2;
  }
  
  .sidebar-container:hover{
    transition: all 0.3s;
  }

  
  .sidebar-wrapper{
    position: sticky;
    height: 90vh;
    top: 10vh;
    padding-top: 40px;
  }`, "",{"version":3,"sources":["webpack://./src/home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,sCAAsC;IACtC,YAAY;AAChB;AACA;IACI,iBAAiB;EACnB;;EAEA;IACE,2BAA2B;EAC7B;EACA,yBAAyB;EACzB;IACE,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,kBAAkB;EACpB;EACA;IACE,qCAAqC;EACvC;;EAEA;IACE,6CAA6C;IAC7C,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB;;8EAE0E;IAC1E,kBAAkB;IAClB,YAAY;EACd;EACA;IACE,oBAAoB;IACpB,+BAA+B;EACjC;;EAEA;IACE,oBAAoB;EACtB;;;EAGA;IACE,gBAAgB;IAChB,YAAY;IACZ,SAAS;IACT,iBAAiB;EACnB","sourcesContent":[".home{\n    display: flex;\n    flex-direction: column;\n}\n.main-content{\n    min-height: calc(100vh - (20px + 6vh));\n    width: 100vw;\n}\n.app-container {\n    min-height: 100vh;\n  }\n  \n  .no-padding-right-row {\n    padding-right: 0 !important;\n  }\n  /* update element label */\n  label {\n    font-weight: 700;\n    font-size: 12px;\n    color: #2f14e5;\n    font-style: normal;\n  }\n  label::first-word {\n    text-transform: capitalize !important;\n  }\n  \n  button {\n    background-color: var(--primary-color-medium);\n    color: white;\n    font-size: 14px;\n    font-weight: 700;\n    box-shadow: 0px 23px 9px rgba(51, 18, 239, 0.02),\n      0px 13px 8px rgba(51, 18, 239, 0.07), 0px 6px 6px rgba(51, 18, 239, 0.12),\n      0px 1px 3px rgba(51, 18, 239, 0.14), 0px 0px 0px rgba(51, 18, 239, 0.15);\n    border-radius: 4px;\n    border: none;\n  }\n  .sidebar-container{\n    transition: all 0.3s;\n    border-right: 1px solid #E5E9F2;\n  }\n  \n  .sidebar-container:hover{\n    transition: all 0.3s;\n  }\n\n  \n  .sidebar-wrapper{\n    position: sticky;\n    height: 90vh;\n    top: 10vh;\n    padding-top: 40px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
