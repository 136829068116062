import React from 'react'
import { useState } from 'react';
import './signup.css'
import { Link, useNavigate } from 'react-router-dom';
import { signUp } from '../../services/api/UserAuth';
import CarouselSlider from '../../components/Carousel/Carousel';
const { default: EyeInvisible } = require("../../assets/eye-invisible.svg");
const { default: Logo } = require("../../assets/authentication-logo.svg");
const EyeVisible = require("../../assets/eye.png")

interface FormData {
    email: string,
    password: string,
    confirmPassword: string,
    phone:string
}
const SignUp = () => {
    const navigate = useNavigate()
    const [formData, setFormData] = useState<FormData>({ email: '', password: '', confirmPassword: '', phone:'' });
    const [errors, setErrors] = useState<Record<string, string>>({ email: '', password: '', confirmPassword: '' });
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const toggleVisibility = (field: string) => {
        if (field === 'password') setPasswordVisible(!passwordVisible);
        else if (field === 'confirmPassword') setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const newErrors: Record<string, string> = {};

        if (!formData.email.trim()) {
            newErrors.email = 'Please enter your email address.';
        }

        if (!formData.password.trim()) {
            newErrors.password = 'Please enter your password.';
        }

        if (!formData.confirmPassword.trim()) {
            newErrors.confirmPassword = 'Please confirm your password.';
        }

        if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match.';
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                const user = await signUp({ email: formData.email, password: formData.password, UserType: "Client" });
                navigate("/verify-sign-up",{
                    state:{
                        emailUser:user?.email
                    }
                });
                console.log('User signed up successfully:', user);
            } catch (error) {
                console.error('Error signing up user:', error);
            }
        }
    };
    return (
        <div className='sign-up-content'>
            <CarouselSlider />
            <div className='sign-up-sub-content'>
                <div className="authentication-logo">
                    <img src={Logo} width={200} />
                </div>
                <div className='sign-up-titles'>
                    <h1 className='title-sign-up'>Join Us Today!</h1>
                    <p className='subtitle-sign-up'>Signing up is quick and easy
                    </p>
                </div>
                <form onSubmit={handleSubmit} style={{ marginTop: '2%' }}>
                    <div className="form-group signup-group">
                        <label htmlFor="email" className="label-sign-up-form">
                            Email
                        </label>
                        <input
                            type="email"
                            className={` input-sign-up form-control ${errors.email ? 'is-invalid' : ''}`}
                            id="email"
                            name="email"
                            placeholder="Type your e-mail "
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                        {errors.email && <div className="invalid-feedback feedbackError">{errors.email}</div>}

                    </div>
                    <div className="form-group signup-group">
                        <label htmlFor="email" className="label-sign-up-form">
                            Phone number
                        </label>
                        <input
                            type="text"
                            className="input-sign-up form-control"
                            id="phone"
                            name="phone"
                            placeholder="Type your phone number"
                            value={formData.phone}
                            onChange={handleInputChange}
                        />
                    </div>
                    {['password', 'confirmPassword'].map((field) => (
                        <div key={field} className="password-input-container form-group signup-group">
                            <label htmlFor={field} className="label-sign-up-form">
                                {field === 'password' ? 'Password' : 'Confirm password'}
                            </label>
                            <input
                                type={field === 'password' ? (passwordVisible ? "text" : "password") : (confirmPasswordVisible ? "text" : "password")}
                                className={`form-control input-sign-up ${errors[field] ? 'is-invalid' : ''}`}
                                name={field}
                                placeholder={`Type your ${field === 'password' ? 'password' : 'confirm password'}`}
                                value={formData[field]}
                                onChange={handleInputChange}
                            />
                            <img
                                src={field === 'password' ? (passwordVisible ? EyeVisible : EyeInvisible) : (confirmPasswordVisible ? EyeVisible : EyeInvisible)}
                                className="eye-icon"
                                width={18}
                                onClick={() => toggleVisibility(field)}
                                alt={`Toggle ${field} Visibility`}
                            />
                            {errors[field] && <div className="invalid-feedback feedbackError">{errors[field]}</div>}
                        </div>
                    ))}                    <div className='sign-up-form-footer'>
                        <button type="submit" className="btn btn-primary btn-signup">
                            Sign Up
                        </button>
                        <p className='sign-in-question'>Do you have an account ? <Link to='/sign-in' className='btn-login'>
                            Log In
                        </Link> </p>
                    </div>
                </form>
            </div>


        </div>
    )
}

export default SignUp