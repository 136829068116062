import React, { useState } from "react";
import ContractAuditModal from "../modals/ContractAuditModal";
import { useTranslation } from '../../contexts/TranslationProvider';
import "./buttons.css";

function AuditButton() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  return <>
    <button className="action-button" onClick={() => setShowModal(true)}>
      {t("navbar.audit")}
    </button>
    {showModal && <ContractAuditModal onClose={() => setShowModal(false)} />}
  </>
}

export default AuditButton;
