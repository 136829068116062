
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ITypeLevel1, ITypeLevel2, ITypeLevel3 } from "../../../types/models";
import apiClientWithLoading from "../../../services/api/ApiClient";
import { getAllTypeLevel1WithContractTemplate } from "../../../services/api/TypesApi";
import ContractTemplateAPI from "../../../services/api/ContractTemplateApi";
import CustomCombobox from "../../common/CustomCombobox";
import Loading from "../../common/Loading";

interface ComparableClauseSelectorProps {
  modalComparableRef?: any;
  setComparableClauses?: (v: any) => void;
  clauseName?: string;
}
function ComparableClauseSelector({
  modalComparableRef,
  setComparableClauses,
  clauseName
}: ComparableClauseSelectorProps) {

  const [levelsFilter, setLevelsFilter] = useState<{
    level1Id: ITypeLevel1['id'];
    level2Id: ITypeLevel2['id'];
    level3Id: ITypeLevel3['id'];
  }>({
    level1Id: null,
    level2Id: null,
    level3Id: null,
  });
  const [nameLevels, setDisplayLevels] = useState({
    level1: true,
    level2: true,
    level3: true,
  });
  const [options, setOptions] = useState({
    level1: [],
    level2: [],
    level3: [],
  });
  const apiClient = apiClientWithLoading();
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await getAllTypeLevel1WithContractTemplate(apiClient);
      setOptions((prevOptions) => ({
        ...prevOptions,
        level1: data.rows,
      }));
    };
    setLoading(false);

    fetchData();
  }, []);

  const handleLevel1Change = (value) => {
    const selectedLevel1 = options.level1.find((item) => item.name === value);
    if (selectedLevel1) {
      setLevelsFilter({
        level1Id: selectedLevel1.id,
        level2Id: null,
        level3Id: null
      })
      setOptions((prevOptions) => ({
        ...prevOptions,
        level2: selectedLevel1.levels2,
        level3: [],
      }));
      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level2: selectedLevel1.levels2.length > 0,
        level3: false,
      }));

      // Clear the values of Type2 and Type3 comboboxes
      setValue("Type2", "Type Niveau 2");
      setValue("Type3", "Type Niveau 3");
    }
  };

  const handleComboboxClick = () => {
    setTimeout(() => {
      modalComparableRef.current.scrollTo({
        top: modalComparableRef.current.scrollHeight,
        behavior: "smooth",
      });
    }, 10);
  };

  const handleLevel2Change = (value) => {
    const selectedLevel2 = options.level2.find((item) => item.name === value);
    if (selectedLevel2) {
      setLevelsFilter({
        level1Id: levelsFilter.level1Id,
        level2Id: selectedLevel2.id,
        level3Id: null
      })
      setOptions((prevOptions) => ({
        ...prevOptions,
        level3: selectedLevel2.levels3 || [],
      }));

      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level3: Array.isArray(selectedLevel2.levels3) && selectedLevel2.levels3.length > 0,
      }));
    } else {
      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level3: false,
      }));
    }
    setValue("Type3", "Type Niveau 3");
  };
  const handleLevel3Change = (value) => {
    const selectedLevel3 = options.level3.find((item) => item.name === value);
    if (selectedLevel3) {
      setLevelsFilter({
        ...levelsFilter,
        level3Id: selectedLevel3.id,
      })
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const onSubmit = async (data) => {
    setLoading(true);
    let selectedLevel, selectedValue;
    // Check which level the user has selected
    if (data.Type3 !== "Type Niveau 3") {
      selectedLevel = "level3.name";
      selectedValue = data.Type3;
    } else if (data.Type2 !== "Type Niveau 2") {
      selectedLevel = "level2.name";
      selectedValue = data.Type2;
    } else if (data.Type1 !== "Type Niveau 1") {
      selectedLevel = "level1.name";
      selectedValue = data.Type1;
    }
    console.log(levelsFilter)
    const contracts = await ContractTemplateAPI.getAllTemplatesByLevelName(
      apiClient,
      levelsFilter
    );
    setComparableClauses(contracts.rows.map(row => row.clauses))
  };
  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading ? (
        <Loading height="35vh" />
      ) : (<>
        {clauseName && (
          <form action="" className="form-group" onSubmit={handleSubmit(onSubmit)}>
            <p className="fw-bolder">Champs obligatoires *</p>
            <div className="form-input-content">
              <div style={{ width: '100%' }}>
                <div style={{ paddingLeft: '30px' }}>
                  <Controller
                    name="Type1"
                    control={control}
                    defaultValue="Type Niveau 1"
                    rules={{
                      required: true,
                      validate: (value) => value !== "Type Niveau 1",
                    }}
                    render={({ field }) => (
                      <CustomCombobox
                        label="Type Niveau 1 *"
                        options={options.level1.map((item) => item.name)}
                        value={field.value}
                        fullWidth={true}
                        onDropdownOpen={handleComboboxClick}
                        onChange={(value) => {
                          handleLevel1Change(value);
                          field.onChange(value);
                        }}
                      />
                    )}
                  />
                </div>
                {errors.Type1 && <p className="text-danger py-0 my-0">Il faut choisir un template</p>}
                {nameLevels.level2 && (
                  <div style={{ paddingLeft: '30px' }}>
                    <Controller
                      name="Type2"
                      control={control}
                      defaultValue="Type Niveau 2"
                      rules={{
                        required: true,
                        validate: (value) => value !== "Type Niveau 2",
                      }}
                      render={({ field }) => (
                        <CustomCombobox
                          label="Type Niveau 2 *"
                          onDropdownOpen={handleComboboxClick}
                          options={options.level2.map((item) => item.name)}
                          value={field.value}
                          fullWidth={true}
                          onChange={(value) => {
                            handleLevel2Change(value);
                            field.onChange(value);
                          }}
                        />
                      )}
                    />
                  </div>
                )}
                {options.level2.length > 0 && errors.Type2 && <p className="text-danger py-0 my-0">Il faut choisir un template</p>}
                {nameLevels.level3 && (
                  <div style={{ paddingLeft: '30px' }}>
                    <Controller
                      name="Type3"
                      control={control}
                      defaultValue="Type Niveau 3"
                      rules={{
                        required: true,
                        validate: (value) => value !== "Type Niveau 3",
                      }}
                      render={({ field }) => (
                        <CustomCombobox
                          label="Type Niveau 3 *"
                          onDropdownOpen={handleComboboxClick}
                          options={options.level3.map((item) => item.name)}
                          value={field.value}
                          fullWidth={true}
                          onChange={(value) => {
                            handleLevel3Change(value);
                            field.onChange(value)
                          }}
                        />
                      )}
                    />
                  </div>
                )}
                {options.level3.length > 0 && errors.Type3 && <p className="text-danger py-0 my-0">Il faut choisir un template</p>}
              </div>
            </div>
            <div className="">
              <button type="submit" className="confirm-comparison-button">
                Appliquer
              </button>
            </div>
          </form>
        )}</>)}
    </>
  );
}

export default ComparableClauseSelector;
