import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signIn } from '../../services/api/UserAuth';
import CarouselSlider from '../../components/Carousel/Carousel';
import EyeInvisible from "../../assets/eye-invisible.svg";
import Hand from "../../assets/waving-hand.svg";
import Logo from "../../assets/authentication-logo.svg";
import "./signin.css";
const EyeVisible = require("../../assets/eye.png")

interface FormData {
    email: string;
    password: string;
}

const SignIn = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState<FormData>({
        email: '',
        password: '',
    });

    const [errors, setErrors] = useState<Record<string, string>>({
        email: '',
        password: '',
    });

    const [passwordVisible, setPasswordVisible] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newErrors: Record<string, string> = {};

        if (!formData.email.trim()) {
            newErrors.email = 'Please enter your email address.';
        }

        if (!formData.password.trim()) {
            newErrors.password = 'Please enter your password.';
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                const { token, user } = await signIn(formData.email, formData.password);
                console.log('User signed in successfully:', user);
                navigate("/");
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    setErrors({ ...newErrors, signIn: 'An error occurred during sign-in.' });
                }
            }
        }
    };

    return (
        <div className='sign-in-content'>
            <CarouselSlider />

            <div className='sign-in-sub-content'>
                <div className="authentication-logo">
                    <img src={Logo} width={200} alt="Authentication Logo" />
                </div>
                <div className='sign-in-titles'>
                    <img src={Hand} width={30} alt="Waving Hand" />
                    <h1 className='title-sign-in'>Welcome back!</h1>
                    <p className='subtitle-sign-in'>Please login to access your account.</p>
                </div>
                <form onSubmit={handleSubmit} style={{ marginTop: '2%' }}>
                    <div className="form-group signin-group">
                        <label htmlFor="email" className="label-sign-in-form">
                            E-mail or phone number
                        </label>
                        <input
                            type="email"
                            className={`input-sign-in form-control ${errors.email ? 'is-invalid' : ''}`}
                            id="email"
                            name="email"
                            placeholder="Type your e-mail "
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                        {errors.email && <div className="invalid-feedback feedbackError">{errors.email}</div>}
                    </div>
                    <div className="password-input-container form-group signin-group">
                        <label htmlFor="password" className="label-sign-in-form">
                            Password
                        </label>
                        <input
                            type={passwordVisible ? "text" : "password"}
                            className={`form-control input-sign-in ${errors.password ? 'is-invalid' : ''}`}
                            name="password"
                            placeholder='Type your password'
                            value={formData.password}
                            onChange={handleInputChange}
                        />
                        {!errors.password && <img src={passwordVisible ? EyeVisible :EyeInvisible} className="eye-icon" width={20} onClick={togglePasswordVisibility} alt="Toggle Password Visibility" />}
                        {errors.password && <div className="invalid-feedback feedbackError">{errors.password}</div>}
                    </div>
                    <Link to="" className='link-forgot-password'>Forgot Password ?</Link>
                    <div className='sign-in-form-footer'>
                        <button type="submit" className="btn btn-primary btn-signin">
                            Sign In
                        </button>
                        <p className='sign-up-question'>Don't have an account ? <Link to='/sign-up' className='btn-signup-link'>
                            Sign Up
                        </Link> </p>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignIn;
