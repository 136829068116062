import "./EditForm.css";
import { useContext, useEffect, useMemo, useState } from "react";
import EditFormContext from "../../contexts/EditFormContext";
import { calculateFormCompletionPercentage } from "../../helpers/CompletionPercentage";
import { IFormTemplatePage } from "../../types/models";
import EditFormInputList from "./EditFormInputList";
import Loading from "../common/Loading";
import ProgressBar from "../../components/common/ProgressBar";
import { useSubmitForm } from "../../hooks/useSubmitForm";
import EventManager from "../../services/EventManager";
import { useLocation } from "react-router-dom";
import { PromiseSub, createPromiseSub } from "../../utils/promise";
import MiddlewareManager from "../../services/MiddlewareManager";
import SaveDraftModel from "../modals/SaveDraftModal";
import EditFormSaveButton from "./EditFormSaveButton";
const { default: EditFormPageIcon } = require('../../assets/edit_form_page.svg');
const { default: EditFormPageOnIcon } = require('../../assets/edit_form_page_on.svg');
const { default: VLineIcon } = require('../../assets/v_line.svg');
const { default: VLineOnIcon } = require('../../assets/v_line_on.svg');



//maintain the state of the fetched contract data and handle the interactions between the ClauseList and VariableInput components.
function EditForm() {
  const location = useLocation();
  const context = useContext(EditFormContext);
  const submitForm = useSubmitForm()
  const [draft, setSaveDraft] = useState(false);
  const [sub, setSub] = useState<PromiseSub>(null);
  const onLocation = async (pathname: string) => {
    if (location.pathname === "/data-room/edit-form" && pathname != "/data-room/edit-form") {
      const newSub = createPromiseSub()
      setSaveDraft(true);
      setSub(newSub);
      await newSub.promise
    }
  };
  useEffect(() => {
    MiddlewareManager.subscribe("location", onLocation);
    return () => {
      MiddlewareManager.unsubscribe("location", onLocation);
    };
  }, [onLocation]);
  useEffect(() => {
    if (location.pathname !== "/data-rooms/edit-form") {
      setSaveDraft(false);
    }
    return () => { };
  }, []);

  useEffect(() => {
    EventManager.invoke('EditFormContext', context)
    return () => { }
  }, [JSON.stringify(context)])
  useEffect(() => {
    EventManager.subscribe('SubmitForm', submitForm)
    return () => {
      EventManager.unsubscribe('SubmitForm', submitForm)
    }
  }, [])

  const {
    template,
    name,
    paramValues,
    completionPercentage,
    setCompletionPercentage,
    selected,
    setProject,
    setRequirement,
    setRequirementType,
    project
  } = context;
  useEffect(()=>{
    if(location?.state?.project){
      setProject(location?.state?.project)
      setRequirement(location?.state?.requirement)
      setRequirementType(location?.state?.requirementType)
    }
  },[project])
  const [currentIndexPage, setIndexCurrentPage] = useState(0)
  let index = template?.pages?.length
  let allParams = useMemo(() => {
    let allParams: IFormTemplatePage['params'] = []
    template?.pages?.map(page => allParams.push(...page.params))
    return allParams
  }, [template, paramValues])

  useEffect(() => {
    const newCompletionPercentage = calculateFormCompletionPercentage(paramValues, allParams);
    setCompletionPercentage?.(newCompletionPercentage);
  }, [paramValues, allParams]);
  const selectedPageIndex = selected ? template?.pages?.findIndex(page => page.id == selected.pageId) : -1

  return (
    <>
      <div className="d-flex align-items-start justify-content-between border-bottom w-100 pr-3 pt-2 pb-2 pl-3" style={{ paddingRight: "55px", paddingLeft: "18px" }}>
        <h4>{name}</h4>
        <EditFormSaveButton />
        Page ({currentIndexPage + 1}/{index})
      </div>
      <div className="d-flex">
        <div className="container-steps-form  pt-5 px-5">
          <div className="edit-form-steps col py-5 px-4">
            {template?.pages?.map((page, index) => {
              const isChecked = index <= currentIndexPage
              const isNextChecked = (index + 1) <= currentIndexPage
              return <div key={index} className="edit-form-step d-flex align-items-start gap-2 pt-3 pb-2">
                <img src={isChecked ? EditFormPageOnIcon : EditFormPageIcon} height="24px" width="auto" alt="" >
                </img>
                {index < template.pages.length - 1 && (
                  <img src={isNextChecked ? VLineOnIcon : VLineIcon} className="edit-form-step-line" />
                )}
                {page.name}
              </div>
            }
            )}
          </div>
        </div>
        <div className="edit-form-input-list-container">
          {template ? <EditFormInputList currentPage={template?.pages[currentIndexPage]}  setIndexCurrentPage={setIndexCurrentPage} currentIndexPage={currentIndexPage}/> : <Loading />}
        </div>
      </div>
      {draft && (
        <SaveDraftModel
          type="form"
          onClose={() => { setSaveDraft(false); sub.reject() }}
          onSave={(saved: boolean) => { sub.resolve() }}
        />
      )}

    </>
  );
}
export default EditForm;
