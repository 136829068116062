import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import InputValuesContext from "../../contexts/InputValuesContext";
import SelectionContext from "../../contexts/SelectionContext";
import { ContractTemplateParsedData, getClauseNestedGroupParams } from "../../helpers/ParseTemplateData";
import { OptionalClause, OptionalSubClause, ParsedClauseParam, groupClauseParams, nestGroupedClauseParams } from "../../helpers/ParseTemplateData";
import "./EditContractInputList.css";
import { useForm } from "react-hook-form";
import React from "react";
import ClauseInput from "./ClauseInput";


function EditContractInputList({
  inputListRef,
  renderedParams,
  optionalSubClauses,
  isEditing,
  templateData
}: {
  inputListRef: React.MutableRefObject<any>,
  renderedParams: ParsedClauseParam[];
  optionalClauses: OptionalClause[];
  optionalSubClauses: OptionalSubClause[];
  isEditing: boolean;
  templateData: ContractTemplateParsedData,
}) {
  const {
    setExcludedSubClauses,
    setExcludedClauses,
  } = useContext(InputValuesContext);
  const { selected, setSelected } = useContext(SelectionContext);

  const inputGroupRef = useRef(null);


  const handleClick = useCallback(
    (clauseId) => {
      setSelected({
        eventType: 'Param',
        clauseId: clauseId,
        paramName: null,
      });
    },
    [setSelected]
  );

  useEffect(() => {
    if (selected.clauseId && selected.eventType != "ParamValueChange" && inputListRef.current) {
      const elementClassName = `clause-input-${selected.clauseId}`;
      const element = document.getElementsByClassName(elementClassName)[0];
      console.log(element);

      if (element) {
        setTimeout(() => {
          const container = inputListRef.current;
          const containerRect = container.getBoundingClientRect();
          const elementRect = element.getBoundingClientRect();
          const relativeTop = elementRect.top - containerRect.top;
          const newScrollTop = container.scrollTop + relativeTop;
          container.scrollTo({
            top: newScrollTop,
            behavior: "smooth",
          });
        }, 500);
      }
    }
  }, [selected.clauseId, inputListRef]);


  function handleExclusionToggle(event, id, isSubclause) {
    event.stopPropagation();
    if (isSubclause) {
      setExcludedSubClauses((prevExcludedSubClauses) => {
        if (prevExcludedSubClauses.includes(id)) {
          return prevExcludedSubClauses.filter(
            (excludedId) => excludedId !== id
          );
        } else {
          return [...prevExcludedSubClauses, id];
        }
      });
    } else {
      setExcludedClauses((prevExcludedClauses) => {
        if (prevExcludedClauses.includes(id)) {
          return prevExcludedClauses.filter((excludedId) => excludedId !== id);
        } else {
          return [...prevExcludedClauses, id];
        }
      });
    }
  }

  const form = useForm();
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  function onSubmit(e) {
    e.preventDefault();
  }


  return (
    <div className="variable-input">
      <form onSubmit={handleSubmit(onSubmit)}>
        {templateData.map((clauseData, clauseIndex) => {
          
          const nestedGroupedParam = getClauseNestedGroupParams(clauseData,renderedParams)
          
          return (
            <React.Fragment key={clauseData.id}>
              <ClauseInput
                key={`clauseData-${clauseIndex}`}
                form={form}
                clauseIndex={clauseIndex}
                handleClick={handleClick}
                handleExclusionToggle={handleExclusionToggle}
                inputGroupRef={inputGroupRef}
                isEditing={isEditing}
                isOptionalClause={!!clauseData.isOptional}
                nestedGroupedParam={nestedGroupedParam}
                optionalSubClauses={optionalSubClauses}
                clause={clauseData}
              />
            </React.Fragment>
          );
        })}
      </form>

    </div>
  );
}
export default EditContractInputList;
