import { ContractTemplateParsedData, mergeParams } from "../helpers/ParseTemplateData";
import { IBeneficial, IContract } from "../types/models";
import { cellsToXLSXWorkbook } from "../utils/csv";

export function serializeParamValues(
    inputValues: IContract['paramValues'],
    templateData: ContractTemplateParsedData,
    fileNames: Record<number, string>,
    beneficialsMap: Record<string, IBeneficial>
) {
    const params = mergeParams(templateData)

    let spreadsheets: Record<string, string[][]> = {
        "default": [
            ['Clause_code', 'Clause_name', 'Sub_clause_code', 'Sub_clause_name', 'Name', 'Type', 'Label', 'Value'],
        ],
    }
    for (const param of params) {
        const value = inputValues[param.name]
        const { clauseCode, clauseName, subClauseCode, subClauseName, name, type, label } = param
        const row = [clauseCode, clauseName, subClauseCode, subClauseName, name, type, label]
        switch (param.type) {
            case 'beneficial':
                const beneficial = beneficialsMap[value]
                if (beneficial) {
                    spreadsheets[param.name] = serializeBeneficial(beneficial)
                }
                spreadsheets['default'].push([...row, beneficial?.name ?? ""])
                break;
            case 'beneficial[]':
                const beneficials = Array.isArray(value) ? value.map(bId => beneficialsMap[bId]) : []
                if (beneficials.length) {
                    spreadsheets[param.name] = []
                    beneficials.forEach(b => spreadsheets[param.name].push(...serializeBeneficial(b)))
                }
                spreadsheets['default'].push([...row, beneficials.map(b => b.name).join(", ")])
                break;
            case 'table':
                const rows = value ?? []
                spreadsheets[param.name] = [
                    param.args.map(arg => arg.header),
                    ...rows
                ]
                spreadsheets['default'].push([...row, ""])
                break;
            case 'csv':
                const [csvTransposed, csvRows, csvName] = value ?? [false, [], ""]
                spreadsheets[param.name] = csvRows
                spreadsheets['default'].push([...row, csvName])
                break;
            case 'file':
                if (value instanceof File) {
                    spreadsheets['default'].push([...row, value.name ?? ""])
                } else {
                    const fileName = fileNames[value]
                    spreadsheets['default'].push([...row, fileName ?? ""])
                }
                break;
            case 'list':
                spreadsheets['default'].push([...row, value?.map(idx => idx + 1)?.join(",") ?? ""])
                break;
            case 'enum':
                const arg = param.args[value]
                spreadsheets['default'].push([...row, arg?.option ?? ""])
                break;
            case 'boolean':
                spreadsheets['default'].push([...row, (value == true && "Y") || (value == false && "N") || ""])
                break;
            case 'comment':
            case 'date':
            case 'number':
            case 'string':
                spreadsheets['default'].push([...row, value ?? ""])
                break;
            default:
                break;
        }
    }
    return cellsToXLSXWorkbook(spreadsheets)

};
export function serializeBeneficial(beneficial: IBeneficial) {
    if (beneficial.type == 'Person') {
        return [
            ['name', 'value'],
            ['type', beneficial.type],
            ['firstName', beneficial.firstName ?? ""],
            ['lastName', beneficial.lastName ?? ""],
            ['email', beneficial.email ?? ""],
            ['jobTitle', beneficial.jobTitle ?? ""],
            ['addressLine', beneficial.address?.addressLine ?? ""],
            ['dateOfBirth', beneficial.dateOfBirth ?? ""],
            ['placeOfBirth', beneficial.placeOfBirth ?? ""],
        ]
    } else {
        return [
            ['name', 'value'],
            ['type', beneficial.type],
            ['companyName', beneficial.companyName ?? ""],
            ['socialCapital', beneficial.socialCapital ?? ""],
            ['postalAddress', beneficial.postalAddress ?? ""],
            ['legalForm', beneficial.legalForm ?? ""],
            ['registrationNumber', beneficial.registrationNumber ?? ""],
            ['specialization', beneficial.specialization ?? ""],
        ]
    }

}
